export const GET_SETTINGLIST = termsList => ({
    type: 'GET_SETTINGLIST',
    payload: termsList
  });
  export const GET_COURSES = courses => ({
    type: 'GET_COURSES',
    payload: courses
  });  
  export const GET_ORGANIZATION = organization => ({
    type: 'GET_ORGANIZATION',
    payload: organization
  });  
  export const SET_IS_ONLINE = value => ({
    type: 'SET_IS_ONLINE',
    payload: value
  });  
  export const  RECTIFICATION = (value,data) => ({
    type: 'RECTIFICATION',
    payload:{
      value,data
    }
  });  
  export const  RECTIFICATION_FALSE = value => ({
    type: 'RECTIFICATION_FALSE',
    payload: value
  }); 
  export const  RESET_FILTERS_ALL = value => ({
    type: 'RESET_FILTERS_ALL',
    payload: value
  });  