import React from "react";
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

// @ts-ignore
import ArrowLogo from "./arrow.svg";
import "./radiogroup.scss";

type Props = {
  value?: string;
  title: string;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fields: {
    name: string,
    isChecked: boolean,
    image_url?: string
  }[];
  handleChangeCheckBoxGroup: (event: React.ChangeEvent<HTMLInputElement>, operation: string) => void;
};

const CheckBoxGroupComponent = ({
  value,
  handleChange,
  fields,
  title,
  handleChangeCheckBoxGroup
}: Props): JSX.Element => {
  return (
    <Box
      style={{ width: "312px" }}
      className="radio-group-component-container"
    >
      <FormControl component="fieldset">
        <Accordion style={{ boxShadow: "none", marginLeft: "20px" }}>
          <AccordionSummary
            expandIcon={<img src={ArrowLogo} alt="icon" style={{}} />}
            style={{ minWidth: "260px" ,width:'0'}}
          >
            <Typography
              style={{
                //@ts-ignore
                fontWeight: "400",
              }}
              className="radio-group-component-title"
            >
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{ marginTop: "-16px" }}
            className="radio-group-component-detail"
          >
            <RadioGroup
              aria-label="gender"
              name="gender1"
            >
              {fields?.map((field) => (
                <FormControlLabel
                key={field.name + Math.floor(Math.random()*50-20+42)}
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<RadioButtonCheckedIcon />}
                      checked={field.isChecked}
                      onChange={(e) => handleChangeCheckBoxGroup(e, title)}
                      name={field.name}
                      className="radio-group-component-item"
                      style={{
                        color: field.isChecked ? "#db5461" : "black",
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    />
                  }
                  label={field?.image_url ? <div className="org-row"><img src={field?.image_url} className="filter-icon"/>&nbsp;{field.name}</div> : field.name}
                />
              ))}
            </RadioGroup>
          </AccordionDetails>
        </Accordion>
      </FormControl>
    </Box>
  );
};

export default CheckBoxGroupComponent;
