// Customizable Area Start
import React from "react";
import { Button, Input, TextField } from "@material-ui/core";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  NotificationContainer,
  NotificationManager,
  //@ts-ignore
} from "react-notifications";
import "../assets/ContactUsWeb.scss";
import { imgBanner } from "./assets";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
export const configJSON = require("./config");
import ContactusController, { Props } from "./ContactUsControllerWeb.web";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer.web";

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <NotificationContainer />
        <Header />
        <div className="container-contact">
          <div className="banner w-50">
            <img className="banner-img" src={imgBanner} />
          </div>
          <div className="wrapper-contact w-50">
            <Formik
              initialValues={{
                name: "",
                email: "",
                messege: "",
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .required(configJSON.nameRequire)
                  .matches(configJSON.nameReg, configJSON.nameInvalid)
                  .min(8, configJSON.fullNameInvalid)
                  .max(20, configJSON.fullNameInvalid),
                email: Yup.string()
                  .required(configJSON.emailRequire)
                  .matches(
                    configJSON.emailRegex,
                    configJSON.errorEmailNotValid
                  ),
                messege: Yup.string()
                  .required(configJSON.messageRequire)
                  .matches(configJSON.nameReg, configJSON.messageNoSpace),
              })}
              onSubmit={(values, { resetForm }): any => {
                this.doSubmit(values, resetForm);
              }}
              data-test-id="formik"
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                handleBlur,
                setFieldValue,
              }) => (
                //@ts-ignore
                <Form>
                  <div className="contact-form">
                    <div className="contact-main-form">
                      <h2 className="Contact-Us">Contact Us</h2>
                      <p className="leave-msg">
                        Leave us a message, we will contact you as soon as
                        possible
                      </p>
                      <div>
                        <TextField
                          data-test-id="name"
                          className="w-100"
                          id="name"
                          placeholder="Name*"
                          variant="outlined"
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          helperText={errors.name}
                          error={touched.name && errors.name ? true : false}
                        />
                      </div>
                      <br />
                      <div>
                        <TextField
                          className="w-100"
                          id="email"
                          placeholder="Email*"
                          variant="outlined"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          helperText={errors.email}
                          error={touched.email && errors.email ? true : false}
                        />
                      </div>
                      <br />
                      <div className="main">
                        <TextField
                          className="w-100 multiline-text textareatest"
                          placeholder="Message"
                          variant="outlined"
                          multiline
                          rows={9}
                          name="messege"
                          onKeyUp={(event) => this.countChar(event)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.messege}
                          helperText={errors.messege}
                          error={
                            touched.messege && errors.messege ? true : false
                          }
                        />
                        <span className="char-250" id="#charNum'">
                          {this.countCharNum} Characters remaining
                        </span>
                      </div>

                      <br />
                      <div className="div-btn-submit">
                        {this.state.loading ? (
                          <Button variant="contained" className="submit-button">
                            <CircularProgress
                              // eslint-disable-next-line react-native/no-inline-styles
                              style={{ color: "#FFFFFF" }}
                            />
                          </Button>
                        ) : (
                          <Button
                            className="submit-button"
                            name="login-loading"
                            data-test-id="submit-btn-1"
                            id="login-loading"
                            variant="contained"
                            onClick={(e) => {
                              handleSubmit();
                            }}
                          >
                            SUBMIT
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
