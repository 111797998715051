// Customizable Area Start
import React from "react";
import "../assets/ShopePage.scss";
export const configJSON = require("./config");
import ShopePageController, { Props } from "./ShopePageController.web";
import Header from "../../../components/src/HeaderApp.web";
import Footer from "../../../components/src/Footer.web";
import Rating from '@material-ui/lab/Rating';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import { Button } from "@material-ui/core";
import { prime } from "./assets";
import StoreProducts from "../../../components/src/StoreProducts.web";
import TrainingAidWeb from "./TrainingAid.web";
import Merchandise from "./Merchandise";



export default class ShopePage extends ShopePageController {
  constructor(props: Props) {
    super(props);   
  }

   getComponent = (id: string, searchStringValue: string, isSearchClose: boolean, setIsSearchClose: any) => {
    if (id === 'merchant') return <Merchandise />
    return (
      <TrainingAidWeb
        //@ts-ignore
        searchStringValue={searchStringValue}
        isSearchClose={isSearchClose}
        setIsSearchClose={setIsSearchClose}
      />
    )
  }
  render() {

    return (
      <div
        style={{ display: "flex", flexDirection: "column", height: "auto" }}
        className="shop-page-main-container"
      >
        <Header
          //@ts-ignore
          setSearchValue={this.setSearchString}
          setIsSearchClose= {this.setIsSearchClose}
        />
       
        {/* <h1 className="h1-comingSoon">Coming Soon</h1> */}
        <div className="container-shop">
          <div className="shop-training w-50">
            <div className="button-both-together">
              <Button variant="contained" onClick={() => this.setState({ activeBtnClass: 'merchant' })} className={this.state.activeBtnClass === 'merchant' ? "active-button" : "nonActive-button"}>
                Merchandise
              </Button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Button variant="contained" onClick={() => this.setState({ activeBtnClass: 'trainig-aid' })} className={this.state.activeBtnClass === 'trainig-aid' ? "active-button" : "nonActive-button"}>
                Training Aids/Gear
              </Button>
            </div>
            <div className="main-div-slider" >
              {this.getComponent(this.state.activeBtnClass, this.state.searchStringValue, this.state.isSearchClose, this.setIsSearchClose)}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
// Customizable Area End
