// Customizable Area Start
import React from "react";

export const configJSON = require("./config");
import {
  list,
  offline
} from "./assets";
import CaursesPageOfflineAccessController, { Props } from "./CaursesPageOfflineAccessController.web";
import Header from "../../../components/src/HeaderApp.web";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
 class CaursesPageOfflineAccess extends CaursesPageOfflineAccessController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div style={{display: 'flex', flexDirection: 'column', height:'auto'}}>
        <Header />
        <div className="container">
          <div className="wrapper-training w-50">
          {
            this.state.responseData?.length !==0 &&
            this.state.responseData.map((course:any,index:number) => {
            
              return  <div style={{cursor: "pointer"}}>
              <hr key={index} /> 
              <div className="div-course" key={index  + "div"}>
                <img src={list} key={index  + "list"} />
                <div  onClick={() => {
                  // this.props.history.push()
                  this.props.history.push({
                    pathname:`/courseListOflline/${course.id}`,
                    state: { detail: course }
                });
                  }} key={index  + "div-iden"}>
                  <div
                    className="div-identity-sub"
                    key={index  + "div-sub-iden"}
                  >
                    <h1
                      className="identity-line"
                      key={index  + "iden-line"}
                    >
                      {course?.description}
                    </h1>
                  </div>
                  <div
                    className="sub-div-identity"
                    key={index  + "div-sub-duration"}
                  >
                    <h1 className="duration" key={index  + "H1"}>Duration: {course?.duration}</h1>{" "}
                    <img src={course?.image_url} /> {course?.new_course&&<h2>New</h2>}
                  </div>
                </div>
                <img
                  src={offline}
                  alt=""
                  //@ts-ignore
                  // onClick={()=>this.handleDownloadFunction(course)}
                  className="download-icon"
                  key={index  + "offline"}
                />
              </div>
            </div>
            })
          }
          </div>
        </div>
      </div>
    );
  }
}
// const mapDispatchToProps = (dispatch:any) => {
//   return{
//     addCoursesContent: (courseList:any) => {
//     dispatch(GET_COURSES(courseList));
//     },
//     addOrgContent: (orgList:any) => {
//       dispatch(GET_ORGANIZATION(orgList));
//     },
//  }
// }
// const mapStateToProps = (state:any) => {
//     return {
//       coursesContent: state?.HipPocketFireActions?.coursesDataList,
//       orgContent:state?.HipPocketFireActions?.organizationDataList[0],
//     };
// };

//@ts-ignore
const offlinepage = withRouter(CaursesPageOfflineAccess)
//@ts-ignore
export default withStyles(null)(offlinepage)
// Customizable Area End
