// Customizable Area Start
import React from "react";
import {
  Button,
  Grid,
  Dialog,
  Typography,
  Theme,
  createStyles,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CustomTextField from "./CustomTextField.web";
import {
  COURSE_REQUIRED,
  EMAIL_REQUIRED,
  DESTINATION_EMAIL_REQUIRED,
  NAME_REGX,
  NOT_VALID_NAME,
  EMAIL_REGX,
  NOT_VALID_EMAIL,
  //@ts-ignore
} from "./validation.js";
import "./FormDialog.scss";

interface Props {
  openModal: boolean;
  handleModalOpen: any;
  handleAssesmentFormData: () => void;
  setFormdataLocal: () => void;
  questions: any[];
  setShouldResetForm: (opt: boolean) => void;
  shouldResetForm: boolean;
  doSubmitFillableForm: any;
  formValues: any;
  dynamicFields: any[];
  resetDynamicFields: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  classes: any;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const FormPopUpOfFillableForm = ({
  openModal,
  handleModalOpen,
  handleAssesmentFormData,
  setFormdataLocal,
  questions,
  setShouldResetForm,
  shouldResetForm,
  formValues,
  doSubmitFillableForm,
  dynamicFields,
  resetDynamicFields
}: Props) => {
  // Customizable Area Start
  React.useEffect(() => {
    // setShouldResetForm(false);
  }, []);
  // Customizable Area End
  return (
    // Customizable Area Start
    <Dialog open={openModal} className="popUpModel">
      {
        //@ts-ignore
        <DialogTitle id="customized-dialog-title" onClose={handleModalOpen} />
      }
      <Formik
        key="dialog-formik"
        enableReinitialize
        initialValues={{
          email: "",
          destinationEmail: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .required(EMAIL_REQUIRED)
            .matches(EMAIL_REGX, NOT_VALID_EMAIL),
          destinationEmail: Yup.string()
            .required(EMAIL_REQUIRED)
            .matches(EMAIL_REGX, NOT_VALID_EMAIL)
            .test('email-match', 'Destination-email must not be same as your email', function(value){
              return this.parent.email !== value
            }),
        })}
        onSubmit={(values, { resetForm }): any => {
          let modalData = [values, formValues];
          doSubmitFillableForm(modalData, resetForm, "", dynamicFields || []);
          if(resetDynamicFields){
            resetDynamicFields();
          }
          handleModalOpen();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          handleBlur,
          setFieldValue,
        }) => (
          //@ts-ignore
          <Form key="dialog-form">
            <Grid container className="grid-container-form">
              <Grid
                item
                lg={12}
                className="grid-item-container-form InstructorNameField"
                key="grid-2"
              >
                <h4 style={{ width: "100%", color: "#f44336" }}>
                "Attention: The information you input will be formatted and sent in the body of an email."
                </h4>
              </Grid>
              {/* <Grid
                item
                lg={12}
                className="grid-item-container-form InstructorNameField"
                key="grid-2"
              >
                <h4 style={{ width: "100%" }}>
                  {formValues[0].instructorName ||
                    formValues[0].name ||
                    formValues[0].organizationName}
                </h4>
              </Grid> */}
              {/* <Grid
                item
                lg={12}
                className="grid-item-container-form"
                key="grid-2"
              >
                <CustomTextField
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={
                    formValues[0].instructorName ||
                    formValues[0].name ||
                    formValues[0].organizationName
                  }
                  touched={touched}
                  multiline={false}
                  placeholder="name"
                  name="name"
                  disabled={true}
                  required={true}
                  type="string"
                  key="name"
                />
              </Grid> */}
              <Grid
                item
                lg={12}
                className="grid-item-container-form"
                key="grid-2"
              >
                <CustomTextField
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.email || ""}
                  error={errors.email}
                  touched={touched}
                  errors={errors}
                  multiline={false}
                  placeholder="Your Email Address*"
                  name="email"
                  required={true}
                  type="string"
                  key="email"
                />
              </Grid>
              <Grid
                item
                lg={12}
                className="grid-item-container-form"
                key="grid-3"
              >
                <CustomTextField
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.destinationEmail || ""}
                  error={errors.destinationEmail}
                  touched={touched}
                  errors={errors}
                  multiline={false}
                  placeholder="Destination Email Address*"
                  name="destinationEmail"
                  required={true}
                  type="string"
                  key="destinationEmail"
                />
              </Grid>
            </Grid>
            <div className="courseAssesment-btn-container">
              <Button
                variant="contained"
                className="courseAssesment-btn"
                type="submit"
                onClick={(e) => {
                  handleSubmit();
                }}
              >
                SUBMIT
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
    // Customizable Area End
  );
};

const webStyle = {
  header: {
    backgroundColor: "#0C120C",
  },
};

//@ts-ignore
const headerWithRouter = withRouter(FormPopUpOfFillableForm);
export default withStyles(webStyle)(headerWithRouter);
// Customizable Area End
