import React from "react";

interface myProps {
  course: any;
  index: number;
  list: string;
  navigation: any;
  getCourseData: any;
  setState: any;
  setTabName: any;
  setValue: any;
}

const RelatedCourses = (props: myProps) => {
  let { course, index, list, navigation, getCourseData, setState, setTabName, setValue } = props;
  return (
    <div
      style={{ cursor: "pointer" }}
      key={course.id}
      className="related-courses-container"
    >
      <hr key={index} />
      <div
        className="div-course"
        key={index + "div"}
        style={{ justifyContent: "flex-start" }}
      >
        <img src={list} key={index + "list"} />
        <div
          onClick={() => {
            navigation.navigate(`Course`, {
              id: course.id,
            });
            getCourseData(course.id)
            setTabName('Course')
            setValue(0)
            setState({
              tabValue: 0,
              tabNames: "Course",
              isLoading: true,
            });
          }}
          key={index + "div-iden"}
        >
          <div className="div-identity-sub" key={index + "div-sub-iden"}>
            <h1 className="identity-line" key={index + "iden-line"}>
              {course.title || ""}
            </h1>
          </div>
          <div className="sub-div-identity" key={index + "div-sub-duration"}>
            <h1 className="duration" key={index + "H1"}>
              Duration: {course.duration || ""}
            </h1>
            &nbsp;{course.new_course && <h2>New</h2>}
            {course.c_img_urls &&
              course.c_img_urls.map((url: string) => {
                return <img src={url} />;
              })}
            {course.image_url && <img src={course.image_url} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedCourses;
