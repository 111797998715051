import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'; // imports from redux-persist
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer';
import localforage from 'localforage';
const persistConfig = {
  key: 'root',
  storage:localforage
};

const middleware = [];
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  applyMiddleware(...middleware),
  window.__REDUX_DEVTOOLS_EXTENSION_ && window.__REDUX_DEVTOOLS_EXTENSION_()
);

const persistor = persistStore(store);

export { store, persistor };
