// Customizable Area Start
import React from "react";
import "../assets/TermsAndConditionWeb.scss";
export const configJSON = require("./config");
import TermsAndConditionController, {
  Props,
} from "./TermsAndConditionControllerWeb.web";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer.web";
import CircularProgress from "@material-ui/core/CircularProgress";
export default class TermsAndConditionWeb extends TermsAndConditionController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-terms">
          {this.state.loadingContent ? (
            this.state.loadingContentNot ? (
              <div className="auth-form-wrapper-term  w-50">
                <div className="loader-class">
                  <h3>No data available</h3>
                </div>
              </div>
            ) : (
              <div className="auth-form-wrapper-term  w-50">
                <div className="loader-class">
                  <CircularProgress />
                </div>
              </div>
            )
          ) : (
            <div
              className="auth-form-wrapper-term w-50"
              dangerouslySetInnerHTML={{ __html: this?.state?.responseTerms }}
            />
          )}
        </div>
        <Footer />
      </>
    );
  }
}

// Customizable Area End
