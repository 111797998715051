import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
interface Props {
  // Customizable Area Start
 
  // Customizable Area End
}

const TempMerchandise: React.FC<Props> = () => {
  
  return (
    <h1>Coming Soon...</h1>
  );
};

const webStyle = {
  header: {
    backgroundColor: "#0C120C",
    display: "flex",
    flexDirection: "row",
  },
  routeContainer: {},
  routeButton: {
    marginRight: 5,
    // backgroundColor: "#0C120C",
    marginTop: 25,
    marginBottom: 25,
    height: "20px",
    width: "20px",
  },
  routeTitle: {
    //@ts-ignore
    fontWeight: "400",
    cursor: "pointer",
  },
};

//@ts-ignore
const MerchandiseWithRouter = withRouter(TempMerchandise);
//@ts-ignore
export default withStyles(webStyle)(MerchandiseWithRouter);
// Customizable Area End
