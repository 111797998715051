import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
//@ts-ignore
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
// Customizable Area End
export interface Props {
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isOffline: any
  value: any
  hover: any
  activeBtnClass: string
  searchStringValue: string
  isSearchClose: boolean
  // Customizable Area End
}

interface SS {
}
// Customizable Area Start
export default class ShopePageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.setSearchString = this.setSearchString.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      isOffline: '',
      value: 2,
      hover: -1,
      activeBtnClass: 'merchant',
      searchStringValue: '',
      isSearchClose: false,
      //getAllProducts: () => {}
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  //set searchString
  setSearchString = (value: string) => {
    console.log('value',value)
    if (value !== "") {
      this.setState({ searchStringValue: value });
    } else {
      this.setState({ searchStringValue: "" });
    }
  };
  setIsSearchClose = (flag:boolean) => {
    this.setState({
      isSearchClose: flag
    })
  }
  async componentDidMount() {
    if (!window.navigator.onLine) {
      this.OfflineWeb()
    } else {
      this.OnlineWeb()
    }
  }
  OfflineWeb = () => {
    Cookies.set("isOnline", false)
    this.setState({ isOffline: false })
  }
  OnlineWeb = () => {
    Cookies.set("isOnline", true)
    this.setState({ isOffline: true })
  }
  WindowOffline = () => {
    window.addEventListener("offline", () => {
      this.OfflineWeb()
    });
  }
  WindowOnline = () => {
    window.addEventListener('online', (event) => {
      this.OnlineWeb()
    });
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    // debugger
    if (prevState.isOffline !== this.state.isOffline) {
      if (!window.navigator.onLine) {
        this.OfflineWeb()
      } else {
        this.OnlineWeb()
      }
      this.WindowOffline()
      this.WindowOnline()

    }
  }
}
// Customizable Area End
