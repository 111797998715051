import React, { useRef, useState } from "react";
import { Button, Grid, Box } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomTextField from "./CustomTextField.web";
import "./CourseAssesment.scss";
interface Props {
  doSubmitFillableForm?: any;
  BackToMainPage?: any;
  staticFields?: any;
  questions?: any;
  setFormdataLocal?: (data: any[]) => void;
  shouldResetForm?: boolean;
  handleModalOpen: (val: boolean) => void;
  setShouldResetForm: (val: boolean) => void;
  courseData: any;
}

export default function AssessmentForm(props: Props) {
  const [initialValues, setInitialValues] = useState({});
  const [questionsValidation, setQuestionsValidation] = useState({});
  const [formQuestions, setFormQuestions] = useState<
    { id: number | string; question: string }[]
  >([]);
  const formRef = useRef();

  React.useEffect(() => {
    const data = {};
    setFormQuestions(props?.questions);
    props?.staticFields.map((item: any) => {
      //@ts-ignore
      data[item.fieldName] = "";
      if (item.fieldName == "TrainingName") {
        //@ts-ignore
        data[item.fieldName] = props.courseData.title || "";
      }
      return item;
    });
    let rowData = [...props?.questions];
    rowData.map((i, index) => {
      //@ts-ignore
      data[`Q${index}`] = "";
      return i;
    });
    setInitialValues(data);
    let obj: any = {};
    for (let x = 0; x < props?.questions.length; x++) {
      obj[`Q${x}`] = Yup.string()
        .required(`Answer is required`)
        .min(2, "Answer length should be atleast 2 char.");
    }
    setQuestionsValidation(obj);
  }, []);

  React.useEffect(() => {
    if (props.shouldResetForm) {
      //@ts-ignore
      formRef.current !== undefined && formRef.current.resetForm();
      setInitialValues({});
    }
  }, [props.shouldResetForm]);

  return (
    <>
      <Formik
        //@ts-ignore
        innerRef={formRef}
        initialValues={{
          ...initialValues,
          missionName: props.courseData.title || "",
        }}
        validationSchema={Yup.object().shape({
          // missionName: Yup.string()
          //   .required("Mission Name is required")
          //   .min(3, "Mission Name should be atleast more than 2 char."),
          // instructorName: Yup.string()
          //   .required("Instructor Name is required")
          //   .min(3, "Instructor Name should be atleast more than 2 char."),
          // dateOfTraining: Yup.string().required("Date is required"),
          ...questionsValidation,
        })}
        onSubmit={(values, { resetForm }): any => {
          props.handleModalOpen(true);
          let formData = [] as any;
          formQuestions.map((item, index) => {
            formData.push({
              question_id: item.id,
              answer: "",
              name: `Q${index}`,
            });
            return item;
          });
          for (let x in values) {
            {
              formData = formData.map((item: any) => {
                if (item.name === x) {
                  return {
                    ...item,
                    //@ts-ignore
                    answer: values[x],
                  };
                }
                return item;
              });
            }
          }
          formData = formData.map((item: any) => {
            delete item.name
            return item;
          });
          // //@ts-ignore
          // formData[0].mission_name = values?.missionName;
          // //@ts-ignore
          // formData[0].lead_names = [values?.instructorName];
          // //@ts-ignore
          // formData[0].date_of_training = values?.dateOfTraining;
          formData[0].sender_email = "";
          formData[0].recipient_email = "";
          formData[0].parent = true;
          //@ts-ignore
          props.setFormdataLocal(formData);
          props.setShouldResetForm(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          handleBlur,
          setFieldValue,
        }) => (
          //@ts-ignore
          <Form>
            <Grid container>
              {/* <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={2}
                className="fillableForm-Grid"
              >
                <CustomTextField
                  type={"string"}
                  required={true}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  //@ts-ignore
                  value={values?.missionName || ""}
                  touched={touched}
                  disabled={true}
                  //@ts-ignore
                  error={errors?.missionName || ""}
                  multiline={false}
                  label={"Training / Mission Name"}
                  placeholder={"Training/Mission Name*"}
                  name={"missionName"}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={2}
                className="fillableForm-Grid"
              >
                <CustomTextField
                  type={"string"}
                  required={true}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  //@ts-ignore
                  value={values?.instructorName || ""}
                  touched={touched}
                  //@ts-ignore
                  error={errors?.instructorName || ""}
                  multiline={false}
                  label={"Instructor/Lead Name(s)"}
                  placeholder={"Instructor/Lead Name(s)*"}
                  name={"instructorName"}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={2}
                className="fillableForm-Grid"
              >
                <CustomTextField
                  type={"string"}
                  required={true}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  //@ts-ignore
                  value={values?.dateOfTraining || ""}
                  touched={touched}
                  //@ts-ignore
                  error={errors?.dateOfTraining || ""}
                  multiline={false}
                  label={"Date of training/AAR"}
                  placeholder={"DD/MM/YYYY"}
                  name={"dateOfTraining"}
                />
              </Grid> */}
              {formQuestions.map((item, index) => {
                const { question, id } = item;
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={4}
                    xl={2}
                    className="fillableForm-Grid"
                  >
                    <CustomTextField
                      type={"string"}
                      required={true}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      //@ts-ignore
                      value={values[`Q${index}`] || ""}
                      touched={touched}
                      countCharNum={true}
                      //@ts-ignore
                      error={errors[`Q${index}`] || ""}
                      multiline={true}
                      label={question}
                      placeholder={"Message"}
                      name={`Q${index}`}
                      assessMentForm="assessMentForm"
                    />
                  </Grid>
                );
              })}
            </Grid>
            <div className="courseAssesment-btn-container-ext">
              <Button
                onClick={(e) => {
                  handleSubmit();
                }}
                variant="contained"
                className="courseAssesment-btn"
                type="submit"
              >
                CONTINUE
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
