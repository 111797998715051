// Customizable Area Start
import React from "react";
import "../assets/ShopePage.scss";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
export const configJSON = require("./config");
import TrainingAidController, { Props } from "./TrainingAidController.web";
import {
  getAllProductsAction,
  setLoaderOnScreen,
  //@ts-ignore
} from "../../../web/src/action.js";
import ProductList from "../../../components/src/ProductList.web";
import NoDataFoundFallback from "../../../components/src/NoDataFoundFallback.web";
import NoProductsFoundFallback from "../../../components/src/NoProductsFoundFallback.web";

class TrainingAidClass extends TrainingAidController {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { responseProducts, pageNumber, totalPages, errors ,adsDataAll} = this.state;

    return (
      <>
        {errors.length ? /^No Products found/.test(errors[0]) ? <NoProductsFoundFallback /> : <NoDataFoundFallback /> : null}
        {(!errors.length && this.props?.appState?.appReducer?.isLoading) ? (
          <div
            className="loader-class-course"
            test-id='loader-class-trainingAidId'
            style={{
              minHeight: "70vh",
              margin: "auto",
              paddingTop: "210px",
            }}
          >
            <CircularProgress />
          </div>
        ) : null}
        {(!errors.length && !this.props?.appState?.appReducer?.isLoading) ?(
            <>
              <h3 className="h3-disclaimer-shop" >
                The following products contain affiliate links. We may receive a commission if you make a purchase after clicking on one of these links.
              </h3>
              <ProductList products={responseProducts} totalPages={totalPages} page={pageNumber} pageChange={this.handlePageChange} affiliate_count={this.updateAffiliateCounts} adsData={adsDataAll}/>
            </>
          ) : null
        }
      </>

    )
  }
}
export const mapDispatchToProps = (dispatch: any) => {
  return {
    saveProductsToRedux: (products: any) =>
      dispatch(getAllProductsAction(products)),
    setLoading: (data: any) => {
      dispatch(setLoaderOnScreen(data));
    },
  };
};
export const mapStateToProps = (state: any) => {
  return {
    isOnline: state?.HipPocketFireActions?.isOnline,
    appState: state,
  };
};

const TrainingAid = withRouter(
  //@ts-ignore
  connect(mapStateToProps, mapDispatchToProps)(TrainingAidClass)
);
export { TrainingAidClass }
// @ts-ignore
export default withStyles(null)(TrainingAid);
// Customizable Area End
