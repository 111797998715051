import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
//@ts-ignore
import { debounce} from "lodash";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  addCoursesContent?: any;
  coursesContent?: any;
  addOrgContent?:any;
  orgContent?:any;
  history?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  responseData: any;
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class CaursesPageOfflineAccessController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      responseData:[]
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


async componentDidMount() {
 let data=JSON.parse( localStorage.getItem("allCourses") as any)

if(data){
  this.setState({responseData:data})
}
}
}
// Customizable Area End