import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  NotificationContainer,
  NotificationManager,
  //@ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
//@ts-ignore
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  addContactApiCallId: any;
  countCharNum: number = 250;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.addContactApiCallId = "";

    this.state = {
      name: "",
      email: "",
      loading: false,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    if (!window.navigator.onLine) {
      Cookies.set("isOnline", false);
      this.props.navigation.navigat("Home");
    } else {
      Cookies.set("isOnline", true);
    }
  }
  componentDidUpdate(prevProps: any, prevState: any) {
    if (!window.navigator.onLine) {
      Cookies.set("isOnline", false);
      this.props.navigation.navigat("Home");
    } else {
      Cookies.set("isOnline", true);
    }
    window.addEventListener("offline", () => {
      Cookies.set("isOnline", false);
      this.props.navigation.navigat("Home");
    });
    window.addEventListener("online", (event) => {
      Cookies.set("isOnline", true);
    });
  }
  doSubmit(value?: any, resetForm?: any) {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
    };
    const data = {
      name: value.name,
      email: value.email,
      description: value.messege,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addContactApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contactEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    resetForm();
    this.countCharNum = 250;
    return true;
  }
  //Counting characters of messege
  countChar(val: any) {
    let len = val.target.value.length;

    if (len >= 250) {
      val.target.value = val.target.value.substring(0, 250);
    } else {
      this.countCharNum = 250 - len;
    }
    return true;
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (errorReponse) {
        NotificationManager.error("", errorReponse);
      }
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.addContactApiCallId &&
            responseJson !== undefined
          ) {
            this.setState({ loading: false });
            NotificationManager.success("", "Messege is submitted");
            //  toast.success("Messege is submitted.",{position: toast.POSITION.BOTTOM_RIGHT})
          }
        }
      } else {
        // this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
        this.setState({ loading: false });
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (apiRequestCallId === this.addContactApiCallId) {
            NotificationManager.error("", errors[0].contact[0]);
            //  toast.success("Messege is submitted.",{position: toast.POSITION.BOTTOM_RIGHT})
          }
        }
      }
    }
    // Customizable Area End
  }
}
// Customizable Area End
