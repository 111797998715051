// import baseURLNew from "../../framework/src/config";

export const configJSONBase = require("../../../packages/framework/src/config");

export const CustomAdsCounts = async (id:any): Promise<boolean> => {
    try {
     const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

const raw = JSON.stringify({
  "custom_ad": {
    "id": id,
    "is_clicked": true
  }
});

const requestOptions: RequestInit = {
  method: "PUT",
  headers: myHeaders,
  body: raw,
  redirect: "follow"
};

fetch(`${configJSONBase.baseURL}/bx_block_custom_ads/update_clicked_count`, requestOptions)
  .then(response => response.text())
  .then(result => console.log(""))
  .catch(error => console.error(error));
  return true;
    } catch (error) {
      console.error(error);
      return false; // Return false in case of error
    }
  };
  