import * as React from 'react'
//@ts-ignore
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../src/StoreProduct.scss"
import Rating from '@material-ui/lab/Rating';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import { prime } from "../../blocks/filteritems/src/assets";
import StoreProductModal from './StoreProductModal';

interface Props {
  // Customizable Area Start
  product: any
  affiliate_count: any
  // Customizable Area End
}
const StoreProducts: React.FC<Props> = ({ product, affiliate_count }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (isApiCallRequired : Boolean) => {
    setOpen(false)
    if(isApiCallRequired){
      affiliate_count()
    }
  };

  const sliderRef = React.useRef<Slider>(null);
  const [intervalId, setIntervalId] = React.useState<number | null>(null);

  React.useEffect(() => {
    const sliderElement = sliderRef.current;

    // Start sliding when mouse enters the slider
    const handleMouseEnter = () => {
      if (sliderElement) {
        const id = window.setInterval(() => {
          sliderElement.slickNext();
        }, 1000);
        setIntervalId(id);
      }
    };

    // Stop sliding when mouse leaves the slider
    const handleMouseLeave = () => {
      if (intervalId) {
        window.clearInterval(intervalId);
        setIntervalId(null);
      }
    };

    if (sliderElement) {
      sliderElement.innerSlider.list.addEventListener('mouseenter', handleMouseEnter);
      sliderElement.innerSlider.list.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (sliderElement) {
        sliderElement.innerSlider.list.removeEventListener('mouseenter', handleMouseEnter);
        sliderElement.innerSlider.list.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, [intervalId]);


  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <React.Fragment>
      <div className="main-product-div">
        <div className="particuler-div">
          {product?.attributes?.condition === "New" && (<h2 className="h2-new product-cursor" onClick={handleOpen}>New</h2>)}
          <Slider {...settings} ref={sliderRef} >
            {Array.isArray(product?.attributes?.image_variants) ? (
              product?.attributes?.image_variants.map((imageUrl: string | undefined, index: React.Key | undefined) => (
                <div key={index} onClick={handleOpen} className="product-cursor">
                  <img src={imageUrl} alt="" />
                </div>
              ))
            ) : (
              <div onClick={handleOpen} className="product-cursor">
                <img src={product?.attributes?.image_url} alt="" />
              </div>
            )}
          </Slider>
          <span className="content-decs product-cursor" onClick={handleOpen}>
            {product.attributes.title}
          </span>
          <div className="rating-part product-cursor" onClick={handleOpen}>
            {product?.attributes?.star_rating && product?.attributes?.star_rating!==0 &&(
              <Rating
              name="hover-feedback"
              value={product?.attributes?.star_rating}
              precision={0.5}
              readOnly
              emptyIcon={<StarBorderRoundedIcon fontSize="inherit" />}
              icon={<StarRoundedIcon fontSize="inherit" />}
            />
            )}
            {product?.attributes?.review_count && product?.attributes?.review_count===0 &&(
              <p onClick={handleOpen} className="product-cursor">{product?.attributes?.review_count}</p>
            )}
          </div>
          <div className="last-text-line">
            {product?.attributes?.display_amount && (
              <p onClick={handleOpen} className="product-cursor display-amount">
                ${product?.attributes.display_amount}
              </p>
            )}
            {!!product?.attributes?.price && product?.attributes?.price !== "0.0" && (
              <p onClick={handleOpen} className="product-cursor price">
                ${product?.attributes?.price}
              </p>
            )}&nbsp;
            {!!product?.attributes?.discount_percent && product?.attributes?.discount_percent !== 0 && (
              <p onClick={handleOpen} className="product-cursor discount-percent">
                {product?.attributes.discount_percent}% off
              </p>
            )}&nbsp;
            {product?.attributes?.prime_eligible && (<img src={prime} alt="" onClick={handleOpen} className="product-cursor prime-eligible" />)}
          </div>
        </div>
      </div>
      <StoreProductModal open={open} handleClose={handleClose} url={product.attributes.url} />
    </React.Fragment>)
}
export default StoreProducts