import React from "react";
import { Button, Grid, Box } from "@material-ui/core";
// import { useFormik } from "formik";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import CustomTextField from "./CustomTextField.web";
import "./FillableForms.scss";
import ReviewForm from "./ReviewForm";
import LeedSheetForm from "./LeedSheetForm";
import MemoForm from "./MemoForm";

interface Props {
  formName: string;
  doSubmitFillableForm:any;
  BackToMainPage:any
}

const renderForm = (name: string, doSubmitFillableForm:any,BackToMainPage:any) => {
  switch (name) {
    case "After Action Review Form":
      return <ReviewForm doSubmitFillableForm={doSubmitFillableForm} BackToMainPage={BackToMainPage}/>;
    case "Lead Sheet":
      return <LeedSheetForm doSubmitFillableForm={doSubmitFillableForm} BackToMainPage={BackToMainPage}/>;
    case "Army Formatted Memo Form":
      return <MemoForm doSubmitFillableForm={doSubmitFillableForm} BackToMainPage={BackToMainPage}/>;
    default:
      return <></>;
  }
};

export default function FillableForms(props: Props) {
  return <>{renderForm(props.formName,props.doSubmitFillableForm,props.BackToMainPage)}</>;
}
