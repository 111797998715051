// Customizable Area Start
import React from "react";
import { Button, Grid, Dialog, Typography, Theme, createStyles, withStyles, WithStyles } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CustomTextField from "./CustomTextField.web";
import {
  COURSE_REQUIRED,
  EMAIL_REQUIRED,
  DESTINATION_EMAIL_REQUIRED,
  NAME_REGX,
  NOT_VALID_NAME,
  EMAIL_REGX,
  NOT_VALID_EMAIL,
  //@ts-ignore
} from "./validation.js";
import "./FormDialog.scss";

interface Props {
  openModal: boolean;
  handleModalOpen: any;
  handleAssesmentFormData: (data: any[]) => void;
  setFormdataLocal: () => void;
  questions: any[];
  setShouldResetForm: (opt: boolean) => void;
  shouldResetForm : boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  classes: any;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const FormDialog = ({
  openModal,
  handleModalOpen,
  handleAssesmentFormData,
  setFormdataLocal,
  questions,
  setShouldResetForm,
  shouldResetForm
}: Props) => {
  // Customizable Area Start
  React.useEffect(() =>{
    setShouldResetForm(false);
  }, [])
  // Customizable Area End

  return (
    // Customizable Area Start
    <Dialog open={openModal}  className="popUpModel">
      <DialogTitle id="customized-dialog-title" onClose={() => handleModalOpen(false)}>
        </DialogTitle>
      <Formik
        key="dialog-formik"
        enableReinitialize
        initialValues={{
          name: "",
          email: "",
          destinationEmail: "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .required(COURSE_REQUIRED)
            .matches(NAME_REGX, NOT_VALID_NAME),
          email: Yup.string()
            .required(EMAIL_REQUIRED)
            .matches(EMAIL_REGX, NOT_VALID_EMAIL),
        })}
        onSubmit={(values, { resetForm }): any => {
          let tempData = questions;
          tempData[0].sender_email = values.email;
          tempData[0].recipient_email = values.destinationEmail;
          tempData[0].name = values.name;
          //@ts-ignore
          setFormdataLocal(tempData);
          // //@ts-ignore
          handleAssesmentFormData(tempData);
          setShouldResetForm(true);
          handleModalOpen(false);
          resetForm();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          handleBlur,
          setFieldValue,
        }) => (
          //@ts-ignore
          <Form key="dialog-form">
            <Grid container className="grid-container-form">
              <Grid
                item
                lg={12}
                className="grid-item-container-form"
                key="grid-1"
              >
                <CustomTextField
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.name || ""}
                  error={errors.name}
                  touched={touched}
                  errors={errors}
                  multiline={false}
                  placeholder="Name*"
                  name="name"
                  required={true}
                  type="string"
                  key="name"
                />
              </Grid>
              <Grid
                item
                lg={12}
                className="grid-item-container-form"
                key="grid-2"
              >
                <CustomTextField
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.email || ""}
                  error={errors.email}
                  touched={touched}
                  errors={errors}
                  multiline={false}
                  placeholder="Your Email Address*"
                  name="email"
                  required={true}
                  type="string"
                  key="email"
                />
              </Grid>
              {/* <Grid
                item
                lg={12}
                className="grid-item-container-form"
                key="grid-3"
              >
                <CustomTextField
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.destinationEmail || ""}
                  error={errors.destinationEmail}
                  touched={touched}
                  errors={errors}
                  multiline={false}
                  placeholder="Destination Email*"
                  name="destinationEmail"
                  required={true}
                  type="string"
                  key="destinationEmail"
                />
              </Grid> */}
            </Grid>
            <div className="courseAssesment-btn-container">
              <Button
                variant="contained"
                className="courseAssesment-btn"
                type="submit"
              >
                SUBMIT
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
    // Customizable Area End
  );
};

const webStyle = {
  header: {
    backgroundColor: "#0C120C",
  },
};

//@ts-ignore
const headerWithRouter = withRouter(FormDialog);
export default withStyles(webStyle)(headerWithRouter);
// Customizable Area End
