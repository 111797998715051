Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TermsAndConditions";
exports.labelBodyText = "TermsAndConditions Body";
exports.termsEndPoint="bx_block_termsandconditions/settings"

exports.btnExampleTitle = "CLICK ME";
exports.termsEndPoint="bx_block_termsandconditions/settings"
// Customizable Area End