import React, { useState } from "react";
import { Button, Grid, Box } from "@material-ui/core";
// import { useFormik } from "formik";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import CustomTextField from "./CustomTextField.web";
import FormPopUpOfFillableForm from "./FormPopUpOfFillableForm.web";
import "./FillableForms.scss";

interface Props {
  doSubmitFillableForm: any;
  BackToMainPage: any;
}

export default function ReviewForm(props: Props) {
  const [modalOpen, setmodalOpen] = useState<boolean>(false);
  const [formValues, setformValues] = useState<any>("");
  const handleModalOpen = () => {
    setmodalOpen(false);
  };
  return (
    <>
      {/* <Button
              onClick={(e) => {
                 props.BackToMainPage()
              }}
              variant="contained"
              className="courseAssesment-btn"
              // type="submit"
            >
             {"<< Go Back"}
            </Button> */}
      <Formik
        initialValues={{
          missionName: "",
          instructorName: "",
          dateOfTraining: "",
          questionOne: "",
          questionTwo: "",
          questionThree: "",
          questionFour: "",
        }}
        validationSchema={Yup.object().shape({
          missionName: Yup.string()
            .required("Mission Name is required")
            .min(2, "Mission Name should be at least 2 char."),
          instructorName: Yup.string()
            .required("Instructor Name is required")
            .min(2, "Instructor Name should be at least 2 char."),
          dateOfTraining: Yup.string().required("Date is required"),
          questionOne: Yup.string()
            .required("This Field is required")
            .min(3, "This Field should be at least 3 char."),
          questionTwo: Yup.string()
            .required("This Field is required")
            .min(3, "This Field should be at least  3 char."),
          questionThree: Yup.string()
            .required("This Field is required")
            .min(3, "This Field should be at least 3 char."),
          questionFour: Yup.string()
            .required("This Field is required")
            .min(3, "This Field should be at least 3 char."),
        })}
        onSubmit={(values, { resetForm }): any => {
          // this.doSubmit(values, resetForm);
          setformValues([
            values,
            resetForm,
            "after_action_review_custom_forms",
          ]);
          setmodalOpen(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          handleBlur,
          setFieldValue,
        }) => (
          //@ts-ignore
          <Form>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={2}
                className="fillableForm-Grid"
              >
                <CustomTextField
                  type={"string"}
                  required={true}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.missionName}
                  touched={touched}
                  error={touched.missionName && errors.missionName}
                  multiline={false}
                  label={"Training / Mission Name"}
                  placeholder={"Training/Mission Name*"}
                  name={"missionName"}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={2}
                className="fillableForm-Grid"
              >
                <CustomTextField
                  type={"string"}
                  required={true}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.instructorName}
                  touched={touched}
                  error={touched.instructorName && errors.instructorName}
                  multiline={false}
                  label={"Instructor/Lead Name(s)"}
                  placeholder={"Instructor/Lead Name(s)*"}
                  name={"instructorName"}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={2}
                className="fillableForm-Grid"
              >
                <CustomTextField
                  type={"string"}
                  required={true}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  //@ts-ignore
                  value={values.dateOfTraining}
                  touched={touched}
                  error={touched.dateOfTraining && errors.dateOfTraining}
                  multiline={false}
                  label={"Date of training/AAR"}
                  placeholder={"DD/MM/YYYY*"}
                  name={"dateOfTraining"}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={2}
                className="fillableForm-Grid"
              >
                <CustomTextField
                  type={"string"}
                  required={true}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.questionOne}
                  touched={touched}
                  countCharNum={true}
                  error={touched.questionOne && errors.questionOne}
                  multiline={true}
                  label={"1. What did we expect to happen?"}
                  placeholder={"Message*"}
                  name={"questionOne"}
                  classname="multiline-textfield"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={2}
                className="fillableForm-Grid"
              >
                <CustomTextField
                  type={"string"}
                  required={true}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.questionTwo}
                  touched={touched}
                  countCharNum={true}
                  error={touched.questionTwo && errors.questionTwo}
                  multiline={true}
                  label={"2. What actually occurred?"}
                  placeholder={"Message*"}
                  name={"questionTwo"}
                  classname="multiline-textfield"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={2}
                className="fillableForm-Grid"
              >
                <CustomTextField
                  type={"string"}
                  required={true}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  //@ts-ignore
                  value={values.questionThree}
                  touched={touched}
                  countCharNum={true}
                  error={touched.questionThree && errors.questionThree}
                  multiline={true}
                  label={"3. What went well and why?"}
                  placeholder={"Message*"}
                  name={"questionThree"}
                  classname="multiline-textfield"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                xl={2}
                className="fillableForm-Grid"
              >
                <CustomTextField
                  type={"string"}
                  required={true}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  //@ts-ignore
                  value={values.questionFour}
                  touched={touched}
                  countCharNum={true}
                  error={touched.questionFour && errors.questionFour}
                  multiline={true}
                  label={"4. What can be improved upon and how?"}
                  placeholder={"Message*"}
                  name={"questionFour"}
                  classname="multiline-textfield"
                />
              </Grid>
            </Grid>
            <div className="courseAssesment-btn-container-ext">
              <Button
                onClick={(e) => {
                  handleSubmit();
                }}
                variant="contained"
                className="courseAssesment-btn"
                type="submit"
              >
                CONTINUE
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <FormPopUpOfFillableForm
        //@ts-ignore
        handleModalOpen={handleModalOpen}
        doSubmitFillableForm={props.doSubmitFillableForm}
        formValues={formValues}
        openModal={modalOpen}
      />
    </>
  );
}
