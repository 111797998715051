import React from 'react';
import GoogleAdsenseIntegration from '../../blocks/GoogleAdsenseIntegration/src/GoogleAdsenseIntegration';
import ContactUsAds from './ContactUsAds';
import { makeStyles } from '@material-ui/core/styles';
import { Card,CardActionArea, CardMedia} from "@material-ui/core";
import Link from '@material-ui/core/Link';
import { CustomAdsCounts } from './CustomAdsCounts';
export const configJSON = require("../../../packages/framework/src/config");
const useStyles = makeStyles((theme)=>({
  media: {
    objectFit:"contain",
    backgroundSize:"contain",
    height: 300,
    [theme.breakpoints.down('sm')]: {
      height: 150, // Adjust height for smaller screens
    },
  },
}));
interface AdSolutionProps {
  adsList: [{
    ad_type:string,
    custom_ad?:{
        id:number;
        name:string;
        url:string;
        web_large_custom_image:string;
    },
    contact_us_ad?:{
        name:string;
        web_large_contact_us_image:string;
    },
  }]; 
}
const CourseDeatailsAds: React.FC<AdSolutionProps> = ({adsList}) => {
  const classes = useStyles();
  const handleClickDetail = (e:any,poppedIndex:any) => {
    CustomAdsCounts(adsList[0]?.custom_ad?.id);
  };
  const handleDetailsContextMenu = (event:any) => {
    event.preventDefault();

    
  };
  if (adsList[0]?.ad_type === "Custom Ad") {
   return <div style={{padding:"15px"}}><Link onClick={(e:any)=>handleClickDetail(e, adsList[0]?.custom_ad)} onContextMenu={handleDetailsContextMenu} target="_blank" href={adsList.length>0 && adsList[0]?.custom_ad ? adsList[0]?.custom_ad?.url :""} rel="noreferrer">
   <Card >
     <CardActionArea>
       <CardMedia
         className={classes.media}
         image={adsList.length>0 && adsList[0]?.custom_ad ? adsList[0]?.custom_ad?.web_large_custom_image:""}
         title={adsList.length>0 && adsList[0]?.custom_ad ? adsList[0]?.custom_ad?.name:""}
       />
     </CardActionArea>  
   </Card>
   </Link></div>;
  } else if (adsList[0]?.ad_type === "Google Ad") {
    return <div style={{padding:"15px"}}><GoogleAdsenseIntegration navigation={undefined} id={""} /></div>
  } else if (adsList[0]?.ad_type === "Contact Us Ad") {
    return <div style={{padding:"15px"}}><ContactUsAds banner={adsList.length>0 && adsList[0]?.contact_us_ad ? adsList[0]?.contact_us_ad?.web_large_contact_us_image : ""} itemCover={4}/></div>
  }
  return null;
};

export default CourseDeatailsAds;
