export const airForce=require("../assets/airForceIcon.png")
export const all = require("../assets/allicon.png")
export const army = require("../assets/army.png")
export const coast = require("../assets/coastIcon.png")
export const emt = require("../assets/emtIcon.png")
export const fire = require("../assets/fireIcon.png")
export const marine = require("../assets/marineIcon.png")
export const navy = require("../assets/navyIcon.png")
export const police = require("../assets/policeIcon.png")
export const list = require("../assets/listIcon.svg")
export const newIcon = require("../assets/new.png")
export const download = require("../assets/download.svg")
export const offline = require("../assets/offline.svg")
export const noData=require("../assets/nodataFound.png")
export const prime=require("../assets/prime.svg")
export const noProductFound=require("../assets/no-product-found.png")