// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any;
  isOnline?: boolean;
  saveProductsToRedux: any;
  appState: any;
  setLoading: any;
  searchStringValue: string;
  isSearchClose: boolean;
  setIsSearchClose: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  totalPages: number;
  pageNumber: number;
  responseProducts: any;
  errors: string[];
  adsDataAll:any[];
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class TrainingAidController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductsApiCallId: any;
  getProductsBySearchApiCallId: any;
  updateAffiliateCountsApiCallId: any;
  productsPerPage: number;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    
// Customizable Area Start
    this.state = {
      adsDataAll:[],
      totalPages: 0,
      pageNumber: 1,
      responseProducts: [],
      errors: []
    };
    this.getProductsApiCallId = "";
    this.getProductsBySearchApiCallId = "";
    this.updateAffiliateCountsApiCallId = "";
    this.productsPerPage = 12;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    this.getAllProducts(this.state.pageNumber)
  }


  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.searchStringValue !== prevProps.searchStringValue && this.props.searchStringValue !== "getAll") {
      this.getSearchedProducts(this.props.searchStringValue)
    }
    if (this.props.isSearchClose !== prevProps.isSearchClose && this.props.isSearchClose) {
      this.getAllProducts()
      this.props.setIsSearchClose(false);
    }
    if( this.props.searchStringValue !== prevProps.searchStringValue && this.props.searchStringValue == "getAll"){
      this.getAllProducts()
    }
    if(this.state.pageNumber !== prevState.pageNumber) {
      this.getAllProducts(this.state.pageNumber)
    }
  }

  handlePageChange = (event: any, value: any) => {
    this.setState({
      pageNumber: value
    })
  }

  //getting Products
  getAllProducts = (pageNumber?: number): boolean => {
    this.props.setLoading(true)
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductsApiCallId = requestMessage.messageId;

    if (pageNumber) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.productsEndPoint + "?page=" + pageNumber
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.productsEndPoint + "?page=" + 1
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  //getting searched products
  getSearchedProducts = (searchString: string, pageNumber?: number): boolean => {
    if (searchString.length >= 1) {
      this.props?.setLoading(true)
      const header = {
        "Content-Type": configJSON.searchProductsApiContentType,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getProductsBySearchApiCallId = requestMessage.messageId;

      if (pageNumber) {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.searchProductsApiEndPoint +
          searchString +
          `&page=${pageNumber}`
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.searchProductsApiEndPoint + searchString + `&page=1`
        );
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.searchProductsApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      this.setState({ pageNumber: 1 })

      return true;
    } else {
      return false;
    }
  };

  //affiliate counts
  updateAffiliateCounts = (): boolean => {
    const header = {
      "Content-Type": configJSON.affiliatesCountApiContentType,
    };

    const requestBody = {
      "affiliate_click": "true"
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.updateAffiliateCountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.affiliatesCountApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.affiliatesCountApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({errors: []});
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (responseJson && !responseJson.errors) {
      if (apiRequestCallId != null) {
        const handleApiResponse = (responseJson: any) => {
          this.props.saveProductsToRedux(responseJson?.products?.data);
          const products = responseJson?.matching_products ? responseJson?.matching_products.data : responseJson?.products?.data;
          const adsData=responseJson?.ads_data;
          if (responseJson?.products?.data.length !== 0) {
            this.setState({
              adsDataAll:adsData,
              responseProducts: products,
              totalPages: Math.ceil(responseJson.count / this.productsPerPage)
            });
            this.props?.setLoading(false);
          }
        };

        if (
          apiRequestCallId === this.getProductsApiCallId &&
          responseJson !== undefined
        ) {
          handleApiResponse(responseJson);
        }

        if (
          apiRequestCallId === this.getProductsBySearchApiCallId &&
          responseJson !== undefined
        ) {
          handleApiResponse(responseJson);
        }
      }
    } else {
        this.setState({
          responseProducts: [],
          pageNumber: 1,
          totalPages: 0,
          errors: responseJson?.errors || ['noData']
        });
        this.props?.setLoading(false)
    }
    // Customizable Area End
  }

}
// Customizable Area End
// Customizable Area End


