import React, { useState } from "react";
import {
  Button,
  Grid,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  TextField
} from "@material-ui/core";
// import { useFormik } from "formik";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import CustomTextField from "./CustomTextField.web";
import FormPopUpOfFillableForm from "./FormPopUpOfFillableForm.web";
import "./FillableForms.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const country=require("./countries.json")
interface Props {
  doSubmitFillableForm: any;
  BackToMainPage: any;
}

export default function LeedSheetForm(props: Props) {
  const [phone, setphone] = useState();
  const [modalOpen, setmodalOpen] = useState<boolean>(false);
  const [formValues, setformValues] = useState<any>("");
  const handleModalOpen = () => {
    setmodalOpen(false);
  };
  
  
  return (
    <>
      {/* <Button
              onClick={(e) => {
                 props.BackToMainPage()
              }}
              variant="contained"
              className="courseAssesment-btn"
              // type="submit"
            >
             {"<< Go Back"}
            </Button> */}
      <Formik
        initialValues={{
          date: "",
          name: "",
          age: "",
          address: "",
          phoneCode: "+1",
          phone: "",
        }}
        validationSchema={Yup.object().shape({
          date: Yup.string().required("Date is required"),
          name: Yup.string()
            .required("Name is required")
            .min(2, "Name should be at least 2 characters"),
          age: Yup.string()
            .required("Age is required")
            .matches(
              /^([1-9][0-9]{0,2}|1000)$/,
              "Please fill with proper format."
            )
            .max(2, "Age should not be more than 2 digit."),
          address: Yup.string()
            .required("Address is required")
            .min(3, "Address should be at least 3 characters."),
          phone: Yup.string()
            .required("Phone Number is required").matches(/^[0-9]*$/,"Only numbers allowed.")
            .min(10, "Phone Number should be at least  10 numbers.")
            .max(15, "Phone Number should not be more than 15 numbers."),
        })}
        onSubmit={(values, { resetForm }): any => {
          // this.doSubmit(values, resetForm);
          setformValues([values, resetForm, "lead_sheet_custom_forms"]);
          setmodalOpen(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          handleBlur,
          setFieldValue,
        }) => (
          //@ts-ignore
          <Form>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={4} lg={4} xl={2} className="fillableForm-Grid">
                  <CustomTextField
                    type={"string"}
                    required={true}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    //@ts-ignore
                    value={values.date}
                    touched={touched}
                    error={touched.date && errors.date}
                    multiline={false}
                    maxDate = {new Date()}
                    label={"Date"}
                    placeholder={"DD/MM/YYYY*"}
                    name={"date"}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4} xl={2} className="fillableForm-Grid">
                  <CustomTextField
                    type={"string"}
                    required={true}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.name}
                    touched={touched}
                    error={touched.name && errors.name}
                    multiline={false}
                    label={"Name"}
                    placeholder={"Name*"}
                    name={"name"}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4} xl={2} className="fillableForm-Grid">
                  <CustomTextField
                    type={"string"}
                    required={true}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.age}
                    touched={touched}
                    error={touched.age && errors.age}
                    multiline={false}
                    label={"Age"}
                    placeholder={"Age*"}
                    name={"age"}
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4} xl={2} className="fillableForm-Grid">
                  <CustomTextField
                    type={"string"}
                    required={true}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.address}
                    touched={touched}
                    countCharNum={true}
                    error={touched.address && errors.address}
                    multiline={true}
                    label={"Address"}
                    placeholder={"Message*"}
                    name={"address"}
                    classname="multinew-textfield"
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4} xl={2} className="fillableForm-Grid">
                  <h4 className="h4-phoneNumber">Phone Number</h4>
                  <div style={{  display:'flex', width: "308px",alignItems:'flex-start'}}>
                    <TextField
                      id="standard-select-currency"
                      select
                      // label="This is TextField component"
                      value={values.phoneCode}
                      onChange={(e:any)=>{setFieldValue('phoneCode',e.target.value)}}
                      variant="outlined"
                      className="lead-select"
                      style={{
                        justifyContent: 'center',
                        marginBottom: '10px'
                      }}
                      name={"phoneCode"}
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        },
                      }}
                    >
                      {
                        country?.countries.sort().map((allCodes:any,index:number) => {
                          return(
                            <MenuItem value={allCodes.code} className="phoneNumberSelect">{allCodes.code}</MenuItem>
                          )
                        })
                      }
                      
                    </TextField>
                    <CustomTextField
                    type={"string"}
                    required={true}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.phone}
                    touched={touched}
                    error={touched.phone && errors.phone}
                    multiline={false}
                    // label={"Phone Number"}
                    placeholder={"Phone Number*"}
                    name={"phone"}
                    classname="mobile-no"
                  />
                  </div>

                 
                  {/* <PhoneInput
                country={'us'}
                value={phone}
                disableSearchIcon={true}
                disableCountryGuess={true}
                onChange={(phone:any) => setphone(phone)}
              /> */}
                </Grid>
              </Grid>
            </Box>
            <div className="courseAssesment-btn-container-ext" style={{marginTop:'20px'}}>
              <Button
                onClick={(e) => {
                  handleSubmit();
                }}
                variant="contained"
                className="courseAssesment-btn"
                type="submit"
              >
                CONTINUE
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <FormPopUpOfFillableForm
        //@ts-ignore
        handleModalOpen={handleModalOpen}
        doSubmitFillableForm={props.doSubmitFillableForm}
        formValues={formValues}
        // questions={}
        // setFormdataLocal={}
        // handleAssesmentFormData={}
        openModal={modalOpen}
        // setShouldResetForm = {}
        // shouldResetForm = {}
      />
    </>
  );
}
