// Customizable Area Start
import React from "react";
import { Button } from "@material-ui/core";
//@ts-ignore
import AlphabetList from "react-alphabet-list";
import "../assets/TrainingS1Web.scss";
import { withRouter } from "react-router-dom";
import CancelIcon from "@material-ui/icons/Cancel";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfiniteScroll from "react-infinite-scroll-component";
import { Grid, Box } from "@material-ui/core";
import { connect } from "react-redux";
//@ts-ignore
import {
  GET_ORGANIZATION,
  //@ts-ignore
} from "../../../web/src/HipPocketAllActions.js";
export const configJSON = require("./config");
import Tooltip from "@material-ui/core/Tooltip";
import TrainingS1WebController, { Props } from "./TrainingS1WebController.web";
import Header from "../../../components/src/HeaderApp.web";
import Footer from "../../../components/src/Footer.web";
import { list, offline, download, noData } from "./assets";
import DrawerComponent from "../../../components/src/Drawer.web";
import {
  getAllCoursesAction,
  setFilterFieldByName,
  setFilterValueByName,
  setLoaderOnScreen,
  //@ts-ignore
} from "../../../web/src/action.js";
import AdsSolution from "../../../components/src/AdsSolution";
import CourseListAds from "../../../components/src/CourseListAds";

class TrainingS1Web extends TrainingS1WebController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    let current = "";
    let alphaBetsList = [] as any;
    let getId = (index: number, course: any) => {
      if (index === 0) {
        current = course?.title?.trim().charAt(0);
        if (alphaBetsList?.length !== 0) {
          if (
            !alphaBetsList.includes(
              course?.title
                ?.trim()
                .charAt(0)
                .toUpperCase()
            )
          ) {
            alphaBetsList.push(
              course?.title
                ?.trim()
                .charAt(0)
                .toUpperCase()
            );
          }
        } else if (alphaBetsList?.length === 0) {
          alphaBetsList.push(
            course?.title
              ?.trim()
              .charAt(0)
              .toUpperCase()
          );
        }
        return course?.title
          ?.trim()
          .charAt(0)
          .toUpperCase();
      } else if (course?.title?.trim().charAt(0) === current) {
        return "";
      } else {
        current = course?.title?.trim().charAt(0);
        if (alphaBetsList?.length !== 0) {
          if (
            !alphaBetsList.includes(
              course?.title
                ?.trim()
                .charAt(0)
                .toUpperCase()
            )
          ) {
            alphaBetsList.push(
              course?.title
                ?.trim()
                .charAt(0)
                .toUpperCase()
            );
          }
        } else if (alphaBetsList?.length === 0) {
          alphaBetsList.push(
            course?.title
              ?.trim()
              .charAt(0)
              .toUpperCase()
          );
        }
        return course?.title
          ?.trim()
          .charAt(0)
          .toUpperCase();
      }
    };

    const state = this.props?.appState?.appReducer;
 
    
    return (
      <>
        <div className="MainDivOfCourses" id="scrollableDiv">
          <Grid container direction="row">
            <Header
              //@ts-ignore
              searchValue={this.state.searchString}
              setSearchValue={this.setSearchString}
              handleSidebar={this.handleSidebar}
              autoCompleteFields={this.state.autoCompleteFields}
              getAutoCompleteFieldsData={this.getAutoCompleteFieldsData}
              cleareautoCompleteFields={this.cleareautoCompleteFields}
              sortBy={state?.sortBy}
              schoolFilterValue={state?.schoolFilterValue}
              organizationValues={state?.organizationValues}
              durationValues={state?.durationValues}
              groupTypeFilterValue={state?.groupTypeFilterValue}
              DifficultyFilterValue={state?.DifficultyFilterValue}
              TrainingAidsFilterValue={state?.TrainingAidsFilterValue}
              TrainingAreaFilterValue={state?.TrainingAreaFilterValue}
              searchCoursesOffline={this.searchCoursesOffline}
              allCoursesApi={this.getallCourses}
            />
          </Grid>
          <Grid container direction="row">
            <div className="container" role="training">
              <div className="wrapper-training w-50">
                <div className="div-main-filter"></div>
                <div className="div-organization">
                  {state?.organizationValues.length > 0 &&
                    state?.organizationValues
                      .filter((item: any) => item.name !== "All")
                      .map((item: any, index: number) => {
                        return (
                          <Button
                            className={"org-button"}
                            variant="contained"
                            id="orgcancelBtn"
                            key={index}
                          >
                            {item.image_url && (
                              <img src={item.image_url} alt="" />
                            )}
                            {item.name} &nbsp;&nbsp;&nbsp;&nbsp;
                            <CancelIcon
                              style={{ width: "2.2vw", height: "2.2vw" }}
                              onClick={() =>
                                this.handleFilterChangeHome(
                                  "Organizations",
                                  item.name
                                )
                              }
                            />
                          </Button>
                        );
                      })}
                  {state?.durationValues.length > 0 &&
                    state?.durationValues.map((name: string, index: number) => {
                      return (
                        <Button
                          className={"org-button"}
                          variant="contained"
                          key={index}
                        >
                          {name} &nbsp;&nbsp;&nbsp;&nbsp;
                          <CancelIcon
                            style={{ width: "2.2vw", height: "2.2vw" }}
                            id="durationcancelBtn"
                            onClick={() =>
                              this.handleFilterChangeHome("Durations", name)
                            }
                          />
                        </Button>
                      );
                    })}
                  {state?.sortBy && state?.sortBy !== "(A-Z)" && (
                    <Button className={"org-button"} variant="contained">
                      {state?.sortBy} &nbsp;&nbsp;&nbsp;&nbsp;
                      <CancelIcon
                        style={{ width: "2.2vw", height: "2.2vw" }}
                        id="sortbycancelBtn"
                        onClick={() => {
                          const AllFilters = [
                            state?.sortBy,
                            ...state?.TrainingAidsFilterValue,
                            ...state?.TrainingAreaFilterValue,
                            ...state?.DifficultyFilterValue,
                            ...state?.groupTypeFilterValue,
                            ...state?.schoolFilterValue,
                            ...state?.organizationValues,
                            ...state?.durationValues,
                          ];
                          if (AllFilters.length <= 2) {
                            this.getallCourses();
                          }
                          // this.setSortBy("");
                          this.props.setFilterValue({
                            name: "sortBy",
                            value: "(A-Z)",
                          });
                        }}
                      />
                    </Button>
                  )}
                  {state?.groupTypeFilterValue.length > 0 &&
                    state?.groupTypeFilterValue.map(
                      (name: string, index: number) => {
                        return (
                          <Button
                            className={"org-button"}
                            variant="contained"
                            key={index}
                          >
                            {name} &nbsp;&nbsp;&nbsp;&nbsp;
                            <CancelIcon
                              style={{ width: "2.2vw", height: "2.2vw" }}
                              id='GroupTypeCancelBtn'
                              onClick={() =>
                                this.handleFilterChangeHome("Group Type", name)
                              }
                            />
                          </Button>
                        );
                      }
                    )}
                  {state?.DifficultyFilterValue.length > 0 &&
                    state?.DifficultyFilterValue.map(
                      (name: string, index: number) => {
                        return (
                          <Button
                            className={"org-button"}
                            variant="contained"
                            key={index}
                          >
                            {name} &nbsp;&nbsp;&nbsp;&nbsp;
                            <CancelIcon
                              style={{ width: "2.2vw", height: "2.2vw" }}
                              id='DifficultyCancelBtn'
                              onClick={() =>
                                this.handleFilterChangeHome("Difficulty", name)
                              }
                            />
                          </Button>
                        );
                      }
                    )}
                  {state?.TrainingAidsFilterValue.length > 0 &&
                    state?.TrainingAidsFilterValue.map(
                      (name: string, index: number) => {
                        return (
                          <Button
                            className={"org-button"}
                            variant="contained"
                            key={index}
                          >
                            {name} &nbsp;&nbsp;&nbsp;&nbsp;
                            <CancelIcon
                              style={{ width: "2.2vw", height: "2.2vw" }}
                              id="TrainingAidscancelBtn"
                              onClick={() =>
                                this.handleFilterChangeHome(
                                  "Training Aids",
                                  name
                                )
                              }
                            />
                          </Button>
                        );
                      }
                    )}
                  {state?.TrainingAreaFilterValue.length > 0 &&
                    state?.TrainingAreaFilterValue.map(
                      (name: string, index: number) => {
                        return (
                          <Button
                            className={"org-button"}
                            variant="contained"
                            key={index}
                          >
                            {name} &nbsp;&nbsp;&nbsp;&nbsp;
                            <CancelIcon
                              style={{ width: "2.2vw", height: "2.2vw" }}
                              id="trainingfilterCancelBtn"
                              onClick={() =>
                                this.handleFilterChangeHome(
                                  "Training Area",
                                  name
                                )
                              }
                            />
                          </Button>
                        );
                      }
                    )}
                  {state?.schoolFilterValue.length > 0 &&
                    state?.schoolFilterValue.map(
                      (name: string, index: number) => {
                        return (
                          <Button
                            className={"org-button"}
                            variant="contained"
                            key={index}
                          >
                            {name} &nbsp;&nbsp;&nbsp;&nbsp;
                            <CancelIcon
                              style={{ width: "2.2vw", height: "2.2vw" }}
                              id="schoolscancelBtn"
                              onClick={() =>
                                this.handleFilterChangeHome("Schools", name)
                              }
                            />
                          </Button>
                        );
                      }
                    )}
                </div>
              </div>
            </div>
          </Grid>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ paddingLeft: "2.9296875vw" }}
              data-testid="parent"
            >
              <h1 className="text-title" data-testid="child">
                Courses
              </h1>
            </Grid>
            {this.props?.appState?.appReducer?.isLoading ? (
              <div
                className="loader-class-course"
                style={{
                  minHeight: "70vh",
                  margin: "auto",
                  paddingTop: "210px",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                {this.state.responseCourses?.length === 0 ? (
                  !this.props?.appState?.appReducer?.isLoading &&
                    this.state.isOffline &&
                    !this.state.afterLoadCourse ? (
                    <Grid
                      item
                      xs={12}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      container
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "60vh",
                        }}
                      >
                        <CircularProgress className="loader-class-ext" />
                      </div>
                    </Grid>
                  ) : this.state.isOffline && this.state.afterLoadCourse ? (
                    <Grid
                      item
                      xs={12}
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      container
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "60vh",
                        }}
                      >
                        <img src={noData} className="nodata-img" />
                      </div>
                    </Grid>
                  ) : (
                    this.state.responseCourses?.length == 0 && (
                      <Grid
                        item
                        xs={12}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        container
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            minHeight: "60vh",
                          }}
                        >
                          <h4 style={{ paddingLeft: "6px" }}>
                            No Courses Available.
                          </h4>
                        </div>
                      </Grid>
                    )
                  )
                ) : (
                  <>
                    <Grid
                      item
                      xs={11}
                      style={{ paddingLeft: "2.2786458333333335vw" }}
                      direction="column"
                      justifyContent="center"
                    >
                      <InfiniteScroll
                        dataLength={this.state.responseCourses?.length}
                        next={() => {
                          this.setState({
                            pageNumber: this.state.pageNumber + 1,
                          });
                          const isFilterSelected =
                            (state?.sortBy !== "(A-Z)" &&
                              state?.sortBy !== "") ||
                            state?.schoolFilterValue.length > 0 ||
                            state?.groupTypeFilterValue.length > 0 ||
                            state?.DifficultyFilterValue.length > 0 ||
                            state?.TrainingAidsFilterValue.length > 0 ||
                            state?.TrainingAreaFilterValue.length > 0 ||
                            state?.durationValues.length > 0 ||
                            state?.organizationValues.length > 0;
                          if (
                            isFilterSelected &&
                            this.state.searchStringValue === ""
                          ) {
                            const schoolIds: string[] = [];
                            const orgIds: string[] = [];
                            for (let school of state?.schoolFilterValue) {
                              let tempSchool:
                                | { name: string; id: string }
                                | undefined = this.state.schools.find(
                                  (item: { name: string; id: string }) =>
                                    item.name === school
                                );
                              tempSchool !== undefined &&
                                //@ts-ignore
                                schoolIds.push(tempSchool.id);
                            }
                            for (let org of state?.organizationValues) {
                              let tempOrg:
                                | { name: string; id: string }
                                | undefined = this.state.responseOrganization.find(
                                  (item: { name: string; id: string }) =>
                                    item.name === org.name
                                );
                              //@ts-ignore
                              tempOrg !== undefined && orgIds.push(tempOrg.id);
                            }
                            //@ts-ignore
                            this.getFilterDataFromSidebar(
                              state?.sortBy,
                              schoolIds,
                              state?.groupTypeFilterValue,
                              state?.DifficultyFilterValue,
                              state?.TrainingAidsFilterValue,
                              state?.TrainingAreaFilterValue,
                              state?.durationValues,
                              //@ts-ignore
                              orgIds,
                              this.state.pageNumber
                            );

                            return;
                          }

                          if (this.state.searchStringValue !== "") {
                            this.getSearchedCourses(
                              this.state.searchStringValue,
                              this.state.pageNumber
                            );
                            return;
                          }
                          if (
                            this.state.searchStringValue === "" &&
                            !isFilterSelected
                          ) {
                            this.getallCourses(this.state.pageNumber);
                          }
                        }}
                        hasMore={
                          this.state.isOffline &&
                            this.state.pageNumber < this.state.totalPages
                            ? true
                            : false
                        }
                        loader={
                          <div className="loader-class-course">
                            <CircularProgress />
                          </div>
                        }
                        endMessage={
                          this.state.responseCourses.length > 0 ? (
                            <p style={{ textAlign: "center", padding: "30px" }}>
                              <b id="allSeen">You have seen all courses</b>
                            </p>
                          ) : (
                            <></>
                          )
                        }
                      // scrollableTarget="scrollableDiv"
                      >
                        <Grid container direction="column">
                        <CourseListAds responseCourses={this.state.responseCourses} adsAllData={this.state.adsDataCourseAll} getId={getId} propCo={this.props} isOfflineV={this.state.isOffline} downloadingDataV={this.state.downloadingData} makeDownloadCOuntV={this.makeDownloadCOunt}/>
                        </Grid>
                      </InfiniteScroll>
                    </Grid>
                    <Grid item xs={1}>
                      {this.state.responseCourses?.length !== 0 && (
                        <div
                          className="abcd-list"
                          style={{
                            position: "sticky",
                            top: "60px",
                            right: "65px",
                          }}
                        >
                          {this.state.ATOZ.map((item) => (
                            <h4
                              role="alphaBatic"
                              id='a-z_lists'
                              onClick={() => {
                                if (document.getElementById(item)) {
                                  var myElement = document.getElementById(item);
                                  //@ts-ignore
                                  var topPos = myElement.offsetTop;
                                  document.getElementsByName(
                                    "infinite-scroll-component "
                                    //@ts-ignore
                                  ).scrollTop = topPos;
                                  //@ts-ignore
                                  document
                                    .getElementById(item)
                                    .scrollIntoView();
                                }
                              }}
                              style={{
                                cursor:
                                  alphaBetsList?.length !== 0 &&
                                    !alphaBetsList?.includes(item)
                                    ? "no-drop"
                                    : "",
                                color:
                                  alphaBetsList?.length !== 0 &&
                                    !alphaBetsList?.includes(item)
                                    ? "gray"
                                    : "",
                              }}
                            >
                              {item}
                            </h4>
                          ))}
                        </div>
                      )}
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>

          <Grid item={true} xs={12} id="footer">
            <Footer />
          </Grid>
        </div>
        <DrawerComponent
          showSidebar={this.state.showSidebar}
          handleSidebar={this.handleSidebar}
          getallCourses={this.getallCourses}
          getFilterDataFromSidebar={this.getFilterDataFromSidebar}
          getSchoolsByid={this.getSchoolsByid}
          filterCoursesOffline={this.filterCoursesOffline}
        />
      </>
    );
  }
}
export const mapDispatchToProps = (dispatch: any) => {
  return {
    addOrgContent: (orgList: any) => {
      dispatch(GET_ORGANIZATION(orgList));
    },
    saveCoursesToRedux: (courses: any) =>
      dispatch(getAllCoursesAction(courses)),
    setFilterValue: (data: { name: string; value: any[] }) =>
      dispatch(setFilterValueByName(data)),
    setFilterField: (data: { name: string; value: any[] }) =>
      dispatch(setFilterFieldByName(data)),
    setLoading: (data: any) => {
      dispatch(setLoaderOnScreen(data));
    },
  };
};
export const mapStateToProps = (state: any) => {
  return {
    orgContent: state?.HipPocketFireActions?.organizationDataList[0],
    isOnline: state?.HipPocketFireActions?.isOnline,
    appState: state,
  };
};

const TrainingWeb = withRouter(
  //@ts-ignore
  connect(mapStateToProps, mapDispatchToProps)(TrainingS1Web)
);
//@ts-ignore
export default withStyles(null)(TrainingWeb);
// Customizable Area End
