Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.getCourseDataAPiEndPoint = "bx_block_courses/courses";
exports.getCourseDataAPiMethod = "GET";
exports.getCourseDataApiContentType = "application/json";
exports.getRelatedCourseDataAPiEndPoint = "bx_block_courses/courses";
exports.getRelatedCourseDataAPiMethod = "GET";
exports.getRelatedCourseDataApiContentType = "application/json";
exports.getRelatedProductsDataAPiEndPoint = "bx_block_courses/get_affiliateurls";
exports.getRelatedProductsDataAPiMethod = "GET";
exports.getRelatedProductsDataApiContentType = "application/json";
exports.postAssesmentFormAPiEndPoint = "bx_block_courses/answers";
exports.postAssesmentFormAPiMethod = "POST";
exports.postAssesmentFormApiContentType = "application/json";
exports.formSuccess = "Form submitted Successfully.";
exports.postFillableFormAPiEndPoint = "bx_block_custom_form";
exports.postFillableFormAPiMethod = "POST";
exports.postFillableFormApiContentType = "application/json";
exports.affiliatesCountApiEndPoint = "bx_block_affiliateurl/affiliateproducts/affiliates_count";
exports.affiliatesCountApiMethod = "PUT";
exports.affiliatesCountApiContentType = "application/json";
// Customizable Area End
