// Customizable Area Start
import React from "react";
import { Typography, IconButton, Link, Grid } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
// @ts-ignore
import AppLogo from "./AppFooterLogo.svg";
import "./Footer.scss";
//@ts-ignore
import Cookies from "js-cookie";
import { Console } from "console";

const Footer: React.FC<RouteComponentProps> = ({ history }) => {
  // Customizable Area Start
  //@ts-ignore
  const [isOnline, setIsOnline] = React.useState<boolean>();
  React.useEffect(() => {
    if(!window.navigator.onLine){
      Cookies.set("isOnline",false)
      setIsOnline(false)
    }else{
      Cookies.set("isOnline",true)
      setIsOnline(true)
    }
    window.addEventListener("offline", ()=>{
      Cookies.set("isOnline",false)
      setIsOnline(false)
    });
    window.addEventListener('online', (event) => {
      Cookies.set("isOnline",true)
      setIsOnline(true)
  });
  });
  // Customizable Area End

  return (
    // Customizable Area Start
    <Grid container className="footer-grid-container-ext" wrap="wrap">
      <Grid item lg={6} className="footer-grid-item">
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => history.push("/")}
        >
          <img src={AppLogo} alt="logo" className="mvp-footer-logo-app" />
        </IconButton>
      </Grid>
      {isOnline && (
        <Grid item lg={3} className="footer-grid-item">
          <Typography
            // style={webStyle.Link}
            className="footer-content-nav"
            // @ts-ignore
            onClick={() => history.push("/contact")}
          >
            Contact Us
          </Typography>
          <Typography
            // style={webStyle.Link}
            className="footer-content-nav"
            // @ts-ignore
            onClick={() => history.push("/terms")}
          >
            Terms & Conditions
          </Typography>
          <Typography
            // style={webStyle.Link}
            className="footer-content-nav"
            // @ts-ignore
            onClick={() => history.push("/privacy")}
          >
            Privacy Policy
          </Typography>
        </Grid>
      )}
      {isOnline && (
        <Grid item lg={3} className="footer-grid-item">
          <Typography
            // style={webStyle.Link}
            className="footer-content-nav"
            // @ts-ignore
            onClick={() => history.push("/about")}
          >
            About Us
          </Typography>
          <Link
            underline="none"
            // style={webStyle.Link}
            className="footer-content-nav"
            //@ts-ignore
            color="white"
            // @ts-ignore
            onClick={() =>
              history.push({
                pathname: '/',
                state: { detail: 'howItworksClicked' }
              })
            }
            // href="#HowItWorks"
          >
            How it works
          </Link>
        </Grid>
      )}
    </Grid>
    // Customizable Area End
  );
};

const webStyle = {
  Footer: {
    backgroundColor: "#0C120C",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  Link: { alignSelf: "flex-start", cursor: "pointer", color: "white" },
  ToolBarOne: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "325px",
  },
  ToolBarTwo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginRight: "170px",
    paddingBottom: "25px",
  },
} as any;

//@ts-ignore
const footerWithRouter = withRouter(Footer);
export default withStyles(webStyle)(footerWithRouter);
// Customizable Area End
