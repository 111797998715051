import * as React from 'react'
//@ts-ignore
import { Grid } from "@material-ui/core";
import { noData } from '../../blocks/filteritems/src/assets';

const NoDataFoundFallback: React.FC = () => {
    return (
        <React.Fragment>
            <Grid
            item
            xs={12}
            direction="column"
            justifyContent="center"
            alignItems="center"
            container
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "60vh",
              }}
            >
              <img src={noData} style={{ width: '40.552083vw' }} />
            </div>
          </Grid>
        </React.Fragment>)
}
export default NoDataFoundFallback;