// Customizable Area Start
import React from "react";

import "../assets/PrivacyPolicyWeb.scss";

export const configJSON = require("./config");
import PrivacyPolicyControllerWeb, {
  Props,
} from "./PrivacyPolicyControllerWeb.web";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer.web";
import CircularProgress from "@material-ui/core/CircularProgress";

export default class PrivacyPolicyWeb extends PrivacyPolicyControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <Header />
        <div className="container-privacy">
          {this.state.loadingContent ? (
            this.state.loadingContentNot ? (
              <div className="wrapper-policy  w-50">
                <div className="loader-class">
                  <h3>No data available</h3>
                </div>
              </div>
            ) : (
              <div className="wrapper-policy  w-50">
                <div className="loader-class">
                  <CircularProgress />
                </div>
              </div>
            )
          ) : (
            <div
              className="wrapper-policy w-50"
              dangerouslySetInnerHTML={{ __html: this.state.responsePrivacy }}
            />
          )}
        </div>
        <Footer />
      </>
    );
  }
}
// Customizable Area End
