import React from "react";

// Customizable Area Start

import { createTheme } from "@material-ui/core/styles";

import Header from "../../../components/src/HeaderApp.web";
import Footer from "../../../components/src/Footer.web";
import "../assets/comingsoon.scss";
// Customizable Area End

import ComingSoonController, { Props } from "./ComingSoonController";

export default class ComingSoonWeb extends ComingSoonController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
        <Header />
        <div className="commingsoon-container">
            <div dangerouslySetInnerHTML={{ __html: this.state?.comingsoon }} className="w-100">
        </div>
        </div>
        <Footer />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({});

const webStyle = {};
// Customizable Area End
