import React from 'react'
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import "./StoreProductModal.scss"
interface Props {
    // Customizable Area Start
    open: boolean;
    handleClose: (isApiCallRequired: Boolean) => void;
    // numberElem: number;
    url: string;
    // Customizable Area End
}
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const StoreProductModal: React.FC<Props> = ({ open, handleClose, url }) => {

    return (
        <div>
            <Modal
                open={open}
                onClose={()=> handleClose(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='product-modal'>
                    <Typography id="modal-modal-title product-modal-test" variant="h6" component="h2">
                        You are being redirected to securely complete your purchase
                    </Typography>
                    <div className="button-both-together-modal">
                        <Button onClick={()=> handleClose(true)} href={url} target="_blank" rel="noreferrer" variant="contained" className={"active-button-modal"}>
                            Leave this website
                        </Button> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button variant="contained" onClick={()=> handleClose(false)} className={"nonActive-button-modal"}>
                            Stay on this website
                        </Button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
export default StoreProductModal