const initialState = {
  settingDataList: [],
  coursesDataList: [],
  organizationDataList: [],
  isOnline: true,
  isResetfilter:true
};
export default (state = initialState, action) => {
  switch (action.type) {
    case 'GET_SETTINGLIST':
      return {
        ...state,
        settingDataList: [action.payload]
      };
    case 'GET_COURSES':
      return {
        ...state,
        coursesDataList: [...state.coursesDataList, action.payload]
      };
    case 'GET_ORGANIZATION':
      return {
        ...state,
        organizationDataList: [action.payload]
      };
    case 'SET_IS_ONLINE':
      return {
        ...state,
        isOnline: action.payload
      };
      case 'RESET_FILTERS_ALL':
        return {
          ...state,
          isResetfilter: action.payload
        };  
    case 'RECTIFICATION'  :
      state.coursesDataList[0].courseData.forEach((courseContent,index) => {
      if(courseContent.id==action.payload.value){
        state.coursesDataList[0].courseData[index]["offline_downloaded"]=action.payload.data
        return state
      }
     });
    default:
      return state;
  }
};
