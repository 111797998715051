import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
//@ts-ignore
import Cookies from "js-cookie";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history:any;
  isOnline: boolean;
  setLoading:any;
  appState:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  timeout: any;
  isOffline:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HomeController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      timeout: 2000,
      isOffline:''
    }
    
    // Customizable Area Startg
    this.subScribedMessages = [];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    
    if(this.props.history?.location?.state?.detail==='howItworksClicked'){
      this.props.history.replace({ state: undefined });
      setTimeout(() => {
        window.scroll(0,this.findPosition(document.getElementById("HowItWorks"))as any)
      }, 100);
    }
    if(!window.navigator.onLine){
      Cookies.set("isOnline",false)
      this.setState({isOffline:false})
    }else{
      Cookies.set("isOnline",true)
      this.setState({isOffline:true})
    }
   
  }
  findPosition(obj:any) {
    var currenttop = 0;
    if (obj.offsetParent) {
        do {
            currenttop += obj.offsetTop;
        } while ((obj = obj.offsetParent));
        return [currenttop];
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
  
    if(this.props.history?.location?.state?.detail==='howItworksClicked'){
      this.props.history.replace({ state: undefined });
      setTimeout(() => {
        window.scroll(0,this.findPosition(document.getElementById("HowItWorks"))as any)
      }, 100);
    }
    
    // debugger
    if (prevState.isOffline !== this.state.isOffline) {
      if(!window.navigator.onLine){
        Cookies.set("isOnline",false)
      
      this.setState({isOffline:false})
      }else{
          Cookies.set("isOnline",true)
          this.setState({isOffline:true})
      }
      window.addEventListener("offline", ()=>{
        Cookies.set("isOnline",false)
        this.setState({isOffline:false})
      });
      window.addEventListener('online', (event) => {
        
          Cookies.set("isOnline",true)
        this.setState({isOffline:true})
    });
    }
  }

  goToHome() {
    if (this.state.timeout > 0) {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationHomeScreenMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
    }
  }
  // Customizable Area End
}
