import React from "react";
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import {
  // Customizable Area Start
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Tab,
  Tabs,
  // Customizable Area End
} from "@material-ui/core";
import "./CourseDetailHeader.scss";
// @ts-ignore
import courseLogo from "./course.svg";
// @ts-ignore
import courseBlackLogo from "./courseBlack.svg";

// @ts-ignore
import suppliesLogo from "./supplies.svg";
// @ts-ignore
import suppliesBlackLogo from "./suppliesBlack.svg";

// @ts-ignore
import relateCourseLogo from "./relatedCourse.svg";
// @ts-ignore
import relateCourseBlackLogo from "./relatedCourseBlack.svg";

// @ts-ignore
import referenceLogo from "./reference.svg";
// @ts-ignore
import referenceBlackLogo from "./referenceBlack.svg";

// @ts-ignore
import tipsLogo from "./tips.svg";
// @ts-ignore
import tipsBlackLogo from "./tipsBlack.svg";

// @ts-ignore
import Assesment from "./Assesment.svg";
// @ts-ignore
import AssesmentBlackLogo from "./AssesmentBlack.svg";

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles({
  // indicator: {
  //   display: "flex",
  //   justifyContent: "center",
  //   backgroundColor: "white",
  //   "& > span": {
  //     backgroundColor: "#00000"
  //   }
  // }
})((props: StyledTabsProps) => {
  return (
    <Tabs
      {...props}
    // TabIndicatorProps={{
    //   children: <span style={{}} />
    // }}
    />
  );
});

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
      color: "white",
      minWidth: "120px",
      marginRight: theme.spacing(1),
      "&:focus": {},
    },
    selected: {
      backgroundColor: "white",
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      color: "black",
    },
    "@media (max-width: 1167px)": {
      selected: {
        backgroundColor: "white",
        color: "black",
        borderRadius: 15,
      },
    },
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  demo1: {
    backgroundColor: "#0C120C",
  },
  demo2: {
    backgroundColor: "#0C120C",
  },
  container: {
    display: "flex",
    justifyContent: "space-around",
  },
  icon: {
    height: "15px",
    width: "15px",
  },
  iconButton: {
    marginRight: 5,
    height: "20px",
    width: "20px",
    maxWidth: "100%",
  },
  title: {
    paddingLeft: "25px",
    paddingRight: "25px",
  },
}));

export default function CourseDetailHeader(Props: any) {
  const { setValue, value, relatedCourses, relatedProducts, reference, proTips, setTabName, questions, tabName, courseTitle } = Props;
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className="course-detail-header-container" data-testid='div-course-content' role="pro-tips">
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="styled tabs example"
        //@ts-ignore
        className={classes.container}
        data-testid='Course Assesment Form'
      >
        <StyledTab
          onClick={(event: any) => { setTabName('Course') }}
          //@ts-ignore
          label={
            <Typography className="header-title-style">
              <IconButton
                edge="start"
                className={classes.iconButton}
                aria-label="menu"
              >
                <img
                  src={tabName === "Course" ? courseBlackLogo : courseLogo}
                  alt="logo"
                  className={classes.icon}
                />
              </IconButton>
              {courseTitle?.length > 45 ? `${courseTitle.substring(0, 45)}...` : courseTitle}
            </Typography>
          }
        />
        {/* <StyledTab
          //@ts-ignore
          label={
            <Typography className="header-title-style">
              <IconButton
                edge="start"
                className={classes.iconButton}
                aria-label="menu"
              >
                <img
                  src={value === 1 ? suppliesBlackLogo : suppliesLogo}
                  alt="logo"
                  className={classes.icon}
                />
              </IconButton>
              Training Supplies
            </Typography>
          }
        /> */}
        {relatedCourses?.length !== 0 &&
          <StyledTab
            onClick={(event: any) => { setTabName('ReletedCourse') }}
            //@ts-ignore
            label={
              <Typography className="header-title-style" >
                <IconButton
                  edge="start"
                  className={classes.iconButton}
                  aria-label="menu"
                  data-testid="div-course-content-tab"
                >
                  <img
                    src={tabName === "ReletedCourse" ? relateCourseBlackLogo : relateCourseLogo}
                    alt="logo"
                    className={classes.icon}
                  />
                </IconButton>
                Related Courses
              </Typography>
            }
          />}
        {relatedProducts?.length !== 0 &&
          <StyledTab
            onClick={(event: any) => { setTabName('ReletedProducts') }}
            //@ts-ignore
            label={
              <Typography className="header-title-style" >
                <IconButton
                  edge="start"
                  className={classes.iconButton}
                  aria-label="menu"
                  data-testid="div-course-content-tab"
                >
                  <img
                    src={tabName === "ReletedProducts" ? suppliesBlackLogo : suppliesLogo}
                    alt="logo"
                    className={classes.icon}
                  />
                </IconButton>
                Training Supplies
              </Typography>
            }
          />}
        {reference &&
          <StyledTab
            onClick={(event: any) => { setTabName('References') }}
            //@ts-ignore
            label={
              <Typography className="header-title-style">
                <IconButton
                  edge="start"
                  className={classes.iconButton}
                  aria-label="menu"
                >
                  <img
                    src={tabName === "References" ? referenceBlackLogo : referenceLogo}
                    alt="logo"
                    className={classes.icon}
                  />
                </IconButton>
                References
              </Typography>
            }
          />
        }
        {proTips &&
          <StyledTab
            onClick={(event: any) => { setTabName('ProTips') }}
            //@ts-ignore
            label={
              <Typography className="header-title-style">
                <IconButton
                  edge="start"
                  className={classes.iconButton}
                  aria-label="menu"
                >
                  <img
                    src={tabName === "ProTips" ? tipsBlackLogo : tipsLogo}
                    alt="logo"
                    className={classes.icon}
                  />
                </IconButton>
                Pro Tips
              </Typography>
            }
          />}
        {questions?.length !== 0 &&
          <StyledTab
            onClick={(event: any) => { setTabName('AssesmentForm') }}

            //@ts-ignore
            label={
              <Typography className="header-title-style">
                <IconButton
                  edge="start"
                  className={classes.iconButton}
                  aria-label="menu"

                >
                  <img
                    src={tabName === "AssesmentForm" ? AssesmentBlackLogo : Assesment}
                    alt="logo"
                    className={classes.icon}
                  />
                </IconButton>
                Course Assesment Form
              </Typography>
            }
          />}
      </StyledTabs>
    </div>
  );
}
