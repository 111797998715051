export const schoolFields = [
    {
      name: "Airborne school",
      isChecked: false,
    },
    {
      name: "Officer Candidate",
      isChecked: false,
    },
    {
      name: "Reserve Officer Training Corps",
      isChecked: false,
    },
    {
      name: "Recruiting",
      isChecked: false,
    },
    {
      name: "Basic Training (Boot Camp)",
      isChecked: false,
    },
    {
      name: "Police Academy",
      isChecked: false,
    },
    {
      name: "Infintery",
      isChecked: false,
    },
    {
      name: "Field Artillery",
      isChecked: false,
    },
    {
      name: "Administrative",
      isChecked: false,
    },
    {
      name: "Combat Medic",
      isChecked: false,
    },
    {
      name: "Weapons",
      isChecked: false,
    },
    {
      name: "Air Assault",
      isChecked: false,
    },
    {
      name: "Sapper",
      isChecked: false,
    },
    {
      name: "Combact Engineer",
      isChecked: false,
    }
  ];

  export const GROUP_FILTER_FIELDS = [
    {
      name: "Individual",
      isChecked: false,
    },
    {
      name: "Team/Small (2-5)",
      isChecked: false,
    },
    {
      name: "Squad/Medium (6-10)",
      isChecked: false,
    },
    {
      name: "Platoon/Large (11-30)",
      isChecked: false,
    },
  ]

  export const DIFFICULTY_FILTER_FIELDS = [
    {
      name: "Basic",
      isChecked: false,
    },
    {
      name: "Intermediate",
      isChecked: false,
    },
    {
      name: "Advanced",
      isChecked: false,
    },
    {
      name: "Pro",
      isChecked: false,
    }
  ];

  export const TRAINING_AIDS_FILTER_FIELDS = [
    {
      name: "No materials required",
      isChecked: false,
    },
    {
      name: "Materials required",
      isChecked: false,
    },
  ];

  export const TRAINING_AREA_FILTER_FIELDS = [
    {
      name: "Small Space",
      isChecked: false,
    },
    {
      name: "Medium Space",
      isChecked: false,
    },
    {
      name: "Large Space",
      isChecked: false,
    }    
  ];
  export const DURATION_FILTER_FIELDS = [
    {
      name: "0-10 mins",
      isChecked: false,
    },
    {
      name: "11-15 mins",
      isChecked: false,
    },
    {
      name: "16-30 mins",
      isChecked: false,
    },   
    {
      name: "31-45 mins",
      isChecked: false,
    },   
    {
      name: "46-60 mins",
      isChecked: false,
    },   
  ];