import React from "react";
import {
  Drawer,
  Divider,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
} from "@material-ui/core";
//@ts-ignore
import Cookies from "js-cookie";
import './Drawer.scss';
import CheckBoxGroupComponent from "./CheckBoxGroup.web";
import {
  GROUP_FILTER_FIELDS,
  DIFFICULTY_FILTER_FIELDS,
  TRAINING_AIDS_FILTER_FIELDS,
  TRAINING_AREA_FILTER_FIELDS,
  DURATION_FILTER_FIELDS,
  //@ts-ignore
} from './DrawerConstant.js'
import { useDispatch, useSelector } from "react-redux";
//@ts-ignore
import { setFilterFieldByName, setFilterValueByName, resetFilterReduxState } from "../../web/src/action.js"

interface myProps {
  showSidebar: boolean;
  handleSidebar: any;
  getallCourses: () => any;
  getFilterDataFromSidebar:any;
  filterCoursesOffline: (courses: any[], descriptionArray: string[]) => void;
  getSchoolsByid: (ids?: number|string[]) => void;
}

const DrawerComponent = (props: myProps) => {

  const [isOnline, setIsOnline] = React.useState<boolean>();
  const dispatch = useDispatch();
  // @ts-ignore
  const state = useSelector((state) => state.appReducer);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const AllFilters = [...state.TrainingAidsFilterValue, ...state.TrainingAreaFilterValue, ...state.DifficultyFilterValue, ...state.groupTypeFilterValue, ...state.schoolFilterValue, ...state.organizationValues, ...state.durationValues];
    if(event.target.value === "(A-Z)" && AllFilters.length === 0){
      props.getallCourses();
    }
    dispatch(setFilterValueByName({name: "sortBy", value: event.target.value}))
  };

  const handleChangeCheckBoxGroup = (
    event: React.ChangeEvent<HTMLInputElement>, operation: string, onlyRemove?:boolean
  ) => {
    const AllFilters = [...state.TrainingAidsFilterValue, ...state.TrainingAreaFilterValue, ...state.DifficultyFilterValue, ...state.groupTypeFilterValue, ...state.schoolFilterValue, ...state.organizationValues, ...state.durationValues];
    if (AllFilters.length === 1 && !event.target.checked) {
      props.getallCourses();
    }
    let data: {
      name: string,
      value: {
        name: string;
        isChecked: boolean;
    }[]
    } = {
      name: "",
      value: []
    }
    switch (operation) {
      case "Training Area":
        let TrainingAreaFilterFieldsTemp = state.TrainingAreaFilterFields.map((item: any) => item.name === event.target.name ? { ...item, isChecked: !item.isChecked}: item);
        data.name= "TrainingAreaFilterFields";
        data.value = TrainingAreaFilterFieldsTemp;
        dispatch(setFilterFieldByName(data));
          if(event.target.checked){
            const data = {
              name: "TrainingAreaFilterValue",
              value: [...state.TrainingAreaFilterValue, event.target.name]
            }
            dispatch(setFilterValueByName(data));
          }else{
            const tempTrainingAreaFilterValue = state.TrainingAreaFilterValue.filter((item: any)=> item !== event.target.name);
            const data = {
              name: "TrainingAreaFilterValue",
              value: tempTrainingAreaFilterValue
            }
            dispatch(setFilterValueByName(data));
          }
        return;

      case "Training Aids":
        let TrainingAidsFilterFieldsTemp = state.TrainingAidsFilterFields.map((item: any) => item.name === event.target.name ? { ...item, isChecked: !item.isChecked}: item);
        data.name= "TrainingAidsFilterFields";
        data.value = TrainingAidsFilterFieldsTemp;
        dispatch(setFilterFieldByName(data));
          if(event.target.checked){
            const data = {
              name: "TrainingAidsFilterValue",
              value: [...state.TrainingAidsFilterValue, event.target.name]
            }
            dispatch(setFilterValueByName(data));
          }else{
            const tempTrainingAidsFilterValue = state.TrainingAidsFilterValue.filter((item: any)=> item !== event.target.name);
            const data = {
              name: "TrainingAidsFilterValue",
              value: tempTrainingAidsFilterValue
            }
            dispatch(setFilterValueByName(data));
          }
        return;

      case "Difficulty":
        let DifficultyFilterFieldsTemp = state?.DifficultyFilterFields.map((item: any) => item.name === event.target.name ? { ...item, isChecked: !item.isChecked}: item);
        data.name= "DifficultyFilterFields";
        data.value = DifficultyFilterFieldsTemp;
        dispatch(setFilterFieldByName(data))
          if(event.target.checked){
            const data = {
              name: "DifficultyFilterValue",
              value: [...state?.DifficultyFilterValue, event.target.name]
            }
            dispatch(setFilterValueByName(data));
          }else{
            const tempDifficultyFilterValue = state?.DifficultyFilterValue.filter((item: any)=> item !== event.target.name);
            const data = {
              name: "DifficultyFilterValue",
              value: tempDifficultyFilterValue
            }
            dispatch(setFilterValueByName(data));
          }
        return;

      case "Group Type":
        let groupTypeFilterFieldsTemp = state?.groupTypeFilterFields.map((item: any) => item.name === event.target.name ? { ...item, isChecked: !item.isChecked}: item);
        data.name= "groupTypeFilterFields";
        data.value = groupTypeFilterFieldsTemp;
        dispatch(setFilterFieldByName(data));
          if(event.target.checked){
            const data = {
              name: "groupTypeFilterValue",
              value: [...state?.groupTypeFilterValue, event.target.name]
            }
            dispatch(setFilterValueByName(data));
          }else{
            const tempGroupTypeFilterValue = state?.groupTypeFilterValue.filter((item: any)=> item !== event.target.name);
            const data = {
              name: "groupTypeFilterValue",
              value: tempGroupTypeFilterValue
            }
            dispatch(setFilterValueByName(data));
          }
        return;

      case "Schools":
        let schoolFilterValueTemp = state.schoolFilterFields.map((item: any) => item.name === event.target.name ? { ...item, isChecked: !item.isChecked}: item);
        data.name= "schoolFilterFields";
        data.value = schoolFilterValueTemp;
        dispatch(setFilterFieldByName(data));
          if(event.target.checked){
            const data = {
              name: "schoolFilterValue",
              value: [...state.schoolFilterValue, event.target.name]
            }
            dispatch(setFilterValueByName(data));
          }else{
            const tempSchoolFilterValue = state.schoolFilterValue.filter((item: any)=> item !== event.target.name);
            const data = {
              name: "schoolFilterValue",
              value: tempSchoolFilterValue
            }
            dispatch(setFilterValueByName(data));
          }
        return;

      case "Organizations":
        if (event.target.name === "All") {
          if(event.target.checked){
            const AllFilters = [...state?.TrainingAidsFilterValue, ...state?.TrainingAreaFilterValue, ...state?.DifficultyFilterValue, ...state?.groupTypeFilterValue, ...state?.schoolFilterValue, ...state?.durationValues];
            if(AllFilters.length === 0 && state?.sortBy === "(A-Z)") {
              props.getallCourses();
            }
            let organizationFieldsTemp = state.organizationFields.map((item:any) => item.name === "All" ? { ...item, isChecked: true} : {...item, isChecked: false});
              dispatch(setFilterValueByName({
              name: "organizationFields",
              //@ts-ignore
              value: organizationFieldsTemp
            }));
            let filter: {name: string, image_url: string} | undefined = state.responseOrganization.find((item: {name: string, image_url: string}) => item.name === "All" )
            //@ts-ignore
            dispatch(setFilterValueByName({
              name: "organizationValues",
              //@ts-ignore
              value: []
            }));
          }else{
            dispatch(setFilterValueByName({
              name: "organizationValues",
              //@ts-ignore
              value: []
            }));
          }
          return;
        }
        if(state?.organizationValues.length === 1 && !event.target.checked){
          let organizationFieldsTemp = state.organizationFields.map((item: any) => (item.name === event.target.name) ? { ...item, isChecked: !item.isChecked}: (item.name === "All") ? { ...item, isChecked: true } :  item);
          dispatch(setFilterValueByName({
            name: "organizationFields",
            //@ts-ignore
            value: organizationFieldsTemp
          }));
        }else{
          let organizationFieldsTemp = state.organizationFields.map((item: any) => (item.name === event.target.name) ? { ...item, isChecked: !item.isChecked}: (item.name === "All") ? { ...item, isChecked: false } :  item);
          dispatch(setFilterValueByName({
            name: "organizationFields",
            //@ts-ignore
            value: organizationFieldsTemp
          }));
        }
          if(event.target.checked){
            let filter: {name: string, image_url: string} | undefined = state.responseOrganization.find((item: {name: string, image_url: string}) => item.name === event.target.name);
            let filteredOrgVal = state.organizationValues.filter((item: any) => item.name !== "All");
              if(filter){
                dispatch(setFilterValueByName({
                  name: "organizationValues",
                  //@ts-ignore
                  value: [...filteredOrgVal, { name: filter.name, image_url: filter.image_url}]
                }));
              }
          }else{
            const tempOrganizationValues = state.organizationValues.filter((item: any)=> item.name !== event.target.name);
            dispatch(setFilterValueByName({
              name: "organizationValues",
              //@ts-ignore
              value: tempOrganizationValues
            }));
          }
        return;

      case "Durations":
        let durationFieldsTemp = state.durationFields.map((item: any) => item.name === event.target.name ? { ...item, isChecked: !item.isChecked}: item);
        data.name= "durationFields";
        data.value = durationFieldsTemp;
        dispatch(setFilterFieldByName(data));
          if(event.target.checked){
            const data = {
              name: "durationValues",
              value: [...state?.durationValues, event.target.name]
            }
            dispatch(setFilterValueByName(data));
          }else{
            const tempDurationValues = state?.durationValues.filter((item: any)=> item !== event.target.name);
            const data = {
              name: "durationValues",
              value: tempDurationValues
            }
            dispatch(setFilterValueByName(data));
          }
        return;

      default:
        return;
    }
  };

  React.useEffect(()=>{
    window.addEventListener("offline", ()=>{
      Cookies.set("isOnline",false);
      setIsOnline(false);
    });
    window.addEventListener('online', (event) => {
      Cookies.set("isOnline",true);
      setIsOnline(true)
    });
  }, [])

  React.useEffect(()=>{
    const schoolIds: string[] = [];
    const orgIds: string[] = [];

    for(let school of state?.schoolFilterValue) {
      let tempSchool: {name: string, id: string } | undefined = state?.schools.find((item: {name: string, id: string }) => item.name === school);
      //@ts-ignore
      tempSchool !== undefined && schoolIds.push(tempSchool.id);
    }
    for(let org of state?.organizationValues) {
      let tempOrg: {name: string, id: string } | undefined = state.responseOrganization.find((item: {name: string, id: string }) => item.name === org.name);
      //@ts-ignore
      tempOrg !== undefined && orgIds.push(tempOrg.id);
    }
    if(!window.navigator.onLine){
      Cookies.set("isOnline",false);
      setIsOnline(false)
      const downloadedCourses = JSON.parse(localStorage.getItem("allCourses") || "[]");
      const training_area_filter = state?.TrainingAreaFilterValue.length > 0 ? downloadedCourses.filter((course: any) => state?.TrainingAreaFilterValue.includes(course.training_area)) :  downloadedCourses;
      const training_aids_filter = state?.TrainingAidsFilterValue.length > 0 ? training_area_filter.filter((course: any) => state?.TrainingAidsFilterValue.includes(course.training_aids)) : training_area_filter;
      const organization_id_filter = orgIds.length > 0 ? training_aids_filter.filter((course: any) =>  
      {
        let temp = false;
        orgIds.forEach((item) => {
          if(course.c_org_ids.includes(item)) {
            temp = true;
          }
        })
        return temp;
      }) : training_aids_filter;
      const group_type_filter = state?.groupTypeFilterValue.length > 0 ? organization_id_filter.filter((course: any) => state?.groupTypeFilterValue.includes(course.group_type)) : organization_id_filter;
      const duration_filter = state?.durationValues.length > 0 ? group_type_filter.filter((course: any) => state?.durationValues.includes(course.duration)) : group_type_filter;
      const difficulty_level_filter = state?.DifficultyFilterValue.length > 0 ? duration_filter.filter((course: any) => state?.DifficultyFilterValue.includes(course.difficulty_level)) : duration_filter;
      const school_id_filter = schoolIds.length > 0 ? difficulty_level_filter.filter((course: any) => {
        let ans = false;
        if(course.school_id){
          for(let x of course.school_id) {
            if(schoolIds.includes(x)){
              ans = true;
            }
          }
        }
        return ans;
        }
      ) : difficulty_level_filter;
      const description = school_id_filter.map((item:any) => item.title)
      let sortBy = description;
      // let sortBy = school_id_filter.sort((a: any, b: any) => a.title.localeCompare(b.title));
      if(state?.sortBy === "(A-Z)") {
        sortBy = school_id_filter.sort((a: any, b: any) => a.title.localeCompare(b.title));
      } else if (state?.sortBy === "By Download") {
        sortBy = school_id_filter.sort((a: any, b: any) => b.download_count - a.download_count);
      } else if (state?.sortBy === "Newly Added") {
        //@ts-ignore
        sortBy = school_id_filter.sort((a: any, b: any) => new Date(b.created_at) - new Date(a.created_at)); 
      }
      props.filterCoursesOffline(school_id_filter, sortBy);
    }else{
      Cookies.set("isOnline",true);
      setIsOnline(true)
       //@ts-ignore
      props?.getFilterDataFromSidebar(state?.sortBy,schoolIds,state?.groupTypeFilterValue,state?.DifficultyFilterValue,state?.TrainingAidsFilterValue,state?.TrainingAreaFilterValue, state?.durationValues, orgIds);
    }
  }, [state?.sortBy, state?.schoolFilterValue, state?.groupTypeFilterValue, state?.DifficultyFilterValue, state?.TrainingAidsFilterValue, state?.TrainingAreaFilterValue, state?.durationValues, state?.organizationValues])

  return (
    <Drawer
      //@ts-ignore
      openSecondary={true}
      open={props.showSidebar}
      width={520}
      anchor="right"
      className="drawer-container"
      onBackdropClick={() => props?.handleSidebar()}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100vh",
        }}
        className="drawer-right"
      >
        <Box>
          <Box
            className="drawer-container-header"
          >
            <Typography variant="h5" className="header-title-left">
              Apply Filter
            </Typography>
            <Typography
              onClick={() => {
                const formattedDataOfSchool = state.schools.map((item: {name: string}) => ({name: item?.name, isChecked: false}))
                const formattedDataOfOrganization = state.responseOrganization.map((item: {name: string}) => item.name !== "All" ? {name: item?.name, isChecked: false} : {name: item?.name, isChecked: true})
                dispatch(resetFilterReduxState({
                  schoolFilterFields: formattedDataOfSchool,
                  orgFilterFields: formattedDataOfOrganization,
                  durationFields: DURATION_FILTER_FIELDS,
                  groupTypeFilterFields: GROUP_FILTER_FIELDS,
                  DifficultyFilterFields: DIFFICULTY_FILTER_FIELDS,
                  TrainingAidsFilterFields: TRAINING_AIDS_FILTER_FIELDS,
                  TrainingAreaFilterFields: TRAINING_AREA_FILTER_FIELDS,
                }))
                props.getallCourses();
              }}
              variant="body2"
              className="header-title-right"
            >
              Reset All
            </Typography>
          </Box>
          <Box
          className="drawer-container-body">
            {
             <FormControl component="fieldset">
              <Box>
                <Typography
                  className="body-title-sortby"
                >
                  Sort By
                </Typography>
              </Box>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={state?.sortBy}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="(A-Z)"
                  control={<Radio />}
                  label={
                    <Typography
                      variant="body1"
                      className="body-font-16"
                      style={{
                        color: state?.sortBy === "(A-Z)" ? "#db5461" : "black",
                      }}
                    >
                      (A-Z)
                    </Typography>
                  }
                  style={{ marginTop: "-15px" }}
                />
                <FormControlLabel
                  value="By Download"
                  control={<Radio />}
                  label={
                    <Typography
                      variant="body1"
                      className="body-font-16"
                      style={{
                        color: state?.sortBy === "By Download" ? "#db5461" : "black",
                      }}
                    >
                      By Download
                    </Typography>
                  }
                  style={{ marginTop: "-15px" }}
                />
                <FormControlLabel
                  value="Newly Added"
                  control={<Radio />}
                  label={
                    <Typography
                      variant="body1"
                      className="body-font-16"
                      style={{
                        color: state?.sortBy === "Newly Added" ? "#db5461" : "black",
                      }}
                    >
                      Newly Added
                    </Typography>
                  }
                  style={{ marginTop: "-15px" }}
                />
              </RadioGroup>
            </FormControl>
            }
          </Box>
          <Divider className="drawer-container-devider" />
          <CheckBoxGroupComponent
            title="Organizations"
            fields={state?.organizationFields}
            handleChangeCheckBoxGroup={handleChangeCheckBoxGroup}
          />
          <Divider className="drawer-container-devider" />
          <CheckBoxGroupComponent
            title="Durations"
            fields={state?.durationFields}
            handleChangeCheckBoxGroup={handleChangeCheckBoxGroup}
          />
          <Divider className="drawer-container-devider" />
            <CheckBoxGroupComponent
              title="Schools"
              fields={state?.schoolFilterFields}
              handleChangeCheckBoxGroup={handleChangeCheckBoxGroup}
            />
            <Divider className="drawer-container-devider" />
          <CheckBoxGroupComponent
            title="Group Type"
            fields={state?.groupTypeFilterFields}
            handleChangeCheckBoxGroup={handleChangeCheckBoxGroup}
          />
          <Divider className="drawer-container-devider" />
          <CheckBoxGroupComponent
            title="Difficulty"
            fields={state?.DifficultyFilterFields}
            handleChangeCheckBoxGroup={handleChangeCheckBoxGroup}
          />
          <Divider className="drawer-container-devider" />
          <CheckBoxGroupComponent
            title="Training Aids"
            fields={state?.TrainingAidsFilterFields}
            handleChangeCheckBoxGroup={handleChangeCheckBoxGroup}
          />
          <Divider className="drawer-container-devider" />
          <CheckBoxGroupComponent
            title="Training Area"
            fields={state?.TrainingAreaFilterFields}
            handleChangeCheckBoxGroup={handleChangeCheckBoxGroup}
          />
          <Divider className="drawer-container-devider" />
        </Box>
        <Box
          className="drawer-container-footer"
        >
          <Button
            variant="contained"
            className="drawer-container-footer-btn"
            onClick={()=>{
              props.handleSidebar();
            }}
          >
            APPLY FILTER
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DrawerComponent;
