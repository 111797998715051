// Customizable Area Start
import React from "react";
import '../assets/AboutUsWeb.scss'

export const configJSON = require("./config");
import AboutUsWebController, { Props } from "./AboutUsWebController.web";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer.web";
import CircularProgress from '@material-ui/core/CircularProgress';

export default class AboutUsWeb extends AboutUsWebController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (<>
      <Header />
      <div className="container-about">
        {
          this.state.loadingContent ?
            (this.state.loadingContentNot ? <div className="wrapper-aboutUs w-50">
              <div className="loader-class"><h3>No data available</h3></div></div> :
              <div className="wrapper-aboutUs w-50">
                <div className="loader-class"><CircularProgress /></div></div>) :
            <div className="wrapper-aboutUs w-50" dangerouslySetInnerHTML={{ __html: this.state.responseAbout }}>
            </div>
        }
      </div>
      <Footer />
    </>
    );
  }
}
// Customizable Area End