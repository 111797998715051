// Customizable Area Start
import React from "react";
import "../assets/FillableFormsTabWeb.scss";
import "../assets/CourseDetails.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const configJSON = require("./config");
import FillableFormsTabWebControllerWeb, {
  Props,
} from "./FillableFormsTabWebControllerWeb.web";
import FillableForms from "../../../components/src/FillableForms.web";
import FormDialog from "../../../components/src/FormDialog.web";
import Header from "../../../components/src/HeaderApp.web";
import Footer from "../../../components/src/Footer.web";
//@ts-ignore
import {
  NotificationContainer,
  NotificationManager,//@ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { menuIcon, fillableFormMoveIcon } from "./assets";

export default class MemoArmyFormWeb extends FillableFormsTabWebControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "column", height: "auto" }} className="memo-army-form-main-container">
        <Header
          //@ts-ignore
          BackToMainPage={this.BackToMainPage}
        />
        <NotificationContainer />
        <div className="container-fillable">
          <div className="wrapper-fillable w-50">
            <div className="container-center-data">
              <div className="course-header-title-container-ext">
                <br />
                <h3 className="course-header-title">
                  Army Formatted Memo Form
                </h3>
                <br />
              </div>
              <FillableForms
                formName="Army Formatted Memo Form"
                doSubmitFillableForm={this.doSubmitFillableForm}
                BackToMainPage={this.BackToMainPage}
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
// Customizable Area End
