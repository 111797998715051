import React, { useRef } from "react";
import {
  // Customizable Area Start
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  TextField,
  // Customizable Area End
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete'
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
//@ts-ignore
import Cookies from "js-cookie";
import Menu from "@material-ui/icons/Menu";
//@ts-ignore
import { GET_COURSES } from "../../../packages/web/src/HipPocketAllActions.js";
import "./HeaderApp2.scss";
import "./HeaderApp.scss";
// @ts-ignore
import AppLogo from "./AppLogo.svg";
// @ts-ignore
import searchLogo from "./search.svg";
// @ts-ignore
import homeLogo from "./home.svg";
// @ts-ignore
import trainingLogo from "./training.svg";
// @ts-ignore
import shopLogo from "./shop.svg";
// @ts-ignore
import commingSoonLogo from "./coming_soon.svg";
// @ts-ignore
import filterLogo from "./filter.svg";
// @ts-ignore
import fillableLogo from "./fillable.png";
// @ts-ignore
import { resetFilterReduxState, setLoaderOnScreen } from "../../web/src/action.js";

import {
  GROUP_FILTER_FIELDS,
  DIFFICULTY_FILTER_FIELDS,
  TRAINING_AIDS_FILTER_FIELDS,
  TRAINING_AREA_FILTER_FIELDS,
  DURATION_FILTER_FIELDS,
  //@ts-ignore
} from '../../components/src/DrawerConstant.js'

import Tooltip from "@material-ui/core/Tooltip";
//@ts-ignore
import parse from 'autosuggest-highlight/parse';
//@ts-ignore
import match from 'autosuggest-highlight/match';
interface Props {
  // Customizable Area Start
  history?: any;
  // @ts-ignore
  handleSidebar?: any;
  setSearchValue?: any;
  searchValue: string;
  autoCompleteFields?: string[]
  getAutoCompleteFieldsData?: any
  cleareautoCompleteFields?: any
  sortBy?: string;
  schoolFilterValue?: string[];
  organizationValues?: string[];
  durationValues?: string[];
  groupTypeFilterValue?: string[];
  DifficultyFilterValue?: string[];
  TrainingAidsFilterValue?: string[];
  TrainingAreaFilterValue?: string[];
  searchCoursesOffline?: (value: string) => void;
  BackToMainPage?: any
  allCoursesApi?: (pageNumber?: number) => void;
  setIsSearchClose?: any
  // Customizable Area End
}

const TempHeader: React.FC<Props> = ({
  history,
  searchValue,
  // @ts-ignore
  handleSidebar,
  setSearchValue,
  autoCompleteFields,
  getAutoCompleteFieldsData,
  cleareautoCompleteFields,
  sortBy,
  schoolFilterValue,
  organizationValues,
  durationValues,
  groupTypeFilterValue,
  DifficultyFilterValue,
  TrainingAidsFilterValue,
  TrainingAreaFilterValue,
  searchCoursesOffline,
  BackToMainPage,
  allCoursesApi,
  setIsSearchClose
}) => {
  const [isHovering, setIsHovering] = React.useState("");
  const [isSearchBarCilcked, setIsSearchBarCilcked] = React.useState<boolean>(
    false
  );
  const [isOnline, setIsOnline] = React.useState<boolean>();
  const [autoCompleteOptions, setAutoCompleteOptions] = React.useState<string[]>([]);
  const [value, setValue] = React.useState('');
  const [value1, setValue1] = React.useState('');
  const [offlineSearchValue, setOfflineSearchValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [openShopSearch, setOpenShopSearch] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [valueShopSearch, setValueShopSearch] = React.useState('');
  const handleMouseEnter = (name: string) => {
    setIsHovering(name);
  };

  const handleMouseLeave = () => {
    setIsHovering("");
  };
  React.useEffect(() => {
    if (history.location.state) {
      setIsSearchBarCilcked(true);
      history.replace({ state: undefined });
    }
    const navbarMenu = document.getElementById("menu") as any;
    const burgerMenu = document.getElementById("burger") as any;
    const bgOverlay = document.querySelector(".overlay") as any;

    // Show Menu when Click the Burger
    // Hide Menu when Click the Overlay
    if (burgerMenu && navbarMenu && bgOverlay) {
      burgerMenu.addEventListener("click", () => {
        navbarMenu.classList.toggle("is-active");
        bgOverlay.classList.toggle("is-active");
      });
      bgOverlay.addEventListener("click", () => {
        navbarMenu.classList.toggle("is-active");
        bgOverlay.classList.toggle("is-active");
      });
    }

    // Hide Menu when Click the Links
    document.querySelectorAll(".menu-link").forEach((link) => {
      link.addEventListener("click", () => {
        navbarMenu.classList.remove("is-active");
        bgOverlay.classList.remove("is-active");
      });
    });
  }, [isSearchBarCilcked]);

  const dispatch = useDispatch();
  const state = useSelector((state: any) => state.appReducer);
  const addCoursesContent = (courseList: any) => {
    dispatch(GET_COURSES(courseList));
  };

  const ref = useRef();

  React.useEffect(() => {
    //@ts-ignore
    const searchInputBar = document.getElementById('searchingBar')

    if (searchInputBar) {
      //@ts-ignore
      searchInputBar.value = searchValue
    }
  }, [searchValue]);

  React.useEffect(() => {
    let optionData = [] as any

    //@ts-ignore
    autoCompleteFields?.forEach((element: any) => {
      // let searchContent =
      //   element?.title +
      //   "<br><div class='p-tag-searchbar-header'>" + (element?.highlight_attributes?.tag ? element?.highlight_attributes?.tag : '') + "</div>"
      // let OptionsStr = searchContent
      optionData.push(element?.title)
    });
    setAutoCompleteOptions(optionData || [])
    //@ts-ignore
    if (autoCompleteFields?.length !== 0) {
      setOpen(true)
    }
  }, [autoCompleteFields]);

  React.useEffect(() => {
    if (!window.navigator.onLine) {
      Cookies.set("isOnline", false)
      setIsOnline(false)
    } else {
      Cookies.set("isOnline", true)
      setIsOnline(true)
    }
    window.addEventListener("offline", () => {

      Cookies.set("isOnline", false)
      setIsOnline(false)

    });
    window.addEventListener('online', (event) => {
      Cookies.set("isOnline", true)
      setIsOnline(true)
      location.reload()
    });
    return () => {
      window.removeEventListener("offline", () => {
        Cookies.set("isOnline", true)
      });
    };
  });

  React.useEffect(() => {
    let x = document.querySelector('.MuiAutocomplete-clearIndicatorDirty');
    if (x) {
      x.addEventListener('click', (event: any) => {
        allCoursesApi && allCoursesApi()
        cleareautoCompleteFields()
        setOpen(false)
        setValue('');
        event.stopImmediatePropagation();
      })
    }
  }, [open]);

  React.useEffect(() => {
    if (value === '' && setSearchValue) {
      setSearchValue('');
    }
  }, [value])

  React.useEffect(() => {
    if (valueShopSearch === '' && setSearchValue) {
      setSearchValue('');
    }
  }, [valueShopSearch])

  React.useEffect(() => {
    window.addEventListener("offline", () => window.location.reload())
  }, []);

  return (
    <>
      <header className="header" data-testid="app-header" >
        <div className="container-temp">
          <nav className="navbar">
            <IconButton
              edge="start"
              style={{
                cursor: "pointer",
              }}
              aria-label="menu"
              onClick={() => history.push("/")}
            >
              <img src={AppLogo} alt="logo" className="mvp-logo-app" />
            </IconButton>
            <div
              className={isSearchBarCilcked ? "burger-close" : "burger"}
              id="burger"
            >
              <Menu />
            </div>
            <span
              className="overlay"
              style={{ backgroundColor: "rgb(0 0 0 / 26%)" }}
            />
            {!isSearchBarCilcked && (
              <div className="menu" id="menu">
                <ul className="menu-inner">
                  <li className="menu-item">
                    <Box
                      className={history.location.pathname === "/" ? "link-container active-link" : "link-container default"}
                      style={{
                        ...webStyle.routeContainer,
                      }}
                      onClick={() => history.push("/")}
                    >
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                      >
                        <img
                          src={homeLogo}
                          alt="logo"
                          style={{ height: "15px", width: "15px" }}
                        />
                      </IconButton>
                      <Typography
                        variant="body2"
                        style={{
                          color: "white",
                          marginLeft: "-2px",
                          fontSize: "1.1067708333333333vw",
                          cursor: "pointer",
                          width: '2.5vw'
                        }}
                      >
                        Home
                      </Typography>
                    </Box>
                  </li>

                  <li className="menu-item" onClick={() => history.push("/courses")}
                    id="training-btn-0"
                    data-testid="training-btn">
                    <Box
                      className={history.location.pathname === "/courses" ? "link-container active-link" : "link-container default"}
                      style={{
                        ...webStyle.routeContainer,
                      }}
                    >
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                      >
                        <img
                          src={trainingLogo}
                          alt="logo"
                          style={{ height: "15px", width: "15px" }}
                        />
                      </IconButton>
                      <Typography
                        variant="body2"
                        color="inherit"
                        style={{
                          color: "white",
                          marginLeft: "-2px",
                          fontSize: "1.1067708333333333vw",
                          cursor: "pointer",
                        }}
                      >
                        Training
                      </Typography>
                    </Box>
                  </li>

                  <li className="menu-item">
                    <Box
                      className={history.location.pathname === "/shop" ? "link-container active-link" : "link-container default"}
                      style={{
                        ...webStyle.routeContainer,
                      }}
                      onClick={() => history.push("/shop")}
                    >
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                      >
                        <img
                          src={shopLogo}
                          alt="logo"
                          style={{ height: "15px", width: "15px" }}
                        />
                      </IconButton>
                      <Typography
                        variant="body2"
                        color="inherit"
                        style={{
                          color: "white",
                          marginLeft: "-2px",
                          fontSize: "1.1067708333333333vw",
                          cursor: "pointer",
                        }}
                      >
                        Shop
                      </Typography>
                    </Box>
                  </li>

                  {
                    isOnline && <li className="menu-item">
                      <Box
                        className={history.location.pathname === "/comingsoon" ? "link-container active-link" : "link-container default"}
                        style={{
                          ...webStyle.routeContainer,
                        }}
                        onClick={() => history.push("/comingsoon")}
                      >
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="menu"
                        >
                          <img
                            src={commingSoonLogo}
                            alt="logo"
                            style={{ height: "15px", width: "15px" }}
                          />
                        </IconButton>

                        <Tooltip title={"This section will available soon."}>
                          <Typography
                            variant="body2"
                            color="inherit"
                            style={{
                              color: "white",
                              marginLeft: "-2px",
                              fontSize: "1.1067708333333333vw",
                              cursor: "pointer",
                            }} >
                            Coming Soon
                          </Typography>
                        </Tooltip>

                      </Box>
                    </li>
                  }
                  {isOnline &&
                    <li className="menu-item">
                      <Box
                        className={history.location.pathname === "/fillableforms" ? "link-container active-link" : "link-container default"}
                        style={{
                          ...webStyle.routeContainer,
                        }}
                        onClick={() => {
                          history.push("/fillableforms")
                        }}
                      >
                        <IconButton
                          edge="start"
                          color="inherit"
                          aria-label="menu"
                        >
                          <img
                            src={fillableLogo}
                            alt="logo"
                            style={{ height: "15px", width: "15px" }}
                          />
                        </IconButton>
                        <Typography
                          variant="body2"
                          color="inherit"
                          style={{
                            color: "white",
                            marginLeft: "-2px",
                            fontSize: "1.1067708333333333vw",
                            cursor: "pointer",
                          }}
                        >
                          Fillable Forms
                        </Typography>
                      </Box>
                    </li>
                  }
                </ul>
              </div>
            )}
            {window.location.pathname === "/courses" ||
              window.location.pathname === "/contact" ||
              window.location.pathname === "/comingsoon" ||
              window.location.pathname === "/terms" ||
              window.location.pathname === "/about" ||
              window.location.pathname === "/privacy" ||
              window.location.pathname === "/shop" ||
              window.location.pathname === "/" ||
              window.location.pathname === "/fillableforms" ||
              window.location.pathname === "/leadform" ||
              window.location.pathname === "/memoform" ||
              window.location.pathname === "/reviewform" ||
              window.location.pathname.includes("/courses/") ||
              window.location.pathname === "/howitworks" ? (
              <Toolbar
                className="menu-block"
                style={
                  window.location.pathname === "/" ||
                    window.location.pathname === "/fillableforms" ||
                    window.location.pathname === "/comingsoon" ||
                    window.location.pathname === "/leadform" ||
                    window.location.pathname === "/memoform" ||
                    window.location.pathname === "/reviewform" ||
                    window.location.pathname.includes("/courses/")
                    ? {
                      display: "flex",
                      justifyContent: "space-between",
                      visibility: "hidden",
                    }
                    : isSearchBarCilcked
                      ? {
                        display: "flex",
                        justifyContent: "space-between",
                        width: "61.442708vw",
                      }
                      : {
                        display: "flex",
                        justifyContent: "space-between",
                      }
                }
              >
                {
                  isSearchBarCilcked && isOnline && window.location.pathname === "/courses" &&(
                    <Autocomplete
                      value={value}
                      noOptionsText={'No Courses Available'}
                      className="textField"
                      id="asynchronous-demo"
                      open={open}
                      ref={ref}
                      onOpen={() => {
                        if (value.length >= 1) {
                          let input = value.trim();
                          if (input) {
                            setOpen(true);
                            getAutoCompleteFieldsData(input)
                          }
                        }
                      }}
                      onClose={(e: any) => {
                        setOpen(false);
                        cleareautoCompleteFields();
                      }}

                      getOptionSelected={(option, value) => {
                        return option === value
                      }
                      }

                      onChange={(evet, value: any, res) => {
                        //@ts-ignore
                        autoCompleteFields?.forEach((element: any) => {
                          if (value.toUpperCase().includes(element.title.toUpperCase())) {
                            history.push(`/courses/${element.id}`)
                          }
                        });

                      }}
                      onSelect={(x: any) => {
                        //@ts-ignore
                        // if(!x.target.value){
                        //   setOpen(false);
                        // }else if(){

                        // }

                        if (x.target.value.length < 2) {
                          cleareautoCompleteFields()
                          setOpen(false);
                        }
                        setValue(x.target.value);
                        //@ts-ignore
                        setValue1(x.target.value);
                        let input = x.target.value.trim();
                        if (!input) {
                          cleareautoCompleteFields()
                          setOpen(false);
                        } else if (input && input.length < 1) {
                          cleareautoCompleteFields()
                          setOpen(false);
                        }
                      }}

                      options={autoCompleteOptions || []}
                      renderOption={(option, { inputValue }) => {
                        // const indexOfBr = option.indexOf('<br>') as any;
                        // let title = option.substr(0, indexOfBr);
                        const matches = match(option, inputValue);
                        const parts = parse(option, matches);
                        return (
                          <div>
                            {parts.map((part: any, index: number) => (
                              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400, color: part.highlight && '#db5461' }}
                                dangerouslySetInnerHTML={{ __html: part.text }}>
                              </span>
                            ))}
                          </div>
                        );
                      }}
                      // loading={loading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e: any) => {


                            if (e.target.value == '') {
                              allCoursesApi && allCoursesApi()
                              setSearchValue(e.target.value);
                            }
                            // apiCall(e.target.value);
                            getAutoCompleteFieldsData(e.target.value)

                            setValue(e.target.value);
                          }}
                          data-testid="autoSearchBox"
                          onKeyPress={(e: any) => {
                            //@ts-ignore
                            if (e.key === "Enter") {
                              const formattedDataOfSchool = state.schools.map((item: { name: string }) => ({ name: item?.name, isChecked: false }))
                              const formattedDataOfOrganization = state.responseOrganization.map((item: { name: string }) => item.name !== "All" ? { name: item?.name, isChecked: false } : { name: item?.name, isChecked: true })
                              dispatch(resetFilterReduxState({
                                schoolFilterFields: formattedDataOfSchool,
                                orgFilterFields: formattedDataOfOrganization,
                                durationFields: DURATION_FILTER_FIELDS,
                                groupTypeFilterFields: GROUP_FILTER_FIELDS,
                                DifficultyFilterFields: DIFFICULTY_FILTER_FIELDS,
                                TrainingAidsFilterFields: TRAINING_AIDS_FILTER_FIELDS,
                                TrainingAreaFilterFields: TRAINING_AREA_FILTER_FIELDS,
                              }))
                              //@ts-ignore
                              setValue(e.target.value);
                              //@ts-ignore
                              setSearchValue(e.target.value);
                              e.preventDefault();
                              return;
                            }
                          }}

                          value={value}
                          variant="outlined"
                          className="textField"
                          id="searchingBar"
                          placeholder="Search for Courses / Acronyms"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                  )
                }
                {
                  isSearchBarCilcked && isOnline && window.location.pathname === "/shop" &&  (
                    <Autocomplete
                      autoComplete={false}
                      freeSolo
                      value={valueShopSearch}
                      className="textField"
                      id="asynchronous-demo"
                      open={openShopSearch}
                      ref={ref}
                      onOpen={() => {
                        setOpenShopSearch(true);
                        setIsSearchClose(false);
                        setSearchValue("");
                      }}
                      onClose={(e: any) => {
                        setSearchValue('');
                        setOpenShopSearch(false);
                      }}

                      onChange={(event, newValue: any) => {
                        setValueShopSearch(newValue)

                      }}
                      onSelect={(x: any) => {

                        if (x.target.value.length < 2) {
                          setOpenShopSearch(false);
                        }
                      }}

                      options={[]}                  
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e: any) => {
                            if (e.target.value === "") {
                              setSearchValue("getAll");
                            }
                          }}
                          data-testid="autoSearchBox"
                          onKeyPress={(e: any) => {
                            //@ts-ignore
                            if (e.key === "Enter") {
                              //@ts-ignore
                              setSearchValue(e.target.value);
                              e.preventDefault();
                              return;
                            }
                          }}

                          value={valueShopSearch}
                          variant="outlined"
                          className="textField"
                          // id="searchingBar"
                          placeholder="Search here"
                        />
                      )}
                    />
                  )
                }
                {
                  isSearchBarCilcked && !isOnline && (
                    <TextField
                      onChange={(e: any) => {
                        setOfflineSearchValue(e.target.value);
                        searchCoursesOffline && searchCoursesOffline(e.target.value);
                      }}
                      value={offlineSearchValue}
                      variant="outlined"
                      className="textField"
                      id="searchingBar"
                      placeholder="Search for offline Courses"
                      data-testid="search-bar"
                      InputProps={{
                        endAdornment: (
                          <SearchTwoToneIcon />
                        )
                      }}
                    />
                  )
                }
                <div style={{
                  display: 'flex',
                  minWidth: '100px'
                }}>
                  {!isSearchBarCilcked && (
                    <IconButton
                      edge="start"
                      style={{
                        marginRight: "2.2786458333333335vw",
                        borderRadius: "50%",
                        backgroundColor: "white",
                        height: "2.6041666666666665vw",
                        width: "2.6041666666666665vw",
                        marginLeft: "auto",
                      }}
                      color="inherit"
                      aria-label="menu"
                      data-testid="open-searchbar"
                      id="opensearchbar"
                      onClick={() => {
                        setIsSearchBarCilcked(true);
                      }}
                    >
                      <img src={searchLogo} alt="Search" className="img-1" />
                    </IconButton>
                  )}
                  {isSearchBarCilcked && (
                    <IconButton
                      edge="start"
                      style={{
                        marginRight: "2.2786458333333335vw",
                        borderRadius: "50%",
                        backgroundColor: "white",
                        height: "2.6041666666666665vw",
                        width: "2.6041666666666665vw",
                        marginLeft: "auto",
                      }}
                      color="inherit"
                      aria-label="menu"
                      data-testid="close-searchbar"
                      onClick={() => {
                        setIsSearchBarCilcked(false);
                        setIsSearchClose(true)
                        if (value) {
                          allCoursesApi && allCoursesApi()
                        }
                        if (sortBy === '(A-Z)' && schoolFilterValue?.length === 0 && organizationValues?.length === 0 && durationValues?.length === 0 && groupTypeFilterValue?.length === 0 && DifficultyFilterValue?.length === 0 && TrainingAidsFilterValue?.length === 0 && TrainingAreaFilterValue?.length === 0) {
                          setValue('');
                        }
                        setOptions([]);
                      }}
                    >
                      <img src="https://img.icons8.com/material-outlined/24/000000/multiply--v1.png" alt="Search" className="img-1" />
                    </IconButton>
                  )}
                  {(window.location.pathname === "/courses") && (
                    <>
                      <br />
                      <IconButton
                        onClick={() => {
                          if (getAutoCompleteFieldsData && setSearchValue && isSearchBarCilcked) {
                            setIsSearchBarCilcked(false);
                            getAutoCompleteFieldsData('')
                            if (sortBy === '(A-Z)' && schoolFilterValue?.length === 0 && organizationValues?.length === 0 && durationValues?.length === 0 && groupTypeFilterValue?.length === 0 && DifficultyFilterValue?.length === 0 && TrainingAidsFilterValue?.length === 0 && TrainingAreaFilterValue?.length === 0) {
                              setValue('');
                              setSearchValue('');
                            }
                          }
                          handleSidebar()
                        }}
                        edge="start"
                        style={{
                          marginRight: "0.6510416666666666vw",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          height: "2.6041666666666665vw",
                          width: "2.6041666666666665vw",
                          marginLeft: "auto",
                        }}
                        color="inherit"
                        aria-label="menu"
                        data-testid="handle-sidebar"
                      >
                        <img src={filterLogo} alt="Search" className="img-2" />
                      </IconButton>
                    </>
                  )}
                </div>
              </Toolbar>
            ) : null}
          </nav>
        </div>
      </header>
      {
        //@ts-ignore
        (!isOnline && !window.location.pathname.includes('/courses/')) && (
          <div className="is-offline-container">
            <Typography
              variant="body2"
              color="error"
            >
              You Are Offline Now.
            </Typography>
          </div>
        )}
    </>
  );
};

const webStyle = {
  header: {
    backgroundColor: "#0C120C",
    display: "flex",
    flexDirection: "row",
  },
  routeContainer: {},
  routeButton: {
    marginRight: 5,
    // backgroundColor: "#0C120C",
    marginTop: 25,
    marginBottom: 25,
    height: "20px",
    width: "20px",
  },
  routeTitle: {
    //@ts-ignore
    fontWeight: "400",
    cursor: "pointer",
  },
};

//@ts-ignore
const headerWithRouter = withRouter(TempHeader);
//@ts-ignore
export default withStyles(webStyle)(headerWithRouter);
// Customizable Area End
