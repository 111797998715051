import {
  GET_ALL_COURSES,
  GET_ALL_PRODUCTS,
  SET_FILTER_BY_NAME,
  SET_VALUE_BY_NAME,
  RESET_FILTER_STATE,
  IS_LOADING
} from './constant';

let initialState = {
  courses: [],
  products: [],
  sortBy: '(A-Z)',
  schoolFilterValue: [],
  schoolFilterFields: [],
  organizationValues: [],
  organizationFields: [],
  durationValues: [],
  durationFields: [],
  groupTypeFilterValue: [],
  groupTypeFilterFields: [],
  DifficultyFilterValue: [],
  DifficultyFilterFields: [],
  TrainingAidsFilterValue: [],
  TrainingAidsFilterFields: [],
  TrainingAreaFilterValue: [],
  TrainingAreaFilterFields: [],
  responseOrganization: [],
  schools: [],
  temporaryData: [],
  isLoading:false
};

export const AppReducer = (state = initialState, action) => {
  let currentState = { ...state };
  switch (action.type) {
    case SET_FILTER_BY_NAME:
      currentState[action.data.name] = action.data.value;
      return currentState;
    case SET_VALUE_BY_NAME:
      currentState[action.data.name] = action.data.value;
      return currentState;
    case RESET_FILTER_STATE:
      return {
        ...state,
        sortBy: '(A-Z)',
        schoolFilterValue: [],
        schoolFilterFields: action.data.schoolFilterFields,
        organizationValues: [],
        organizationFields: action.data.orgFilterFields,
        durationValues: [],
        durationFields: action.data.durationFields,
        groupTypeFilterValue: [],
        groupTypeFilterFields: action.data.groupTypeFilterFields,
        DifficultyFilterValue: [],
        DifficultyFilterFields: action.data.DifficultyFilterFields,
        TrainingAidsFilterValue: [],
        TrainingAidsFilterFields: action.data.TrainingAidsFilterFields,
        TrainingAreaFilterValue: [],
        TrainingAreaFilterFields: action.data.TrainingAreaFilterFields
      };
    case GET_ALL_COURSES:
      return { ...state, courses: action.data };
      case GET_ALL_PRODUCTS:
        return { ...state, products: action.data };
    case IS_LOADING:
      currentState.isLoading=action.data
      return currentState;
    default:
      return state;
  }
};
