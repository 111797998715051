import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
// Customizable Area End

import GoogleAdsenseIntegrationController, {
  Props,
  configJSON,
} from "./GoogleAdsenseIntegrationController";

export default class GoogleAdsenseIntegration extends GoogleAdsenseIntegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      
      <ins className='adsbygoogle'
        data-test-id={"adsenseTestID"}
        style={{ display: 'block'}}
        //we need to add the clientId their
        data-ad-client={configJSON.dataAdClient}
        data-ad-slot="8714601560"
        data-ad-format='auto'
        data-full-width-responsive='true'
      />
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
