Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.brandAPiEndPoint = "catalogue/brands";
exports.filterbrandAPiEndPoint = "filter_items/filtering?q[brand_id]=";
exports.singleCategoryAPiEndPoint = "filter_items/filtering?q[category_id]=";
exports.cataloguesAPiEndPoint = "filter_items/filtering?";
exports.organiztionEndPoint="bx_block_organizations/organizations"
exports.coursesEndPoint="bx_block_courses/courses"
exports.productsEndPoint= "bx_block_affiliateurl/affiliateproducts";
exports.searchProductsApiContentType= "application/json";
exports.searchProductsApiEndPoint= "bx_block_affiliateurl/affiliateproducts/search_product?keyword=";
exports.searchProductsApiMethod = "GET";
exports.affiliatesCountApiContentType= "application/json";
exports.affiliatesCountApiEndPoint= "bx_block_affiliateurl/affiliateproducts/affiliates_count";
exports.affiliatesCountApiMethod = "PUT";
exports.searchCoursesApiContentType = "application/json";
exports.searchCoursesApiEndPoint = "bx_block_courses/search_course?search=";
exports.searchCoursesApiMethod = "GET";
exports.filterEndPoint="bx_block_courses/apply_filters"
exports.httpPostApiMethod = "POST";
exports.getSchoolApiMethod = "GET";
exports.getSchoolApiEndPoint="bx_block_organizations/schools/";
exports.getSchoolByidApiEndPoint="bx_block_organizations/organizations/";
exports.getSchoolApiContentType = "application/json";
exports.getCourseDataAPiEndPoint = "bx_block_courses/courses";
exports.getCourseDataAPiMethod = "GET";
exports.getAutoCompleteFieldsApiContentType = "application/json";
exports.getAutoCompleteFieldsApiEndPoint = "/bx_block_courses/autocomplete?search=";
exports.getAutoCompleteFieldsApiMethod = "GET";
exports.postDownloadCount = "/bx_block_courses/update_download_count";
exports.httpPutApiMethod="PUT"
exports.schoolFields = [
  {
    name: "Airborne school",
    isChecked: false,
  },
  {
    name: "Officer Candidate",
    isChecked: false,
  },
  {
    name: "Reserve Officer Training Corps",
    isChecked: false,
  },
  {
    name: "Recruiting",
    isChecked: false,
  },
  {
    name: "Basic Training (Boot Camp)",
    isChecked: false,
  },
  {
    name: "Police Academy",
    isChecked: false,
  },
  {
    name: "Infintery",
    isChecked: false,
  },
  {
    name: "Field Artillery",
    isChecked: false,
  },
  {
    name: "Administrative",
    isChecked: false,
  },
  {
    name: "Combat Medic",
    isChecked: false,
  },
  {
    name: "Weapons",
    isChecked: false,
  },
  {
    name: "Air Assault",
    isChecked: false,
  },
  {
    name: "Sapper",
    isChecked: false,
  },
  {
    name: "Combact Engineer",
    isChecked: false,
  }
];

exports.GROUP_FILTER_FIELDS = [
  {
    name: "Individual",
    isChecked: false,
  },
  {
    name: "Team/Small (2-5)",
    isChecked: false,
  },
  {
    name: "Squad/Medium (6-10)",
    isChecked: false,
  },
  {
    name: "Platoon/Large (11-30)",
    isChecked: false,
  },
]

exports.DIFFICULTY_FILTER_FIELDS = [
  {
    name: "Basic",
    isChecked: false,
  },
  {
    name: "Intermediate",
    isChecked: false,
  },
  {
    name: "Advanced",
    isChecked: false,
  },
  {
    name: "Pro",
    isChecked: false,
  }
];

exports.TRAINING_AIDS_FILTER_FIELDS = [
  {
    name: "No materials required",
    isChecked: false,
  },
  {
    name: "Materials required",
    isChecked: false,
  },
];

exports.TRAINING_AREA_FILTER_FIELDS = [
  {
    name: "Small Space",
    isChecked: false,
  },
  {
    name: "Medium Space",
    isChecked: false,
  },
  {
    name: "Large Space",
    isChecked: false,
  }
];
exports.DURATION_FILTER_FIELDS = [
  {
    name: "0-10 mins",
    isChecked: false,
  },
  {
    name: "11-15 mins",
    isChecked: false,
  },
  {
    name: "16-30 mins",
    isChecked: false,
  },
  {
    name: "31-45 mins",
    isChecked: false,
  },
  {
    name: "46-60 mins",
    isChecked: false,
  },
];
// Customizable Area End