import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
toast.configure()
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  aboutContent?:any;
  addAboutContent?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  responseAbout: any;
  loadingContent:boolean;
  loadingContentNot:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class AboutUsWebController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAboutApiCallId:any
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      responseAbout:[],
      loadingContent:true,
      loadingContentNot:false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    this.getAbout();
  }
   
  //Getting terms data
  getAbout=(): boolean =>{
    
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAboutApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType 
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.getAboutApiCallId &&
            responseJson !== undefined
          ) {
            this.setState({responseAbout:responseJson[0].about_us,loadingContent:false})
          }
        }
      } else {
         
        // this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
        this.setState({loadingContent:false,loadingContentNot:true})
        // toast.error("Error occurred.",{position: toast.POSITION.BOTTOM_RIGHT})
      }
    }
    // Customizable Area End
  }
}
// Customizable Area End