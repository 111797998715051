// Customizable Area Start
import React from "react";
import "../assets/FillableFormsTabWeb.scss";
import "../assets/CourseDetails.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const configJSON = require("./config");
import FillableFormsTabWebControllerWeb, {
  Props,
} from "./FillableFormsTabWebControllerWeb.web";
import FillableForms from "../../../components/src/FillableForms.web";
import Header from "../../../components/src/HeaderApp.web";
import Footer from "../../../components/src/Footer.web";
//@ts-ignore
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { menuIcon, fillableFormMoveIcon } from "./assets";

export default class FillableFormsTabWeb extends FillableFormsTabWebControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div
        style={{ display: "flex", flexDirection: "column", height: "auto" }}
        className="fillable-form-main-container"
      >
        <Header
          //@ts-ignore
          BackToMainPage={this.BackToMainPage}
        />
        <NotificationContainer />
        <div className="container-fillable">
          <div className="wrapper-fillable w-50">
            {this.state.currentForm === "After_Action_Review_Form" && (
              <div className="container-center-data">
                <div className="course-header-title-container">
                  <br />
                  <h3 className="course-header-title header-of-filabble">
                    After Action Review Form
                  </h3>
                  <br />
                </div>
                <FillableForms
                  formName="After Action Review Form"
                  doSubmitFillableForm={this.doSubmitFillableForm}
                  BackToMainPage={this.BackToMainPage}
                />
              </div>
            )}
            {this.state.currentForm === "Lead_Sheet" && (
              <div className="container-center-data">
                <div className="course-header-title-container">
                  <br />
                  <h3 className="course-header-title header-of-filabble">
                    Lead Sheet
                  </h3>
                  <br />
                </div>
                <FillableForms
                  formName="Lead Sheet"
                  doSubmitFillableForm={this.doSubmitFillableForm}
                  BackToMainPage={this.BackToMainPage}
                />
              </div>
            )}
            {this.state.currentForm === "Army_Formatted_Memo_Form" && (
              <div className="container-center-data">
                <div className="course-header-title-container">
                  <br />
                  <h3 className="course-header-title header-of-filabble">
                    Army Formatted Memo Form
                  </h3>
                  <br />
                </div>
                <FillableForms
                  formName="Army Formatted Memo Form"
                  doSubmitFillableForm={this.doSubmitFillableForm}
                  BackToMainPage={this.BackToMainPage}
                />
              </div>
            )}
            {this.state.currentForm === "" && (
              <div className="main-course-div">
                <div
                  style={{ cursor: "pointer" }}
                  id="main-div-list-course"
                  className="main-div-list-course"
                >
                  <div
                    className="div-fillable"
                    onClick={() => {
                      this.props.navigation.navigate("ReviewFormWeb");
                    }}
                    data-test-id="review-form"
                  >
                    <img src={menuIcon} />
                    <div>
                      <div className="div-identity-sub">
                        <h1 className="identity-line">
                          After Action Review Form
                        </h1>
                      </div>
                      <div className="sub-div-identity" />
                    </div>
                    <img
                      src={fillableFormMoveIcon}
                      alt=""
                      className="fillable-icon"
                    />
                  </div>
                  <hr />
                  <div
                    className="div-fillable"
                    onClick={() => {
                      this.props.navigation.navigate("LeadFormWeb");
                    }}
                  >
                    <img src={menuIcon} />
                    <div>
                      <div className="div-identity-sub">
                        <h1 className="identity-line">Lead Sheet</h1>
                      </div>
                      <div className="sub-div-identity" />
                    </div>
                    <img
                      src={fillableFormMoveIcon}
                      alt=""
                      className="fillable-icon"
                    />
                  </div>
                  <hr />
                  <div
                    className="div-fillable"
                    onClick={() => {
                      this.props.navigation.navigate("MemoArmyFormWeb");
                    }}
                  >
                    <img src={menuIcon} />
                    <div>
                      <div className="div-identity-sub">
                        <h1 className="identity-line">
                          Army Formatted Memo Form
                        </h1>
                      </div>
                      <div className="sub-div-identity" />
                    </div>
                    <img
                      src={fillableFormMoveIcon}
                      alt=""
                      className="fillable-icon"
                    />
                  </div>
                  <hr />
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
// Customizable Area End
