import React from "react";
import { InputLabel, TextField, FormHelperText } from "@material-ui/core";
//@ts-ignore
import cancelIcon from "./cancel.svg"
import "./CustomeTextField.scss";
import moment from "moment";
interface Props {
  handleChange?: any;
  handleBlur?: any;
  value: any;
  error?: any;
  touched: any;
  errors?: any;
  placeholder?: any;
  name?: any;
  label?: any;
  multiline?: any;
  countCharNum?: any;
  required?: any;
  type?: any;
  number?: number;
  showCross?: any;
  showNumber?: any;
  onCrossClick?: (name: string | number) => void;
  disabled?: boolean;
  classname?: string;
  assessMentForm?:any;
  maxDate?:any;
}

const CustomTextField: React.FC<Props> = (props) => {
  let CHARACTER_LIMIT = 1000;
  const today = moment()
  return (
    <><div className="textfield-container" style={{ position: "relative" }}>
      {props.label && (
        <InputLabel
          htmlFor="input-with-icon-adornment"
          style={{
            fontSize: "22px",
            //@ts-ignore
            fontWeight: "400",
            color: "#000000",
            paddingBottom: "10px",
            width: "300px",
          }}
          className={props.name}
        >
          {props.label}
        </InputLabel>
      )}
      {
        props.number ? (
          <span className="field-number">
           {props?.number}.
          </span>
        ) : <></>
      }
      <TextField
        style={{
          minWidth: "300px",
          minHeight: "100px",
          marginBottom: props.multiline ? "100px" : "0px",
          border: "0px solid white",
        }}
        className={props?.classname}
        inputProps={
          props.placeholder.includes("DD/MM/YYYY")?{
          maxLength: CHARACTER_LIMIT,
          max: today.format('YYYY-MM-DD')
        }:
        {
          maxLength: CHARACTER_LIMIT,
        }}
        disabled={props.disabled}
        multiline={props.multiline}
        minRows={props.multiline ? 5 : 1}
        id={props.name}
        placeholder={props.placeholder}
        variant="outlined"
        required={props.required}
        name={props.name}
        onChange={props.handleChange}
        onBlur={props.handleBlur}
        value={props.value || ""}
        type={props.type}
        onFocus={(e: any) => {
          if (props.placeholder.includes("DD/MM/YYYY")) {
            e.currentTarget.type = "date";
            e.currentTarget.onkeydown = function (evt:any) {
              return false;
            } 
          }
        }}
        
        error={props.error ? true : false}
      />
      {props?.assessMentForm !=='assessMentForm'&&
      <FormHelperText
        error
        className={props?.assessMentForm==='assessMentForm'?("text-field-helpertext "+ " assessMentForm"):("text-field-helpertext " + props.name+" "+ props?.classname+'addF')}
        style={{ top: props.label == undefined ? "55px" : "87px" }}
      >
        {props.error}
      </FormHelperText>}
      {props.countCharNum ? (
        <span className={'remain-char'}>
          {/* {`${CHARACTER_LIMIT - props.value.length} Characters remaining`} */}
          {`${CHARACTER_LIMIT - (props.value?.length || 0)} Characters remaining`}
        </span>
      ) : (
        <></>
      )}
      {
        props.showCross && props.number !== 1  ? (
          <span className="cancel-icon" style={{cursor:'pointer'}} onClick={() =>props.onCrossClick && props.number ? props.onCrossClick(props?.number) : () =>{}}>
            <img src={cancelIcon} alt="cancle"/>
          </span>
        ) : <></>
      }
    </div>
    {props?.assessMentForm==='assessMentForm'&&
    <h6  
    className='h6-asseesment'
    style={{position:'initial',marginTop:'-90'}}>
    {props.error}
  </h6>
  }</>
  );
};

export default CustomTextField;
