// Customizable Area Start
import React from "react";

export const configJSON = require("./config");
import {
  list,
  offline
} from "./assets";
import {
  // Customizable Area Start
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  TextField,
  // Customizable Area End
} from "@material-ui/core";
import "../assets/CaursesPageOfflineAccess.scss";
import CoursesListPageOfllineController, { Props } from "./CoursesListPageOfllineController.web";
import CourseDetailHeader from "../../../components/src/CourseDetailHeader.web";
import Header from "../../../components/src/HeaderApp.web";
import Footer from "../../../components/src/Footer.web";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
class CoursesListPageOflline extends CoursesListPageOfllineController {
  constructor(props: Props) {
    super(props);
  }

  render() {
  
    return (
      // Customizable Area Start
      <div>
        <Header 
        //@ts-ignore
        offlineCourseDetail={"offlineCourseDetail"}/>
        <CourseDetailHeader
          value={this.state.tabValue}
          setValue={this.setTabValue}
        />
         <div className="is-offline-container">
          <Typography
            variant="body2"
          >
            You Are Offline Now.
          </Typography>
        </div>
        <div  className="course-details-offline-container">
        {
          this.state.tabValue === 0 
          ? this.state.responseData !== null 
              ? <div style={{display: 'flex', flexDirection: 'column', height:'auto'}}>
                <div className="course-header-title-container"><h3 className="course-header-title">{this.state.courseDetail?.title}</h3></div>
                  <div className="pading-course-related" dangerouslySetInnerHTML={{__html:this.state.responseData}} /> 
                </div>
              : <div className="center" style={{paddingLeft: '20px'}}>No Course Data Available</div>
          : <></>
        }
        {this.state.tabValue === 1 
        &&<div className="pading-course-related"><h4>Not accessible offline.</h4></div>}
        {this.state.tabValue === 2 &&<div className="pading-course-related"><h4>Not accessible offline.</h4></div>}
        {this.state.tabValue === 3 &&  
        <div style={{display: 'flex', flexDirection: 'column', height:'auto'}}>
        <div className="course-header-title-container"><h3 className="course-header-title">{this.state.courseDetail?.title}</h3></div>
        <div className="pading-course-related" dangerouslySetInnerHTML={{__html:this.state.courseDetail?.course_references}} />
        </div>
        }
        {this.state.tabValue === 4 && 
        <div style={{display: 'flex', flexDirection: 'column', height:'auto'}}>
        <div className="course-header-title-container"><h3 className="course-header-title">{this.state.courseDetail?.title}</h3></div>
        <div className="pading-course-related" dangerouslySetInnerHTML={{__html:this.state.courseDetail?.course_pro_tips}} />
        </div>
         }
        {this.state.tabValue === 5 && <div className="pading-course-related"><h4>Not accessible offline.</h4></div>}
        </div>
        <Footer />
      </div>
      // Customizable Area End
    );

    // return (
    //   <div style={{display: 'flex', flexDirection: 'column', height:'auto'}}>
    //     <Header />
    //     <div className="container">
    //       <div className="wrapper-training w-50" style={{paddingBottom:'3.255vw',paddingTop:'3.255vw'}} dangerouslySetInnerHTML={{ __html:this.state.responseData}}>
    //       </div>
    //     </div>
    //   </div>
    // );
  }
}
// const mapDispatchToProps = (dispatch:any) => {
//   return{
//     addCoursesContent: (courseList:any) => {
//     dispatch(GET_COURSES(courseList));
//     },
//     addOrgContent: (orgList:any) => {
//       dispatch(GET_ORGANIZATION(orgList));
//     },
//  }
// }
// const mapStateToProps = (state:any) => {
//     return {
//       coursesContent: state?.HipPocketFireActions?.coursesDataList,
//       orgContent:state?.HipPocketFireActions?.organizationDataList[0],
//     };
// };

//@ts-ignore
const offlineListpage = withRouter(CoursesListPageOflline)
//@ts-ignore
export default withStyles(null)(offlineListpage)
// Customizable Area End
