// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import Header from '../../components/src/Header.web';
import Footer from '../../components/src/Footer.web';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import OfflineBrowsing from '../../blocks/OfflineBrowsing/src/OfflineBrowsing';
import Documentation from '../../blocks/Documentation/src/Documentation';
import AdvancedSearch from '../../blocks/advancedsearch/src/AdvancedSearch';
import GoogleAdsenseIntegration from '../../blocks/GoogleAdsenseIntegration/src/GoogleAdsenseIntegration';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import AffiliateUrl from '../../blocks/AffiliateUrl/src/AffiliateUrl';
import Analytics from '../../blocks/analytics/src/Analytics';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import Library2 from '../../blocks/Library2/src/Library2';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import TermsAndConditions from '../../blocks/TermsAndConditions/src/TermsAndConditions';
import Sorting from '../../blocks/sorting/src/Sorting';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import HamburgerMenu from '../../blocks/HamburgerMenu/src/HamburgerMenu';
import SplashScreen from '../../blocks/splashscreen/src/SplashScreen.web';
import Onboardingguide from '../../blocks/onboardingguide/src/Onboardingguide';
import AdManager from '../../blocks/AdManager/src/AdManager';
import CfAffiliateAggregatorApi2 from '../../blocks/CfAffiliateAggregatorApi2/src/CfAffiliateAggregatorApi2';
import Customform from '../../blocks/customform/src/Customform';
import ProductTutorial from '../../blocks/ProductTutorial/src/ProductTutorial';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import ContactUsWeb from '../../blocks/contactus/src/ContactUsWeb.web';
import TermsAndConditionsWeb from '../../blocks/TermsAndConditions/src/TermsAndConditionWeb.web';
import PrivacyPolicyWeb from '../../blocks/TermsAndConditions/src/PrivacyPolicyWeb.web';
import AboutUs from '../../blocks/TermsAndConditions/src/AboutUsWeb.web';
import Training from '../../blocks/filteritems/src/TrainingS1Web.web';
import Home from '../../blocks/splashscreen/src/Home.web';
import CourseDetails from '../../blocks/info-page/src/CourseDetails.web';
import ShopePage from '../../blocks/filteritems/src/ShopePage.web';
import CaursesPageOfflineAccess from '../../blocks/OfflineBrowsing/src/CaursesPageOfflineAccess.web';
import CoursesListPageOflline from '../../blocks/OfflineBrowsing/src/CoursesListPageOflline.web';
import FillableFormsTabWeb from '../../blocks/info-page/src/FillableFormsTabWeb.web';
import ReviewFormWeb from '../../blocks/info-page/src/ReviewFormWeb.web';
import LeadFormWeb from '../../blocks/info-page/src/LeadFormWeb.web';
import MemoArmyFormWeb from '../../blocks/info-page/src/MemoArmyFormWeb.web';
import ComingSoonWeb from '../../blocks/navigationmenu/src/ComingSoon.web';

const routeMap = {
  CfAffiliateAggregatorApi2: {
    component: CfAffiliateAggregatorApi2,
    path: '/CfAffiliateAggregatorApi2'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  ProductTutorial: {
    component: ProductTutorial,
    path: '/ProductTutorial'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },

  OfflineBrowsing: {
    component: OfflineBrowsing,
    path: '/OfflineBrowsing'
  },
  Documentation: {
    component: Documentation,
    path: '/Documentation'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  GoogleAdsenseIntegration: {
    component: GoogleAdsenseIntegration,
    path: '/GoogleAdsenseIntegration'
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu'
  },
  ComingSoon: {
    component: ComingSoonWeb,
    path: '/comingsoon'
  },
  AffiliateUrl: {
    component: AffiliateUrl,
    path: '/AffiliateUrl'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  Library2: {
    component: Library2,
    path: '/Library2'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  HamburgerMenu: {
    component: HamburgerMenu,
    path: '/HamburgerMenu'
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: '/Onboardingguide'
  },
  AdManager: {
    component: AdManager,
    path: '/AdManager'
  },
Home: {
    component: Home,
    path: '/home',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  ContactUsWeb: {
    component: ContactUsWeb,
    path: '/Contact',
    modal: true
  },
  TermsAndConditionsWeb: {
    component: TermsAndConditionsWeb,
    path: '/terms',
    modal: true
  },
  PrivacyPolicyWeb: {
    component: PrivacyPolicyWeb,
    path: '/privacy',
    modal: true
  },
  AboutUs: {
    component: AboutUs,
    path: '/about',
    modal: true
  },
  Course: {
    component: CourseDetails,
    path: '/courses/:id',
    exact: true,
  },
  Training: {
    component: Training,
    path: '/courses',
    exact: true,
  },
  Home: {
    component: Home,
    path: '/',
    exact: true
  },
  // MvpScreen: {
  //   component: SplashScreen,
  //   path: '/',
  //   exact: true
  // },
  ShopePage: {
    component: ShopePage,
    path: '/shop',
    exact: true
  },
  CaursesPageOfflineAccess: {
    component: CaursesPageOfflineAccess,
    path: '/offline',
    exact:true
  },
  CoursesListPageOflline: {
    component: CoursesListPageOflline,
    path: '/courseListOflline/:id',
    exact:true
  },
  FillableFormsTabWeb: {
    component: FillableFormsTabWeb,
    path: '/fillableforms',
    exact:true
  },
  ReviewFormWeb: {
    component: ReviewFormWeb,
    path: '/reviewform',
    exact:true
  },
  LeadFormWeb: {
    component: LeadFormWeb,
    path: '/leadform',
    exact:true
  },
  MemoArmyFormWeb: {
    component: MemoArmyFormWeb,
    path: '/memoform',
    exact:true
  }
};

// const firebaseAPI = firebase.initializeApp({
//   apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
//   authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
//   databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
//   projectId: 'rnmasterapp-c11e9',
//   storageBucket: 'rnmasterapp-c11e9.appspot.com',
//   messagingSenderId: '649592030497',
//   appId: '1:649592030497:web:7728bee3f2baef208daa60',
//   measurementId: 'G-FYBCF3Z2W3'
// });

class App extends Component {
  render() {
    // const defaultAnalytics = firebaseAPI.analytics();
    // defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100%', width: '100%' }}>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
