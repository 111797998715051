import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
//@ts-ignore
import { debounce} from "lodash";
//@ts-ignore
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  addCoursesContent?: any;
  coursesContent?: any;
  addOrgContent?:any;
  orgContent?:any;
  history?:any;
  location?:any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  responseData: any;
  tabValue: number;
  courseDetail:any
  isOffline:any
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class CoursesListPageOfllineController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      responseData:[],
      tabValue:0,
      courseDetail:[],
      isOffline:''
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  setTabValue = (value: number) => {
    this.setState({
      tabValue: value,
    });
  };





async componentDidMount() {
  // if(!window.navigator.onLine){
    Cookies.set("isOnline",false)
   // this.setState({isOffline:false})
  const coursesArray = JSON.parse(localStorage.getItem("allCourses") as any||"[]");
  if(coursesArray?.length !==0){
    coursesArray?.forEach((courseLocalStorage:any) => {
      //@ts-ignore
      if(courseLocalStorage.id ==this.props?.match?.params?.id){
        this.setState({courseDetail:courseLocalStorage})
      }
    }) 
  }
  
  //@ts-ignore
 if (this.props?.match?.params?.id) {
  //@ts-ignore
  let data=JSON.parse( localStorage.getItem("courseDataArray") as any)
  let listData;
  if(data){
    
    //@ts-ignore
    listData= data?.find((item:any)=>{
      //@ts-ignore
     return  this.props?.match?.params?.id==item.id  
     }).courseContent
  }
  this.setState({responseData:listData})
  }
  // }else{
  //   Cookies.set("isOnline",true)
  //  // this.setState({isOffline:true})
  //   //@ts-ignore
  //   this.props.history.push(`/courses/${this.props?.match?.params?.id}`) 
  // }
 
}

// componentDidUpdate(prevProps: any, prevState: any) {

  
//   // debugger
//   if (prevState.isOffline !== this.state.isOffline) {
//     if(!window.navigator.onLine){
//     Cookies.set("isOnline",false)
//     //this.setState({isOffline:false})
//     const coursesArray = JSON.parse(localStorage.getItem("allCourses") as any||"[]");
//     if(coursesArray?.length !==0){
//       coursesArray?.forEach((courseLocalStorage:any) => {
//         //@ts-ignore
//         if(courseLocalStorage.id ==this.props?.match?.params?.id){
//           this.setState({courseDetail:courseLocalStorage})
//         }
//       }) 
//     }
    
//     //@ts-ignore
//    if (this.props?.match?.params?.id) {
//     //@ts-ignore
//     let data=JSON.parse( localStorage.getItem("courseDataArray") as any)
//     let listData;
//     if(data){
      
//       //@ts-ignore
//       listData= data?.find((item:any)=>{
//         //@ts-ignore
//        return  this.props?.match?.params?.id==item.id  
//        }).courseContent
//     }
//     this.setState({responseData:listData})
//   }
//   }else{
//         Cookies.set("isOnline",true)
//        // this.setState({isOffline:true})
//           //@ts-ignore
//     this.props.history.push(`/courses/${this.props?.match?.params?.id}`) 
//   }
//     window.addEventListener("offline", ()=>{
//       Cookies.set("isOnline",false)
//       this.setState({isOffline:false})
//       const coursesArray = JSON.parse(localStorage.getItem("allCourses") as any||"[]");
//       if(coursesArray?.length !==0){
//         coursesArray?.forEach((courseLocalStorage:any) => {
//           //@ts-ignore
//           if(courseLocalStorage.id ==this.props?.match?.params?.id){
//             this.setState({courseDetail:courseLocalStorage})
//           }
//         }) 
//       }
      
//       //@ts-ignore
//      if (this.props?.match?.params?.id) {
//       //@ts-ignore
//       let data=JSON.parse( localStorage.getItem("courseDataArray") as any)
//       let listData;
//       if(data){
        
//         //@ts-ignore
//         listData= data?.find((item:any)=>{
//           //@ts-ignore
//          return  this.props?.match?.params?.id==item.id  
//          }).courseContent
//       }
//       this.setState({responseData:listData})
//     }

//     });
//     window.addEventListener('online', (event) => {
      
//         Cookies.set("isOnline",true)
//         this.setState({isOffline:true})
//           //@ts-ignore
//     this.props.history.push(`/courses/${this.props?.match?.params?.id}`) 
//   });
//   }
// }
}
// Customizable Area End