// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  // Customizable Area End
} from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

// @ts-ignore
import AppLogo from "./AppLogo.svg";
import "./HeaderApp.scss";
//@ts-ignore
const Header: React.FC<RouteComponentProps> = ({ history }:any) => {
  return (
    // Customizable Area Start
    <AppBar position="static" className="mvp-app-header">
      <IconButton
        edge="start"
        style={{
          cursor: "pointer",
        }}
        aria-label="menu"
        onClick={() => history.push("/")}
      >
        <img src={AppLogo} alt="logo" className="mvp-logo-app" />
      </IconButton>
    </AppBar>
    // Customizable Area End
  );
};

const webStyle = {
};

//@ts-ignore
const headerWithRouter = withRouter(Header);
export default withStyles(webStyle)(headerWithRouter);
// Customizable Area End
