// Customizable Area Start
import React from "react";
import { AppBar, Grid } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
// @ts-ignore
import mobile from "./mobile.svg";
// @ts-ignore
import play from "./play.svg";
// @ts-ignore
import apple from "./apple.svg";
import "./GetTheApp.scss";

const GetTheApp: React.FC<RouteComponentProps> = ({ history }) => {
  // Customizable Area Start
  // Customizable Area End

  return (
    // Customizable Area Start
    <div className="get-the-app-container">
      <div className="get-app-grid-item-container1">
        <img src={mobile} className="get-the-app-sideimg" />
      </div>
      <div className="get-app-grid-item-container">
        <div className="get-app-grid-right-item-container">
          <div>
            <p className="sideText get-app-grid-right-item-container-title">
              Get the App
            </p>
          </div>
          <div className="get-app-grid-right-item-sub-container">
            <img
              className="get-app-grid-left-img"
              src={play}
              style={{cursor:'pointer'}}
              onClick={()=>{
                //@ts-ignore
                window.location='https://play.google.com/store/apps/details?id=com.HippocketTraining10'
              }}
            />
            <img src={apple} style={{cursor:'pointer'}} className="get-app-grid-right-img" 
            onClick={()=>{
              //@ts-ignore
              window.location='https://apps.apple.com/in/app/hippocket/id1644898209'
            }}/>
          </div>
          <div className="get-app-grid-right-container">
            <div className="get-app-grid-left-container">
              <p
                className="sideText get-app-grid-left-container-title"
              >
                Or Continue using website
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    // Customizable Area End
  );
};

const webStyle = {} as any;

//@ts-ignore
const footerWithRouter = withRouter(GetTheApp);
export default withStyles(webStyle)(footerWithRouter);
// Customizable Area End
