// Customizable Area Start
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card,CardActionArea, CardMedia} from "@material-ui/core";
import { Link } from 'react-router-dom';
const useStyles = makeStyles((theme)=>({
  media: {
    objectFit:"contain",
    backgroundSize:"contain",
    height: 300,
    [theme.breakpoints.down('sm')]: {
      height: 150, // Adjust height for smaller screens
    },
  },
  mediaItemOne: {
    objectFit:"contain",
    backgroundSize:"contain",
    height: 100,
    [theme.breakpoints.down('sm')]: {
      height: 100, // Adjust height for smaller screens
    },
  },
}));
interface Props {
  // Customizable Area Start
  banner: string;
  itemCover:number;
  // Customizable Area End
}
const ContactUsAds: React.FC<Props> = ({banner,itemCover})=> {
  const classes = useStyles();

  return (
    <Link to={"/Contact"}>
    <Card>
      <CardActionArea>
        <CardMedia
          className={itemCover===1 ?classes.mediaItemOne :classes.media}
          image={banner}
          title="contact us"
        />
      </CardActionArea>
    </Card>
    </Link>
  );
}

export default ContactUsAds
// Customizable Area End
