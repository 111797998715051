import React, { useState, useEffect } from "react";
import { Button, Grid, Box } from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CustomTextField from "./CustomTextField.web";
import FormPopUpOfFillableForm from "./FormPopUpOfFillableForm.web";
//@ts-ignore
import AddBtn from "./add-btn.svg";
import "./FillableForms.scss";
import "./MemoForm.scss";

interface Props {
  doSubmitFillableForm: any;
  BackToMainPage: any;
}

export default function MemoForm(props: Props) {
  const [dynamicFieldsValues, setDynamicFieldsValues] = useState<any>({});
  const [dynamicFieldsValidations, setDynamicFieldsValidations] = useState<any>(
    {}
  );
  const [modalOpen, setmodalOpen] = useState<boolean>(false);
  const [formValues, setformValues] = useState<any>("");
  const [dynamicFields, setDynamicFields] = useState([
    {
      number: 1,
      value: "",
    },
    {
      number: 2,
      value: "",
    },
  ]);
  const handleModalOpen = () => {
    setmodalOpen(false);
  };

  useEffect(() => {
    const values = {};
    const validation = {};
    dynamicFields.forEach((element: any, index) => {
      //@ts-ignore
      values[element.number] = "";
      //@ts-ignore
      validation[element.number] = Yup.string()
        .required(`Please fill this field`)
        .min(2, "field length should be at least 2 char.");
    });
    setDynamicFieldsValues(values);
    setDynamicFieldsValidations(validation);
  }, [dynamicFields]);

  const resetDynamicFields = ( ) => {
    setDynamicFields([
      {
        number: 1,
        value: "",
      },
      {
        number: 2,
        value: "",
      },
    ])
  }

  return (
    <>
      <Formik
        initialValues={{
          organizationName: "",
          memorandum: "",
          streetAddress: "",
          city: "",
          state: "",
          zipCode: "",
          officeSymbol: "",
          date: "",
          subject: "",
          signatureBlockname: "",
          rankOrBranch: "",
          rankTitle: "",
          1: "",
          2:"",
          3:"",
          4:"",
        }}
        validationSchema={Yup.object().shape({
          organizationName: Yup.string()
            .required("Organization Name is required")
            .min(3, "Organization Name should be  at least 3 char."),
          memorandum: Yup.string()
            .required("Memorandum Name is required")
            .min(3, "Memorandum Name should be at least 3 char."),
          streetAddress: Yup.string()
            .required("Street Address is required")
            .min(3, "Street Address should be  at least 3 char."),
          city: Yup.string()
            .required("City Name is required")
            .min(3, "City Name should be at least 3 char."),
          state: Yup.string()
            .required("State Name is required")
            .min(3, "State Name should be at least 3 char."),
          zipCode: Yup.number()
            .required("Zip code is required")
            .min(3, "Zip code should be  at least 3 char."),
          officeSymbol: Yup.string()
            .required("Office Symbol is required")
            .min(3, "Office Symbol should be  at least 3 char."),
          date: Yup.string().required("Date is required"),
          subject: Yup.string()
            .required("Subject is required")
            .min(3, "Subject should be  at least 3 char."),
          signatureBlockname: Yup.string()
            .required("Signature Blockname is required")
            .min(3, "Signature Blockname should be  at least 3 char."),
          rankOrBranch: Yup.string()
            .required("Rank is required")
            .min(3, "Rank should be  at least 3 char."),
          rankTitle: Yup.string()
            .required("Rank Title is required")
            .min(3, "Rank Title should be at least 3 char."),
          1: Yup.string()
            .required(`Please fill this field`)
            .min(3, "Field should be at least 3 char."),
          2: Yup.string()
            .required(`Please fill this field`)
            .min(3, "Field should be at least 3 char."),
          3: Yup.string()
            .required(`Please fill this field`)
            .min(3, "Field should be at least 3 char."),
            4: Yup.string()
            .required(`Please fill this field`)
            .min(3, "Field should be at least 3 char."),
        })}
        onSubmit={(values, { resetForm }): any => {
          setformValues([values, resetForm, "MEMO_FORM", dynamicFields]);
          setmodalOpen(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          handleBlur,
          setFieldValue,
        }) => (
          //@ts-ignore
          <Form>
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                className="memoGrid"
              >
                <Grid item xs={12} sm={4} lg={4} xl={2} className="fillableForm-Grid">
                  <CustomTextField
                    type="string"
                    required={true}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.organizationName}
                    touched={touched}
                    error={touched.organizationName && errors.organizationName}
                    multiline={true}
                    countCharNum={true}
                    label="Organization Name/Title"
                    placeholder="Ex. US ARMY HUMAN RESOURCES COMMAND *"
                    name="organizationName"
                    classname="multiline-textfield"
                  />
                </Grid>
                <Grid
                  item
                  xs={12} sm={4} lg={4} xl={2}
                  className="fillableForm-Grid"
                  style={{ paddingRight: "20px" }}
                >
                  <CustomTextField
                    type="string"
                    required={true}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    //@ts-ignore
                    value={values.streetAddress}
                    touched={touched}
                    error={touched.streetAddress && errors.streetAddress}
                    multiline={true}
                    countCharNum={true}
                    label="Street Address"
                    placeholder="Address *"
                    name="streetAddress"
                    classname="multiline-textfield"
                  />
                </Grid>
                <Grid
                  item
                  xs={12} sm={4} lg={4} xl={2}
                  className="fillableForm-Grid"
                  style={{ paddingRight: "42px" }}
                >
                  <CustomTextField
                    type="string"
                    required={true}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.memorandum}
                    touched={touched}
                    error={touched.memorandum && errors.memorandum}
                    multiline={true}
                    countCharNum={true}
                    label="Memorandum For"
                    placeholder="Ex. Commander, Headquaters and Headquaters Company *"
                    name="memorandum"
                    classname="multiline-textfield"
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4} xl={2} className="fillableForm-Grid">
                  <CustomTextField
                    type="string"
                    required={true}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.city}
                    touched={touched}
                    error={touched.city && errors.city}
                    countCharNum={false}
                    multiline={false}
                    label="City"
                    placeholder="City *"
                    name="city"
                  />
                </Grid>
                <Grid
                  item
                  xs={12} sm={4} lg={4} xl={2}
                  className="fillableForm-Grid"
                  style={{ paddingRight: "20px" }}
                >
                  <CustomTextField
                    type="string"
                    required={true}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.state}
                    touched={touched}
                    error={touched.state && errors.state}
                    countCharNum={false}
                    multiline={false}
                    label="State"
                    placeholder="State *"
                    name="state"
                  />
                </Grid>
                <Grid
                  item
                  xs={12} sm={4} lg={4} xl={2}
                  className="fillableForm-Grid"
                  style={{ paddingRight: "42px" }}
                >
                  <CustomTextField
                    type="number"
                    required={true}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    //@ts-ignore
                    value={values.zipCode}
                    touched={touched}
                    error={touched.zipCode && errors.zipCode}
                    countCharNum={false}
                    multiline={false}
                    label="Zip Code"
                    placeholder="Ex. 12345-1234 *"
                    name="zipCode"
                  />
                </Grid>
                <Grid item xs={12} sm={4} lg={4} xl={2} className="fillableForm-Grid">
                  <CustomTextField
                    type="string"
                    required={true}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    //@ts-ignore
                    value={values.officeSymbol}
                    touched={touched}
                    error={touched.officeSymbol && errors.officeSymbol}
                    countCharNum={false}
                    multiline={false}
                    label="Office Symbol"
                    placeholder={"ARHC-PDO-PA *"}
                    name="officeSymbol"
                  />
                </Grid>
                <Grid
                  item
                  xs={12} sm={4} lg={4} xl={2}
                  className="fillableForm-Grid"
                  style={{ paddingRight: "20px" }}
                >
                  <CustomTextField
                    type={"string"}
                    required={true}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    //@ts-ignore
                    value={values.date}
                    touched={touched}
                    error={touched.date && errors.date}
                    multiline={false}
                    label="Date"
                    placeholder="DD/MM/YYYY *"
                    name="date"
                  />
                </Grid>
                <Grid
                  item
                  xs={12} sm={4} lg={4} xl={2}
                  className="fillableForm-Grid"
                  style={{ paddingRight: "42px" }}
                >
                  <CustomTextField
                    type="string"
                    required={true}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    //@ts-ignore
                    value={values.subject}
                    touched={touched}
                    error={touched.subject && errors.subject}
                    countCharNum={false}
                    multiline={false}
                    label="Subject"
                    placeholder={"Army Superior Unit Award (ASUA) - PERMANENT ORDERS*"}
                    name="subject"
                  />
                </Grid>
              </Grid>
              <div
                className="add-field-title-container"
                style={{ width: "88%", margin: "auto" }}
              >
                <div className="add-field-title-sub-container">
                  <span className="title">Add Fields</span>
                  <span
                    onClick={() => {
                      setDynamicFields([
                        ...dynamicFields,
                        {
                          number:
                            dynamicFields[dynamicFields.length - 1].number + 1,
                          value: "",
                        },
                      ]);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img src={AddBtn} alt="Add" />
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  marginLeft: "26px",
                  width: "100%",
                }}
              >
                {dynamicFields.map((item, index) => (
                  <Grid item xs={12} sm={4} lg={4} xl={2} className="fillableForm-Grid">
                    <CustomTextField
                      type="string"
                      required={true}
                      handleChange={(e: any) => {
                        setDynamicFields(
                          dynamicFields.map((x) => {
                            if (x.number === item.number) {
                              return { ...x, value: e.target.value };
                            } else {
                              return x;
                            }
                          })
                        );
                        // values[e.target.name] = e.target.value;
                        handleChange(e);
                      }}
                      handleBlur={handleBlur}
                      value={dynamicFields.find((x) => x.number === item.number)?.value}
                      // value={values[item.number]}
                      touched={touched}
                      error={item.number===1 ? touched["1"] && errors["1"] :item.number===2 ? touched["2"] && errors["2"] :item.number===3 ? touched["3"] && errors["3"] :item.number===4 ? touched["4"] && errors["4"]:""}
                      // error={item.number ===1 ? errors["1"] : ""}
                      placeholder={
                        index === 0
                          ? "Message *"
                          : index === 1 ? "The POC for this memorandum is the undersigned at..." : "Ex. John Brick"
                      }
                      multiline={true}
                      classname="addFields-textarea"
                      countCharNum={true}
                      name={item.number}
                      showCross={true}
                      showNumber={true}
                      number={index + 1}
                      onCrossClick={() => {
                        setDynamicFields(
                          dynamicFields.filter((x) => x.number !== item.number)
                        );
                      }}
                    />
                  </Grid>
                ))}
              </div>
              <Grid container  spacing={0}>
                <Grid item xs={12} sm={4} lg={4} xl={2} className="fillableForm-Grid">
                  <CustomTextField
                    type="string"
                    required={true}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    //@ts-ignore
                    value={values.signatureBlockname}
                    touched={touched}
                    error={touched.signatureBlockname && errors.signatureBlockname}
                    countCharNum={false}
                    multiline={false}
                    label="Signature Block Name"
                    placeholder="Ex. John Brick *"
                    name="signatureBlockname"
                  />
                </Grid>
                <Grid
                  item
                  xs={12} sm={4} lg={4} xl={2}
                  className="fillableForm-Grid"
                  style={{ paddingRight: "20px" }}
                >
                  <CustomTextField
                    type="string"
                    required={true}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    //@ts-ignore
                    value={values.rankOrBranch}
                    touched={touched}
                    error={touched.rankOrBranch && errors.rankOrBranch}
                    countCharNum={false}
                    multiline={false}
                    label="Rank, Branch"
                    placeholder="Ex. LTC, AG *"
                    name="rankOrBranch"
                  />
                </Grid>
                <Grid
                  item
                  xs={12} sm={4} lg={4} xl={2}
                  className="fillableForm-Grid"
                  style={{ paddingRight: "26px" }}
                >
                  <CustomTextField
                    type="string"
                    required={true}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    //@ts-ignore
                    value={values.rankTitle}
                    touched={touched}
                    error={touched.rankTitle && errors.rankTitle}
                    countCharNum={false}
                    multiline={false}
                    label="Title"
                    placeholder="Ex. Chief, Military Awards Branch *"
                    name="rankTitle"
                  />
                </Grid>
              </Grid>
            </Box>
            <div className="courseAssesment-btn-container-ext">
              <Button
                onClick={(e) => {
                  handleSubmit();
                }}
                variant="contained"
                className="courseAssesment-btn"
                type="submit"
              >
                CONTINUE
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <FormPopUpOfFillableForm
        //@ts-ignore
        handleModalOpen={handleModalOpen}
        doSubmitFillableForm={props.doSubmitFillableForm}
        formValues={formValues}
        openModal={modalOpen}
        dynamicFields={dynamicFields}
        resetDynamicFields={resetDynamicFields}
      />
    </>
  );
}
