import React from 'react';
import GoogleAdsenseIntegration from '../../blocks/GoogleAdsenseIntegration/src/GoogleAdsenseIntegration';
import ContactUsAds from './ContactUsAds';
import { makeStyles } from '@material-ui/core/styles';
import { Card,CardActionArea, CardMedia} from "@material-ui/core";
import Link from '@material-ui/core/Link';
import { CustomAdsCounts } from './CustomAdsCounts';
export const configJSON = require("../../../packages/framework/src/config");
const useStyles = makeStyles((theme)=>({

  media: {
    objectFit:"contain",
    backgroundSize:"contain",
    height: 300,
    [theme.breakpoints.down('sm')]: {
      height: 150, // Adjust height for smaller screens
    },
  },
  mediaItemOne: {
    objectFit:"contain",
    backgroundSize:"contain",
    height: 100,
    [theme.breakpoints.down('sm')]: {
      height: 100, // Adjust height for smaller screens
    },
  },
}));
interface AdSolutionProps {
  adsList: any[]; 
  customAdIdList: any[];
  contactUsAdsBanner:any[];
  itemCoveredProduct:number;
  flag:string;
}
const AdsSolution: React.FC<AdSolutionProps> = ({adsList,customAdIdList,contactUsAdsBanner,flag,itemCoveredProduct}) => {
  
  const classes = useStyles();
  
  const handleClick = (e:any,poppedIndex:any) => {
    e.preventDefault();
    
    window.open(poppedIndex.url, '_blank');
    CustomAdsCounts(poppedIndex.id);
  };
  const handleContextMenu = (event:any) => {
    event.preventDefault();

    
  };
  const popAddType = adsList[0];
  adsList.reverse();
  adsList.pop();
  adsList.reverse();
  adsList.push(popAddType);

  if (popAddType === "customAds") {
    const poppedIndex = customAdIdList[0];
    customAdIdList.reverse();
    customAdIdList.pop();
    customAdIdList.reverse();
    customAdIdList.push(poppedIndex);

    return <div>{
     <Link onClick={(e:any)=>handleClick(e,poppedIndex)} onContextMenu={handleContextMenu} target="_blank" href={poppedIndex.url} rel="noreferrer">
       <Card >
      <CardActionArea>
        <CardMedia
          className={itemCoveredProduct===1 ?classes.mediaItemOne :classes.media}
          image={itemCoveredProduct===2? poppedIndex.web_small_custom_image : itemCoveredProduct===1? poppedIndex.web_single_course_image:poppedIndex.web_large_custom_image}
          title={poppedIndex.name}
        />
      </CardActionArea>
    </Card>  
    </Link>
  
     }</div>  
  } else if (popAddType === "googleAds") {
    return <GoogleAdsenseIntegration navigation={undefined} id={""} />;
  } else if (popAddType === "contactUsAds") {
   return <ContactUsAds banner={itemCoveredProduct===2?contactUsAdsBanner[0].web_small_contact_us_image:itemCoveredProduct===1? contactUsAdsBanner[0].web_single_course_image :contactUsAdsBanner[0].web_large_contact_us_image} itemCover={itemCoveredProduct}/>;
  }
  return null;
};

export default AdsSolution;
