// Customizable Area Start
import React from "react";
// Customizable Area Start
import { Button } from "@material-ui/core";
// Customizable Area End
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
// Customizable Area Start
import {
  Fifth_image,
  Second_image,
  Third_image,
  Fourth_image,
  First_image,
  Sixth_image,
} from "./assets";
import Footer from "../../../components/src/Footer.web";
import "../assets/SplashScreen.scss";
import HomeController, { Props } from "./HomeController";
import HeaderAppWeb from "../../../components/src/HeaderApp.web";
import GetTheAppWeb from "../../../components/src/GetTheApp.web";
import SearchInput from "../../../components/src/SearchInput.web";
import CircularProgress from "@material-ui/core/CircularProgress";
//@ts-ignore
import { setLoaderOnScreen } from "../../../web/src/action.js"
// Customizable Area End

class Home extends HomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
        }}
        className="main-home-container"
      >
        <HeaderAppWeb />
        {this.props.appState.appReducer.isLoading?(
         <div className="loader-class-course" style={{minHeight:'70vh',margin:'auto'}}>
         <CircularProgress />
         </div>
         ):(
        <>
        <div>
          <div
            className="container1"
            style={{ height: "auto", overflowX: "hidden" }}
          >
            <img src={First_image} style={{ width: "100vw", height: "auto" }} />
            <div className="centered">
              <p className="title">
                Stay Proficient <br /> and Master Your Craft
              </p>
              <br />
              <p className="subTitle">
              Select opportunity training based on organization,
              <br />time available, space available, and more
              </p>
              <br />
              <p className="subTitle2">
                Hip Pocket Training: <u>Opportunity training</u> consisting
                <br />
                of brief, informal periods of instruction employed by
                <br />
                small unit leaders to take advantage of any
                <br />
                unscheduled time between assignments
                <br />
                or other tasks.
              </p>
              <br />
              {/* {this.state.isOffline && (
              <div
              >
                <SearchInput />
              </div>
            )} */}
            <br />
            {/* <div
              className="explore-btn-container"
              onClick={() => {
                this.props.history.push("/courses");
              }}
            >
              <Button
                variant="contained"
                style={{
                  backgroundColor: "rgb(12, 18, 12)",
                  color: "white",
                  width: "17.1875vw",
                  height: "2.734375vw",
                  fontSize: "1.5vw",
                }}
              >
                EXPLORE
              </Button>
            </div> */}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p
              style={{
                //@ts-ignore
                fontWeight: "500",
                fontSize: "4.55vw",
                display: "inline",
                cursor: "pointer",
              }}
              onClick={() => {
                //@ts-ignore
                this.props.history.push("/Howitworks");
              }}
              id="HowItWorks"
            >
              How It Works
            </p>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <div style={{ position: "relative" }}>
              <img
                src={Third_image}
                style={{ width: "50vw", height: "auto" }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "15%",
                  left: "13%",
                }}
              >
                <p
                  style={{
                    fontSize: "3.58vw",
                    color: "white",
                    display: "inline",
                    marginRight: "4px",
                  }}
                >
                  Home Page
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "50vw",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="sideText">
                Scroll through or search
                <br />
                for any topic you can think of,
                <br />
                no signup necessary.
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "50vw",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p className="sideText">
                Filter via time available,
                <br />
                audience if you’re giving
                <br />
                the class, and more.
              </p>
            </div>
            <div style={{ position: "relative" }}>
              <img
                src={Fifth_image}
                style={{ width: "50vw", height: "auto" }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "15%",
                  left: "23%",
                }}
              >
                <span>
                  <p
                    style={{
                      fontSize: "3.58vw",
                      color: "white",
                      padding: "0px",
                      margin: "0px",
                    }}
                  >
                    Filter
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ position: "relative" }}>
            <img src={Sixth_image} style={{ width: "50vw", height: "auto" }} />
            <div
              style={{
                position: "absolute",
                top: "12%",
                left: "16%",
              }}
            >
              <p
                style={{
                  fontSize: "3.58vw",
                  color: "white",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                Course
              </p>
              <p
                style={{
                  color: "white",
                  fontSize: "3.58vw",
                  display: "inline",
                }}
              >
                Overview
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "50vw",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="sideText">
              Scroll through the course
              <br />
              at your own pace.
              <br />
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "50vw",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="responsive-padding sideText">
              Here you can find training supplies directly related to that
              topic,
              {/* <br />
              <div style={{ height: "10px" }} /> */}
              &nbsp;related courses to broaden your base of knowledge in that
              specific area,
              {/* <br />
              <div style={{ height: "10px" }} /> */}
              &nbsp;references to the regulations (if applicable), Pro-Tips for
              other ways to accomplish the task,
              {/* <br />
              <div style={{ height: "10px" }} /> */}
              &nbsp;an after-action-review form so you can critique the training
              if needed,
              {/* <br />
              <div style={{ height: "10px" }} /> */}
              &nbsp;and the option to download it in case you need to pull it up
              again without a signal.
              <br />
            </p>
          </div>
          <div style={{ position: "relative" }}>
            <img src={Second_image} style={{ width: "50vw", height: "auto" }} />
            <div
              style={{
                position: "absolute",
                top: "9%",
                left: "20%",
              }}
            >
              <span>
                <p
                  style={{
                    color: "white",
                    fontSize: "3.58vw",
                    padding: "0px",
                    margin: "0px",
                  }}
                >
                  Course
                </p>
                <p
                  style={{
                    color: "white",
                    fontSize: "3.58vw",
                    margin: "0px",
                    padding: "0px",
                  }}
                >
                  Supplies
                </p>
              </span>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <div style={{ position: "relative" }}>
            <img src={Fourth_image} style={{ width: "50vw", height: "auto" }} />
            <div
              style={{
                position: "absolute",
                top: "15%",
                left: "10%",
              }}
            >
              <p
                style={{
                  fontSize: "3.58vw",
                  color: "white",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                More Section
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              width: "50vw",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p className="sideText">
              Here you can find fillable forms that you can email out directly
              from the app (AAR’s, Auto-Format Memo’s, Counselings, and more to
              come!) Additionally,
              {/* <br />
              <div style={{ height: "10px"}} /> */}
              &nbsp;you can shoot us a message letting us know what we should
              add next!
              <br />
            </p>
          </div>
        </div>
        <GetTheAppWeb />
        </>
        )}
        <Footer />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {};
// Customizable Area End

const mapStateToProps = (state: any) => {
  return {
    isOnline: state.HipPocketFireActions.isOnline || false,
    appState:state
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    setLoading: (data: any) => {
      dispatch(setLoaderOnScreen(data));
    },
  };
};
//@ts-ignore
const HomeWithRouter = withRouter(
  //@ts-ignore
  connect(
    mapStateToProps,
    null
  )(Home)
);
export default withStyles(webStyle)(HomeWithRouter);
// Customizable Area End
