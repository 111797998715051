import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  NotificationManager,
  //@ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { toast } from "react-toastify";
//@ts-ignore
import Cookies from "js-cookie";
export const configJSON = require("./config");
import "react-toastify/dist/ReactToastify.css";
toast.configure();
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  tabValue: number;
  courseData: any;
  isLoading: boolean;
  relatedCourseLoading: boolean;
  relatedProductsLoading: boolean;
  openModal: boolean;
  staticFields: any[];
  assesmentQuestions: any[];
  relatedCoursesTags: string[];
  relatedCourses: any[];
  relatedProducts: any[];
  currentPath: string;
  isOffline: any;
  offlineCourseContent: any;
  shouldResetForm: boolean;
  courseId: number | string;
  tabNames: any;
  pageNumber: number;
  totalPages: number;
  adsData: [{
    ad_type:string,
    custom_ad?:{
      id:number;
        name:string;
        url:string;
        web_large_custom_image:string;
    },
    contact_us_ad?:{
        name:string;
        web_large_contact_us_image:string;
    },
  }];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CourseDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCourseDataApiCallId: any;
  getRelatedCourseDataApiCallId: any;
  getRelatedProductsDataApiCallId: any;
  handleAssesmentFormApiCallId: any;
  updateAffiliateCountsApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      adsData:[{
        ad_type:"",
        custom_ad:{
            id:0,
            name:"",
            url:"",
            web_large_custom_image:"",
        },
        contact_us_ad:{
            name:"",
            web_large_contact_us_image:"",
        },
      }],
      relatedCourses: [],
      relatedProducts: [],
      relatedCoursesTags: [],
      assesmentQuestions: [],
      currentPath: window.location.pathname,
      tabValue: 0,
      courseData: null,
      isLoading: true,
      relatedCourseLoading: true,
      relatedProductsLoading: true,
      openModal: false,
      shouldResetForm: false,
      courseId: "",
      staticFields: [
        {
          fieldName: "TrainingName",
          label: "Training / Mission Name",
          placeholder: "Training / Mission Name*",
          multiline: false,
          type: "string",
          required: true,
        },
        {
          fieldName: "InstructorName",
          label: "Instructor/Lead Name(s)",
          placeholder: "Instructor/Lead Name(s)*",
          multiline: false,
          type: "string",
          required: true,
        },
        {
          fieldName: "Dateoftraining",
          label: "Date of training/AAR",
          placeholder: "DD/MM/YYYY",
          multiline: false,
          type: "date",
          required: false,
        },
      ],
      isOffline: "",
      offlineCourseContent: "",
      tabNames: "Course",
      pageNumber: 1,
      totalPages: 0
    };
    // Customizable Area Startg
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (errorReponse) {
        NotificationManager.error("", errorReponse);
      }
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (
            apiRequestCallId === this.getCourseDataApiCallId &&
            responseJson !== undefined
          ) {
            this.setState({
              adsData:responseJson.ads,
              courseData: responseJson.course,
              isLoading: false,
              assesmentQuestions: responseJson.questions,
              relatedCoursesTags: responseJson.course.tag_list,
              courseId: responseJson.course.id,
            });
            if (responseJson.course.id) {
              this.getRelatedCourseData(responseJson.course.id);
              this.getRelatedProductsData(responseJson.course.id);
            }
          }
          if (apiRequestCallId === this.handleAssesmentFormApiCallId) {
            toast.success(configJSON.formSuccess, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          if (apiRequestCallId === this.getRelatedCourseDataApiCallId) {
            responseJson &&
              responseJson?.related_courses &&
              this.setState({
                relatedCourses: responseJson.related_courses.filter(
                  (item: any) => !item?.ad_code
                ),
              });
            this.setState({
              relatedCourseLoading: false,
            });
          }
          if (apiRequestCallId === this.getRelatedProductsDataApiCallId) {
            responseJson &&
              responseJson?.products?.data &&
              this.setState({
                relatedProducts: responseJson?.products?.data,
                relatedProductsLoading: false,
              });
          }
        }
      } else {
        this.setState({ isLoading: false, relatedCourseLoading: false, relatedProductsLoading: false });
        // this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
        // toast.error("Error occurred.",{position: toast.POSITION.BOTTOM_RIGHT})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    document.getElementById('courseDetails-Container')?.scrollIntoView();
    if (!window.navigator.onLine) {
      Cookies.set("isOnline", false);
      this.setState({ isOffline: false });

      let dataOfCourses = JSON.parse(
        (localStorage.getItem("courseDataArray") as any) || "[]"
      );
      const coursesArray = JSON.parse(
        (localStorage.getItem("allCourses") as any) || "[]"
      );
      if (dataOfCourses?.length !== 0) {
        dataOfCourses?.forEach((contentLocalStorage: any) => {
          //@ts-ignore
          if (contentLocalStorage.id == this.props.navigation.getParam("id")) {
            this.setState({
              offlineCourseContent: contentLocalStorage.courseContent,
            });
          }
        });
      }
      if (coursesArray?.length !== 0) {
        coursesArray?.forEach((courseLocalStorage: any) => {
          //@ts-ignore
          if (courseLocalStorage.id == this.props.navigation.getParam("id")) {
            this.setState({ courseData: courseLocalStorage });
          }
        });
      }
    } else {
      Cookies.set("isOnline", true);
      this.setState({ isOffline: true });
      //@ts-ignore
      if (this.props.navigation.getParam("id")) {
        //@ts-ignore
        this.getCourseData(this.props.navigation.getParam("id"));
      }
    }
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (window.navigator.onLine) {
      if (prevState.isOffline !== this.state.isOffline) {
        Cookies.set("isOnline", true);
        this.setState({ isOffline: true });
      }

      if (prevState.tabValue !== this.state.tabValue) {
        if (this.state.tabValue === 1) {
          this.getRelatedCourseData(this.state.courseId);
        }
      }
    } else {
      if (prevState.isOffline !== this.state.isOffline) {
        Cookies.set("isOnline", false);
        this.setState({ isOffline: false });

        const coursesArray = JSON.parse(
          (localStorage.getItem("allCourses") as any) || "[]"
        );
        if (coursesArray?.length !== 0) {
          coursesArray?.forEach((courseLocalStorage: any) => {
            //@ts-ignore
            if (courseLocalStorage.id == this.props.navigation.getParam("id")) {
              this.setState({
                courseData: courseLocalStorage,
                isLoading: false,
              });
            }
          });
        }
      }
    }
    window.addEventListener("offline", () => {
      Cookies.set("isOnline", false);
      this.setState({ isOffline: false });
      const coursesArray = JSON.parse(
        (localStorage.getItem("allCourses") as any) || "[]"
      );
      if (coursesArray?.length !== 0) {
        coursesArray?.forEach((courseLocalStorage: any) => {
          //@ts-ignore
          if (courseLocalStorage.id == this.props.navigation.getParam("id")) {
            this.setState({ courseData: courseLocalStorage });
          }
        });
      }
    });
    window.addEventListener("online", (event) => {
      Cookies.set("isOnline", true);
      this.setState({ isOffline: true });
      //@ts-ignore
      if (this.props.navigation.getParam("id")) {
        //@ts-ignore
        this.getCourseData(this.props.navigation.getParam("id"));
      }
      //@ts-ignore
      if (prevState.tabValue !== this.state.tabValue) {
        if (this.state.tabValue === 1) {
          this.getRelatedCourseData(this.state.courseId);
        }
      }
    });
  }

  setShouldResetForm = (opt: boolean) => {
    this.setState({
      shouldResetForm: opt,
    });
  };

  setTabValue = (value: number) => {
    this.setState({
      tabValue: value,
    });
    return true;
  };
  setTabName = (name: any) => {
    this.setState({
      tabNames: name,
    });
    return true;
  };
  setAssesmentFormData = (data: any[]) => {
    this.setState({
      assesmentQuestions: data,
    });
    return true;
  };

  handleModalOpen = (value: boolean) => {
    this.setState({
      openModal: value,
    });
    return true;
  };

  updateAffiliateCounts = (): boolean => {
    const header = {
      "Content-Type": configJSON.affiliatesCountApiContentType,
    };

    const requestBody = {
      "affiliate_click": "true"
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    this.updateAffiliateCountsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.affiliatesCountApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.affiliatesCountApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  
  getCourseData = (id: number): boolean => {
    const header = {
      "Content-Type": configJSON.getCourseDataApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCourseDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCourseDataAPiEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCourseDataAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getRelatedCourseData = (id: number | string): boolean => {
    const header = {
      "Content-Type": configJSON.getRelatedCourseDataApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRelatedCourseDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRelatedCourseDataAPiEndPoint}/${id}/related_courses`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getRelatedCourseDataAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleAssesmentFormData = (data: any[]): boolean => {
    const header = {
      "Content-Type": configJSON.postAssesmentFormApiContentType,
    };

    const httpBody = { answers: data };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.handleAssesmentFormApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postAssesmentFormAPiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAssesmentFormAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getRelatedProductsData = (id: number | string): boolean => {
    const header = {
      "Content-Type": configJSON.getRelatedProductsDataApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRelatedProductsDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRelatedProductsDataAPiEndPoint}?id=${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getRelatedProductsDataAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
