import { GET_ALL_PRODUCTS, GET_ALL_COURSES, SET_FILTER_BY_NAME, SET_VALUE_BY_NAME,  RESET_FILTER_STATE,IS_LOADING} from "./constant";

export const getAllCoursesAction = (data) => ({
    type: GET_ALL_COURSES,
    data
})
export const getAllProductsAction = (data) => ({
    type: GET_ALL_PRODUCTS,
    data
})
export const setFilterFieldByName = (data) => ({
    type: SET_FILTER_BY_NAME,
    data
})
export const setFilterValueByName = (data) => ({
    type: SET_VALUE_BY_NAME,
    data
})
export const resetFilterReduxState = (data) => ({
    type: RESET_FILTER_STATE,
    data
})
export const setLoaderOnScreen = (data) => ({
    type: IS_LOADING,
    data
})