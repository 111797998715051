export const COURSE_REQUIRED = "Please Enter Your Name";
export const INSTRUCTOR_REQUIRED = "Please Enter Instructor Name";

export const NAME_REQUIRED = "Please Enter Name";
export const EMAIL_REQUIRED = "Please Enter Email";
export const DESTINATION_EMAIL_REQUIRED = "Please Enter Destination Email";
export const NAME_REGX = /^[a-zA-Z ]*$/;
export const NOT_VALID_NAME = "Only Letters Are Allowed";
export const NOT_VALID_EMAIL = "Please Enter Valid Email";
export const EMAIL_REGX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
