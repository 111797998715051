import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  NotificationContainer,
  NotificationManager,
  //@ts-ignore
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { toast } from "react-toastify";
//@ts-ignore
import { debounce } from "lodash";
//@ts-ignore
import Cookies from "js-cookie";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  responseData: any;
  isOffline: any;
  currentForm: any;
  openModal: any;
  shouldResetForm: any;
  staticFields: any;
  assesmentQuestions: any;
  requireStaticField: any;
  // Customizable Area End
}

interface SS {
  id: any;
}
// Customizable Area Start
export default class FillableFormsTabWebControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  submitApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.submitApiCallId = "";
    this.state = {
      responseData: [],
      isOffline: "",
      currentForm: "",
      openModal: false,
      shouldResetForm: false,
      staticFields: [
        {
          fieldName: "TrainingName",
          label: "Training / Mission Name",
          placeholder: "Training / Mission Name*",
          multiline: false,
          type: "string",
          required: true,
        },
        {
          fieldName: "InstructorName",
          label: "Instructor/Lead Name(s)",
          placeholder: "Instructor/Lead Name(s)*",
          multiline: false,
          type: "string",
          required: true,
        },
        {
          fieldName: "Dateoftraining",
          label: "Date of training/AAR",
          placeholder: "DD/MM/YYYY",
          multiline: false,
          type: "date",
          required: false,
        },
      ],
      assesmentQuestions: [
        { id: 1, question: "What did we expect to happen?" },
        { id: 2, question: "What actually occurred?" },
        { id: 3, question: "What went well and why?" },
        { id: 4, question: "What can be improved upon and how?" },
      ],
      requireStaticField: "requireStaticField",
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    if (!window.navigator.onLine) {
      Cookies.set("isOnline", false);
      this.setState({ isOffline: false });
      this.props.navigation.navigate("Home");
    } else {
      Cookies.set("isOnline", true);
      this.setState({ isOffline: true });
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // debugger
    if (prevState.isOffline !== this.state.isOffline) {
      if (!window.navigator.onLine) {
        Cookies.set("isOnline", false);

        this.setState({ isOffline: false });
      } else {
        Cookies.set("isOnline", true);
        this.setState({ isOffline: true });
      }
      window.addEventListener("offline", () => {
        Cookies.set("isOnline", false);
        this.setState({ isOffline: false });
        this.props.navigation.navigate("Home");
      });
      window.addEventListener("online", (event) => {
        Cookies.set("isOnline", true);
        this.setState({ isOffline: true });
      });
    }
  }

  handleModalOpen = () => {
    this.setState({
      openModal: !this.state.openModal,
      shouldResetForm: false,
    });
    return true;
  };
  setShouldResetForm = (opt: boolean) => {
    this.setState({
      shouldResetForm: opt,
    });
    return true;
  };

  BackToMainPage = () => {
    this.setState({
      currentForm: "",
    });
    return true;
  };

  doSubmitFillableForm(
    value?: any,
    resetForm?: any,
    fillableFormName?: string,
    dynamicFields?: any[]
  ) {
    let endPoint = "";
    let data = {};
    let httpBody = {};
    if (value[1][2] === "after_action_review_custom_forms") {
      endPoint = "after_action_review_custom_forms";
      data = {
        training_name: value[1][0].missionName,
        lead_name: value[1][0].instructorName,
        training_date: value[1][0].dateOfTraining,
        expect_to_happen: value[1][0].questionOne,
        actually_occurred: value[1][0].questionTwo,
        went_well: value[1][0].questionThree,
        improved_upon: value[1][0].questionFour,
        submit_name: value[1][0].instructorName,
        submit_email: value[0].email,
        submit_destination_email: value[0].destinationEmail,
        // "account_id":
      };
      httpBody = {
        action_review: data,
      };
    } else if (value[1][2] === "lead_sheet_custom_forms") {
      endPoint = "lead_sheet_custom_forms";
      data = {
        // "lead_sheet": {
        name: value[1][0].name,
        date: value[1][0].date,
        age: value[1][0].age,
        address: value[1][0].address,
        phone_number: value[1][0].phoneCode + value[1][0].phone,
        submit_name: value[1][0].name,
        submit_email: value[0].email,
        submit_destination_email: value[0].destinationEmail,
        // "account_id": 1
        // }
      };
      httpBody = {
        lead_sheet: data,
      };
    } else {
      endPoint = "army_formatted_custom_forms";
      data = {
        organization_name: value[1][0].organizationName,
        address: value[1][0].streetAddress,
        city: value[1][0].city,
        state: value[1][0].state,
        zip_code: value[1][0].zipCode,
        office_symbol: value[1][0].officeSymbol,
        date: value[1][0].date,
        memorandum_for: value[1][0].memorandum,
        subject: value[1][0].subject,
        name: value[1][0].signatureBlockname,
        branch: value[1][0].rankOrBranch,
        title: value[1][0].rankTitle,
        submit_name: value[1][0].signatureBlockname,
        submit_email: value[0].email,
        submit_destination_email: value[0].destinationEmail,
        army_formate_fields_attributes: dynamicFields
          ?.map((item) => ({ description: item.value }))
          .filter((item) => item.description !== ""),
        // "account_id": 1
      };
      httpBody = {
        army_format: data,
      };
    }
    const header = {
      "Content-Type": configJSON.postFillableFormApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    // this.submitApiCallId = requestMessage.messageId;
    Cookies.set("submitFormApiCallId", requestMessage.messageId);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.postFillableFormAPiEndPoint}/${endPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postFillableFormAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    value[1][1]();
    resetForm();
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorReponse) {
        NotificationManager.error("", errorReponse);
      }
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === Cookies.get("submitFormApiCallId")) {
          NotificationManager.success("", "Form is submitted");
        }
      } else {
        // this.parseApiCatchErrorResponse(errorReponse);
        const errors = responseJson?.errors;
        // this.setState({loading:false})
        if (apiRequestCallId != null) {
          //CreateAccount response Api
          if (apiRequestCallId === Cookies.get("submitFormApiCallId")) {
            NotificationManager.error("", errors[0].contact[0]);
            //  toast.success("Messege is submitted.",{position: toast.POSITION.BOTTOM_RIGHT})
          }
        }
      }
    }
    // Customizable Area End
  }
}
// Customizable Area End
